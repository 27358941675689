import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import store from "../../redux/store";
import Spinner from '../additional/Spinner';
import Restricted from '../additional/Restricted';
import delIcon from "../../images/trash.svg";
import server from '../../server';
import male from '../../images/male.svg';
import female from '../../images/female.svg';
import { Redirect } from "react-router-dom";

const initialState = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  nickName: '',
  dob: '',
  moderator: false,
  rating: 0,
  visits: 0,
  wins: 0,
  loses: 0,
  sex: '',
  lastTimePaid: 0
}

class AdminUsers extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    store.dispatch({ type: 'NO_ERROR' });
    const { match: { params } } = this.props;
    const content = this;
    axios.get(`${server.address}/mafia-users/${params.userId}`)
      .then(res => {
        content.setState(res.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  removeUser(id) {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.delete(server.address + '/mafia-users/' + this.state._id)
      .then(function (res) {
        content.updateList();
        content.setState({
          ...initialState,
          removed: false
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: content.props.config.messages.playerRemoved,
            success: true
          }
        })
        setTimeout(() => content.setState({ removed: true }), 3000);
      });
  }

  updateUser() {
    this.firstCheck() ?
      store.dispatch({
        type: 'ERROR',
        payload: {
          errorText: this.props.config.messages.fillAllFields
        }
      })
      :
      this.compareUsers()
  }

  compareUsers() {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        let findEmail = res.data.find(el => el.email === this.state.email);
        let findUser = res.data.find(el => el.nickName === this.state.nickName);
        findEmail !== undefined && findEmail._id !== this.state._id ?
          store.dispatch({
            type: 'ERROR',
            payload: {
              errorText: content.props.config.messages.emailTaken
            }
          })
          :
          findUser !== undefined && findUser._id !== this.state._id ?
            store.dispatch({
              type: 'ERROR',
              payload: {
                errorText: content.props.config.messages.nickTaken
              }
            })
            :
            this.updateData()
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  firstCheck() {
    const values = Object.values(this.state).find(el => el.length === 0 || el.toString().length === 0 || el === undefined);
    return values !== undefined;
  }

  updateData() {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.patch(server.address + '/mafia-users/' + this.state._id, this.state)
      .then(function (res) {
        content.updateList();
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: content.props.config.messages.infoSaved,
            success: true
          }
        })
      });
  }

  updateList() {
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        const data = res.data.sort((a, b) => b.rating - a.rating);
        data.forEach((el, i) => el.no = i + 1);
        store.dispatch({
          type: 'LOAD_USERS',
          payload: {
            users: data
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  revertData() {
    store.dispatch({ type: 'NO_ERROR', });
    store.dispatch({ type: 'PROGRESS' });
    this.componentDidMount();
  }

  now() {
    let date = new Date();
    return date.getTime();
  }

  paidNow() {
    if ((this.now() - this.state.lastTimePaid) < 86400000) {
      this.setState({
        lastTimePaid: 0,
        visits: this.state.visits - 1
      }, () => store.dispatch({ type: 'NO_ERROR', }))
    } else {
      let date = new Date();
      this.setState({
        lastTimePaid: date.getTime(),
        visits: this.state.visits + 1
      }, () => store.dispatch({ type: 'NO_ERROR', }))
    }
  }

  updateNumbers(value, type) {
    if (typeof parseInt(value) !== 'number' || isNaN(value)) {
      value = 0;
    }
    const obj = {};
    obj[type] = value;
    this.setState(obj, () => store.dispatch({ type: 'NO_ERROR', }));
  }

  render() {
    if (this.props.currentUser.firstName === undefined) {
      return <Restricted />
    } else if (this.props.currentUser.admin !== true && this.props.currentUser.moderator !== true) {
      return <Restricted moderator={true} />
    } else if (this.props.progress === true) {
      return (<Spinner margin={true} />)
    } else if (this.state.removed === true) {
      return (<Redirect to='/users' />)
    } else {
      return (
        <div className="page-wrap center" id='admin-users'>

          <div className="text-center mt-3">

            <div className='mb-3'>
              <span className='ml-2 mr-3'>
                <img src={male}
                  alt='male'
                  id='male-icon'
                  className={this.state.sex === 'male' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                  onClick={() => this.setState({ sex: 'male' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
              </span>
              <span className='ml-2 mr-3'>
                <img src={female}
                  alt='female'
                  id='female-icon'
                  className={this.state.sex === 'female' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                  onClick={() => this.setState({ sex: 'female' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
              </span>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName">
                  <span id='firstName-label'>{this.props.config.labels.accountFName}</span>
                </label>
                <input value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="firstName"
                  maxLength="100"
                  aria-label="First name"
                  placeholder="required *"
                  className="form-control" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastName">
                  <span id="lastName-label">{this.props.config.labels.accountLName}</span>
                </label>
                <input value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="lastName"
                  maxLength="100"
                  aria-label="Last name"
                  placeholder="required *"
                  className="form-control" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="email">
                  <span id='email-label'>{this.props.config.labels.accountEmail}</span>
                </label>
                <input value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="email"
                  id="email"
                  maxLength="100"
                  aria-label="Email"
                  placeholder="required *"
                  className="form-control" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="nickname">
                  <span id="nickname-label">{this.props.config.labels.accountNickname}</span>
                </label>
                <input value={this.state.nickName}
                  onChange={(e) => this.setState({ nickName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="nickname"
                  maxLength="100"
                  aria-label="Nickname"
                  placeholder="required *"
                  className="form-control" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="dob">
                  <span id='dob-label'>{this.props.config.labels.accountDOB}</span>
                </label>
                <input value={this.state.dob}
                  onChange={(e) => this.setState({ dob: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="date"
                  id="dob"
                  maxLength="100"
                  aria-label="DOB"
                  className="form-control" />
              </div>
            </div>

            <div className='m-3'>Statistics:</div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text append-width-account" id="rating-prepend">{this.props.config.labels.accountRating}</span>
              </div>
              <input value={typeof this.state.rating === 'number' ? this.state.rating.toString() : '0'}
                onChange={(e) => this.updateNumbers(+e.target.value, 'rating')}
                type="number"
                id="rating"
                maxLength="10"
                aria-label="Rating"
                placeholder="Rating *"
                className="form-control" />

              <input
                value={typeof this.state.visits === 'number' ? this.state.visits.toString() : '0'}
                onChange={(e) => this.updateNumbers(+e.target.value, 'visits')}
                type="number"
                id="visits"
                maxLength="10"
                aria-label="Visits"
                placeholder="Visits *"
                className="form-control d-block" />
              <div className="input-group-append">
                <span className="input-group-text append-width-account" id="visits-append">{this.props.config.labels.accountVisits}</span>
              </div>
            </div>

            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text append-width-account" id="wins-prepend">{this.props.config.labels.accountWins}</span>
              </div>
              <input value={typeof this.state.wins === 'number' ? this.state.wins.toString() : '0'}
                onChange={(e) => this.updateNumbers(+e.target.value, 'wins')}
                type="number"
                id="wins"
                maxLength="10"
                aria-label="Wins"
                placeholder="Wins *"
                className="form-control" />

              <input value={typeof this.state.loses === 'number' ? this.state.loses.toString() : '0'}
                onChange={(e) => this.updateNumbers(+e.target.value, 'loses')}
                type="number"
                id="loses"
                maxLength="10"
                aria-label="Losses"
                placeholder="Losses *"
                className="form-control" />
              <div className="input-group-append">
                <span className="input-group-text append-width-account" id="loses-append">{this.props.config.labels.accountLoses}</span>
              </div>
            </div>

            <table width="100%" className='mb-3'>
              <tbody>
                <tr>
                  <td width="33%" className="text-left">
                    <span>{this.props.config.labels.moderator}<input
                      type="checkbox"
                      name="moderator"
                      className='ml-2 hover-item'
                      checked={this.state.moderator}
                      onChange={() => this.setState({ moderator: !this.state.moderator }, () => store.dispatch({ type: 'NO_ERROR', }))} />
                    </span>
                  </td>
                  <td width="33%" className="text-center">
                    {
                      this.props.errorState ?
                        <div>
                          <span className={this.props.success ? 'green-text' : 'error-text'} id='error-text'>
                            {this.props.errorText}
                          </span>
                        </div>
                        :
                        <div>
                          <span className='error-placeholder' />
                        </div>
                    }
                  </td>
                  <td width="33%" className="content-center text-right">
                    <img onClick={() => this.removeUser(this.state._id)}
                      src={delIcon}
                      alt='delete'
                      height={16}
                      className='hover-item' />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mb-3">
              <button className="btn btn-sm btn-primary"
                id="registration"
                onClick={() => this.updateUser()}>
                {this.props.config.buttons.update}
              </button>
              <button className="btn btn-sm btn-warning ml-5"
                id="clear"
                onClick={() => this.revertData()}>
                {this.props.config.buttons.revert}
              </button>
            </div>

          </div>

        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  users: state.users,
  config: state.config,
  progress: state.progress,
  currentUser: state.currentUser,
  errorState: state.errorState,
  errorText: state.errorText,
  success: state.success
});

export default connect(mapStateToProps)(AdminUsers);