import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import store from "../../redux/store";
import axios from "axios";
import server from '../../server';
import ZeroNight from './ZeroNight';
import TimerButtons from './helpers/TimerButtons';
import { withRouter } from 'react-router-dom';
import foulsRender from '../../helpers/fouls-render';
import nigthRender from '../../helpers/night-buttons-render';

import { BsExclamationOctagon } from "react-icons/bs";
import { FaMedkit } from "react-icons/fa";

class Night extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentGame.startDate !== undefined ? this.props.currentGame : {};
  }

  componentDidMount() {
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
  }

  updatePlayer(id, event) {
    const gamers = this.state.players;
    if (event === '+') {
      gamers.forEach(el => {
        if (el._id === id) {
          el.fouls = el.fouls.concat('•');
          if (el.fouls === '•••') el.mutedCycle = this.state.cycle + 1;
          this.setState({ players: gamers }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
          if (el.fouls === '••••') {
            this.banPlayer(el.no);
          }
        }
      })
    } else if (event === '-') {
      gamers.forEach(el => {
        if (el._id === id) {
          if (el.fouls === '•••') delete el.mutedCycle;
          el.fouls = el.fouls.slice(1);
          this.setState({ players: gamers }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
        }
      });
    } else if (event === 'life') {
      gamers.forEach(el => {
        if (el._id === id) {
          el.fouls = el.fouls.slice(1);
          this.setState({ players: gamers }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
          this.allivePlayer(el.no);
        }
      })
    }
  }

  allivePlayer(no) {
    const gamers = this.state.players;
    gamers.forEach(el => el.no === no ? el.alive = true : null);
    const dead = this.state.deadPlayers.filter(el => el !== no);
    const banned = this.state.disqualified.filter(el => el !== no);
    this.setState({
      players: gamers,
      deadPlayers: dead,
      disqualified: banned
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (!(civilians.length <= mafia.length || mafia.length === 0)) {
      store.dispatch({
        type: 'NO_END_GAME'
      })
    }
  }

  getTime() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    return `${year}/${month + 1}/${day}_${hours}:${minutes < 10 ? 0 + minutes.toString() : minutes}`;
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    });
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
  }

  back(game) {
    this.updateGame(game.prevState);
    this.props.history.goBack();
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + this.state._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  findSheriff(no) {
    const check = this.props.currentGame.players.find(el => el.no === no);
    if (check.role === 'Sheriff') {
      return this.props.config.messages.yesSheriff;
    } else {
      return this.props.config.messages.noSheriff;
    }
  }

  findMafia(no) {
    const check = this.props.currentGame.players.find(el => el.no === no);
    if (check.role === 'Don' || check.role === 'Mafia') {
      return this.props.config.messages.yesMafia;
    } else {
      return this.props.config.messages.noMafia;
    }
  }

  killPlayer(no) {
    const gamers = this.state.players;
    const dead = this.state.deadPlayers;
    if (no !== 'n/a') {
      if (this.state.killedLastNight === true) {
        gamers.find(el => el.no === dead[dead.length - 1]).alive = true;
        dead.pop();
      }
      gamers.forEach(el => el.no === no ? el.alive = false : el.alive);
      dead.push(no);
      this.setState({
        players: gamers,
        deadPlayers: dead,
        killedLastNight: true
      }, () => store.dispatch({
        type: 'GAME',
        payload: this.state
      }));
    } else if (no === 'n/a') {
      if (this.state.killedLastNight === true) {
        gamers.find(el => el.no === dead[dead.length - 1]).alive = true;
        dead.pop();
        this.setState({
          players: gamers,
          deadPlayers: dead,
          killedLastNight: false
        }, () => store.dispatch({
          type: 'GAME',
          payload: this.state
        }));
      }
    }
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (civilians.length <= mafia.length || mafia.length === 0) {
      store.dispatch({
        type: 'END_GAME'
      })
    }
  }

  banPlayer(no) {
    const gamers = this.state.players;
    const disqualified = this.state.disqualified;
    gamers.forEach(el => el.no === no ? el.alive = false : null);
    disqualified.push(no);
    this.setState({
      players: gamers,
      disqualified: disqualified,
      skipNextVoting: this.state.cycle + 1
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (civilians.length <= mafia.length || mafia.length === 0) {
      store.dispatch({
        type: 'END_GAME'
      })
    }
  }

  whoWon() {
    const gamers = this.state.players;
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    return mafia.length >= civilians.length ? 1 : 2
  }

  addComment() {
    const allComments = this.state.comments;
    let comment = this.state.currentComment;
    allComments.push(comment);
    this.setState({
      currentComment: '',
      comments: allComments
    });
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.addComment()
    }
  }

  disableKillButton(el) {
    if (this.props.currentGame.killedLastNight === false) {
      if (this.props.currentGame.deadPlayers.includes(el) || this.props.currentGame.disqualified.includes(el)) {
        return true;
      }
    } else {
      if ((this.props.currentGame.deadPlayers.includes(el) || this.props.currentGame.disqualified.includes(el)) && this.props.currentGame.deadPlayers[this.props.currentGame.deadPlayers.length - 1] !== el) {
        return true;
      } else {
        return false;
      }
    }
  }

  justKilled() {
    let killed = this.props.currentGame.deadPlayers;
    return killed[killed.length - 1];
  }

  bestMove(value, event) {
    this.refreshState();
    if (event === '+' && this.state.bestMove.length < 3) {
      const array = this.state.bestMove;
      if (array.find(el => el === value) === undefined) {
        array.push(value);
        this.setState({
          bestMove: array
        }, () =>
          store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
      }
    }
    if (event === '-') {
      const array = this.state.bestMove.filter(el => el !== value);
      this.setState({
        bestMove: array
      }, () =>
        store.dispatch({
          type: 'GAME',
          payload: this.state
        }));
    }
  }

  refreshState() {
    this.setState({ ...this.props.currentGame });
  }

  render() {
    return this.props.currentUser.email === undefined && this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/public-game' />
      :
      this.props.currentGame.startDate === undefined
        ?
        <Redirect to='/game' />
        :
        this.props.currentGame.cycle === 0
          ?
          <ZeroNight />
          :
          (
            <div className={this.props.isGame ? '' : 'page-wrap'} id='night-page'>

              <div className='mt-3 mb-3'>

                {this.props.currentGame.type === 'public' ?
                  <div className="card">
                    <div className='card-body p-1 text-sm'>
                      {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                    </div>
                  </div>
                  :
                  null
                }

                <div className="card mt-2 custom-thead">
                  <div className='card-body p-1'>
                    <strong>{this.props.config.labels.night} #{this.state.cycle}</strong>
                  </div>
                </div>

                <div className="card mt-2">
                  <div className='card-body p-2'>
                    {this.props.config.texts.mafiaTxt.split('\n').map((el, i) => <div key={i}>
                      {el === '{numbers}' ?
                        <div className={`d-inline-block ${nigthRender().twoRows ? 'vote-buttons' : ''}`}>
                          {
                            this.props.currentGame.players.map(pl =>
                              <button id={`bm-${pl.no}`}
                                key={`bm-${pl.no}`}
                                disabled={this.disableKillButton(pl.no)}
                                className={`vote-button ${nigthRender().smallButtons ? 'vote-button-xs' : 'vote-button-sm'} ${(this.props.currentGame.deadPlayers.includes(pl.no) || this.props.currentGame.disqualified.includes(pl.no)) ? 'vote-selected' : null}`}
                                onClick={() => this.killPlayer(pl.no)}>
                                {pl.no}
                              </button>)
                          }
                          <button id={`bm-miss`}
                            key={`bm-miss`}
                            className={`vote-button ${nigthRender().smallButtons ? 'vote-button-xs' : 'vote-button-sm'} miss-button pl-1 pr-1 ${this.props.currentGame.killedLastNight ? null : 'vote-selected'}`}
                            onClick={() => this.killPlayer('n/a')}>
                            {this.props.config.buttons.miss}
                          </button>
                        </div>
                        : el}
                    </div>)}
                  </div>
                </div>

                <div className="card mt-2">
                  <div className='card-body p-2'>
                    {this.props.config.texts.donTxt.split('\n').map((el, i) => <div key={i}>
                      {el === '{numbers}' ?
                        <div className={`d-inline-block ${nigthRender().twoRows ? 'vote-buttons' : ''}`}>
                          {this.props.currentGame.players.map(el =>
                            <span id={`bm-${el.no}`}
                              key={`bm-${el.no}`}
                              className={`custom-tooltip check-button ${nigthRender().smallButtons ? 'vote-button-xs' : 'vote-button-sm'}`}>
                              {el.no}
                              <span className="custom-tooltiptext">{this.findSheriff(el.no)}</span>
                            </span>
                          )}
                        </div>
                        : el}
                    </div>)}
                  </div>
                </div>

                <div className="card mt-2">
                  <div className='card-body p-2'>
                    {this.props.config.texts.sheriffTxt.split('\n').map((el, i) => <div key={i}>
                      {el === '{numbers}' ?
                        <div className={`d-inline-block ${nigthRender().twoRows ? 'vote-buttons' : ''}`}>
                          {this.props.currentGame.players.map(el =>
                            <span id={`bm-${el.no}`}
                              key={`bm-${el.no}`}
                              className={`custom-tooltip check-button ${nigthRender().smallButtons ? 'vote-button-xs' : 'vote-button-sm'}`}>
                              {el.no}
                              <span className="custom-tooltiptext">{this.findMafia(el.no)}</span>
                            </span>
                          )}
                        </div>
                        : el}
                    </div>)}
                  </div>
                </div>

                {this.props.currentGame.killedLastNight === true
                  &&
                  this.props.currentGame.cycle === 1
                  &&
                  this.props.currentGame.deadPlayers.length + this.props.currentGame.disqualified.length < 3
                  ?
                  <div className="card mt-2">
                    <div className='card-body p-2'>
                      <div className="stream-text">
                        {this.props.config.messages.streamDay}
                      </div>
                      <div>
                        {this.props.config.texts.bestMove.split('\n').map((el, i) => <div key={i}>
                          {el.replace('{N}', this.justKilled())}</div>)}
                      </div>
                      <div className='mt-2 mb-2'>
                        <TimerButtons
                          timer={20}
                          volume={'low'}
                          size={'big'}
                          singleButton={true}
                          id={'bestMove'}
                        />
                      </div>
                      <div>
                        {this.props.config.labels.bestMove}
                      </div>
                      <div className={`d-inline-block ${nigthRender().twoRows ? 'vote-buttons' : ''}`}>
                        {this.props.currentGame.players.map(el =>
                          <button id={`bm-${el.no}`}
                            key={`bm-${el.no}`}
                            className={`vote-button vote-button-sm ${this.props.currentGame.bestMove.includes(el.no) && 'vote-selected'}`}
                            onClick={() => this.props.currentGame.bestMove.includes(el.no) ? this.bestMove(el.no, '-') : this.bestMove(el.no, '+')}>
                            {el.no}
                          </button>
                        )}
                      </div>

                    </div>
                  </div>
                  :
                  null
                }

                <div className="card mt-2">
                  <div className='card-body d-flex justify-content-between p-1'>
                    {this.state.prevState
                      &&
                      <button
                        id='back-btn'
                        className='btn btn-sm btn-warning m-1'
                        onClick={() => this.back(this.state)}>
                        {this.props.config.buttons.back}
                      </button>}
                    <Link to={'/game/day'}>
                      <button
                        id='next-btn'
                        className='btn btn-sm btn-warning m-1'
                        onClick={() => this.updateGame({
                          ...this.state,
                          prevState: JSON.parse(JSON.stringify(this.state)),
                          phase: '/game/day',
                          cycle: this.props.currentGame.cycle + 1,
                          firstWord: this.state.firstWord + 1
                        })}>{this.props.config.buttons.next}
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="card mt-2">
                  <div className='card-body p-1'>
                    <div id='fouls'>
                      <div id='fouls-text'>
                        {this.props.config.labels.fouls}
                      </div>
                      <div id='fouls' className={`d-inline-block ${foulsRender().twoRows ? 'two-fouls-rows' : foulsRender().threeRows ? 'three-fouls-rows' : ''}`}>
                        {this.props.currentGame.players.map(el =>
                          <span
                            className={`fouls-container ${!el.alive && 'fouls-container-dead'} ${foulsRender().threeRows && 'pl-0 pr-0'}`}
                            key={el._id}>
                            <strong className='mr-1 number-container'>{el.no}</strong>
                            {el.alive ?
                              <span className='inner-fouls-container content-left'>
                                <BsExclamationOctagon
                                  alt='add'
                                  title='Add a foul'
                                  className='hover-item button-icon mb-1'
                                  onClick={() => this.updatePlayer(el._id, '+')}
                                />
                                <span
                                  className='ml-1 mr-1 remarks-container hover-item'
                                  title='Remove a foul'
                                  onClick={() => this.updatePlayer(el._id, '-')}>
                                  <b>{el.fouls}</b>
                                </span>
                              </span>
                              :
                              <span className='inner-fouls-container m-auto'>
                                <FaMedkit
                                  alt='help'
                                  title='Return this player'
                                  className='hover-item button-icon mb-1'
                                  onClick={() => this.updatePlayer(el._id, 'life')}
                                />
                              </span>
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="input-group mt-2 mb-3">
                  <input
                    className="form-control form-control-sm"
                    value={this.state.currentComment}
                    placeholder={this.props.config.labels.comment}
                    maxLength='250'
                    id='add_comment_text'
                    onKeyDown={(e) => this.pressEnter(e)}
                    onChange={(e) =>
                      this.setState({
                        currentComment: e.target.value
                      }, () =>
                        store.dispatch({
                          type: 'NO_ERROR'
                        }))}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-sm btn-primary align-right"
                      id='add_comment'
                      disabled={this.state.currentComment.trim().length === 0}
                      onClick={() => this.addComment()}>
                      {this.props.config.buttons.addComment}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          )
      ;
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  currentUser: state.currentUser,
  almostDone: state.almostDone,
  errorState: state.errorState,
  errorText: state.errorText,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(withRouter(Night));