import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BsGearWide } from "react-icons/bs";

class Spinner extends Component {

  render() {
    return (
      <div id='spinner' className={this.props.margin ? 'page-wrap center' : ''}>
        <BsGearWide
          alt='spinner'
          className={`spinner${this.props.size === 'large' ? '' : this.props.size === 'xs' ? '-xs' : '-sm'}`} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Spinner);