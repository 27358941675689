import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import axios from "axios";
import store from "../../redux/store";
import Spinner from "../additional/Spinner";
import Restricted from '../additional/Restricted';
import NoGames from '../additional/Nothing';
import delIcon from "../../images/trash.svg";
import { Link } from "react-router-dom";
import server from '../../server';

class AdminGames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: []
    };
  }

  componentDidMount() {
    store.dispatch({ type: 'PROGRESS' });
    store.dispatch({ type: 'URL', payload: this.props.location.pathname })
    const content = this;
    axios.get(server.address + '/mafia-games/')
      .then(res => {
        content.setState({
          games: res.data.reverse()
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  removeGame(id) {
    store.dispatch({ type: 'PROGRESS' });
    const content = this;
    axios.delete(server.address + '/mafia-games/' + id)
      .then(() => {
        content.componentDidMount();
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  render() {
    if (this.props.currentUser.firstName === undefined) {
      return <Restricted />
    } else if (this.props.currentUser.admin !== true) {
      return <Restricted admin={true} />
    } else {
      return this.props.progress ?
        <Spinner margin={true} />
        : this.state.games.length === 0
          ?
          <NoGames pageHeight='full' type='game' />
          :
          (
            <div id='admin-games-page' className='page-wrap'>

              <table className='table table-sm mt-3'>
                <thead className='thead-light'>
                  <tr>
                    <th>{this.props.config.labels.adminGamesPhase}</th>
                    <th>{this.props.config.labels.adminGamesDate}</th>
                    <th>{this.props.config.labels.adminGamesTime}</th>
                    <th>D</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.games.map((el, i) =>
                    <tr key={el._id}>
                      <td width="30%"><Link to={el.phase}>
                        <span className='link-item hover-item'
                          onClick={() =>
                            store.dispatch({
                              type: 'GAME',
                              payload: el
                            })}>
                          <b>{`${el.phase.slice(6)} ${el.cycle}`}</b></span></Link></td>
                      <td width="30%">{el.startDate.split('_')[0]}</td>
                      <td width="20%">{el.startDate.split('_')[1]}</td>
                      <td width="20%">
                        <img onClick={() => this.removeGame(el._id)}
                          src={delIcon}
                          alt='delete'
                          id="del-icon"
                          height={16}
                          className='hover-item' />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

            </div>
          );
    }
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  progress: state.progress,
  currentUser: state.currentUser,
  config: state.config
});

export default connect(mapStateToProps)(AdminGames);