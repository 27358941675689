import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import store from "../../redux/store";
import Spinner from "../additional/Spinner";
import ConfigButtons from './config/Buttons';
import ConfigLabels from './config/Labels';
import ConfigTexts from './config/Texts';
import ConfigMessages from './config/Messages';
import ConfigRoles from './config/Roles';
import ConfigAudio from './config/Audio';
import NA from '../NA';
import server from '../../server';

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
    store.dispatch({ type: 'NO_ERROR', });
    store.dispatch({ type: 'NO_PROGRESS' });
    store.dispatch({ type: 'URL', payload: this.props.location.pathname });
  }

  revert() { //TODO: fix. does not work
    this.setState({ ...this.props.config });
  }

  updateTranslations() {
    const translations = this.props.config.translations[this.state.currentLanguage];
    translations.roles = this.props.config.roles;
    translations.copyright = this.props.config.copyright;
    translations.title = this.props.config.title;
    translations.buttons = this.props.config.buttons;
    translations.texts = this.props.config.texts;
    translations.labels = this.props.config.labels;
    translations.messages = this.props.config.messages;
    this.patchConfig()
  }

  patchConfig() {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.patch(server.address + '/config/' + server.configID, this.props.config)
      .then(function (res) {
        if (res.data.message === "Config updated") {
          store.dispatch({
            type: 'ERROR',
            payload: {
              errorText: content.props.config.messages.configUpdated,
              success: true
            }
          })
        } else {
          store.dispatch({
            type: 'ERROR',
            payload: {
              errorText: 'Server error. Config was not updated',
              success: true
            }
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
        setTimeout(() => store.dispatch({ type: 'NO_ERROR', }), 3000);
      });
  }

  checkFields() { //TODO: update to check deep keys, not only the level 1
    const values = Object.values(this.state).find(el => el.length === 0 || el === undefined);
    return values === undefined;
  }

  submit() {
    // this.checkFields() //TODO: validation of fields needs to be rethought since those are different components now.
    //   ?
    this.updateTranslations()
    // :
    // store.dispatch({
    //   type: 'ERROR',
    //   payload: {
    //     errorText: this.props.config.messages.fillAllFields,
    //     success: false
    //   }
    // })
  }

  switchConfigPage(page) {
    store.dispatch({
      type: 'CONFIG_STATE',
      payload: page
    })
  }

  render() {
    return (
      <div id='config-page' className='page-wrap'>

        <div className='text-sm mb-3'>

          <div id='tab-buttons' className='mt-1 mb-3'>
            <button className={`btn btn-sm ${this.props.configState === 'buttons' ? 'btn-primary' : 'btn-outline-primary'} mt-2 ml-2 mr-2`}
              id="buttons"
              onClick={() => this.switchConfigPage('buttons')}>
              Buttons
          </button>
            <button className={`btn btn-sm ${this.props.configState === 'labels' ? 'btn-primary' : 'btn-outline-primary'} mt-2 ml-2 mr-2`}
              id="labels"
              onClick={() => this.switchConfigPage('labels')}>
              Labels
          </button>
            <button className={`btn btn-sm ${this.props.configState === 'game-texts' ? 'btn-primary' : 'btn-outline-primary'} mt-2 ml-2 mr-2`}
              id="game-texts"
              onClick={() => this.switchConfigPage('game-texts')}>
              Texts
          </button>
            <button className={`btn btn-sm ${this.props.configState === 'messages' ? 'btn-primary' : 'btn-outline-primary'} mt-2 ml-2 mr-2`}
              id="messages"
              onClick={() => this.switchConfigPage('messages')}>
              Messages
          </button>
            <button className={`btn btn-sm ${this.props.configState === 'roles' ? 'btn-primary' : 'btn-outline-primary'} mt-2 ml-2 mr-2`}
              id="game-roles"
              onClick={() => this.switchConfigPage('roles')}>
              General
          </button>
            <button className={`btn btn-sm ${this.props.configState === 'audio' ? 'btn-primary' : 'btn-outline-primary'} mt-2 ml-2 mr-2`}
              id="audio"
              onClick={() => this.switchConfigPage('audio')}>
              Audio
          </button>
          </div>

          {this.props.configState === 'buttons' ?
            <ConfigButtons />
            :
            this.props.configState === 'game-texts' ?
              <ConfigTexts />
              :
              this.props.configState === 'labels' ?
                <ConfigLabels />
                :
                this.props.configState === 'messages' ?
                  <ConfigMessages />
                  :
                  this.props.configState === 'roles' ?
                    <ConfigRoles />
                    :
                    this.props.configState === 'audio' ?
                      <ConfigAudio />
                      :
                      <NA />
          }

          <div className="mb-3">
            <button className="btn btn-sm btn-primary  ml-3 mr-3"
              id="registration"
              onClick={() => this.submit()}>
              {this.props.config.buttons.update}
            </button>
            <button className="btn btn-sm btn-success ml-3 mr-3"
              id="revert"
              onClick={() => this.revert()}>
              {this.props.config.buttons.revert}
            </button>
          </div>

          {this.props.progress ?
            <Spinner />
            :
            this.props.errorState ?
              <div>
                <span className={`error-placeholder text-reg ${this.props.success ? 'green-text' : 'error-text'}`} id='error-text'>
                  {this.props.errorText}
                </span>
              </div>
              :
              <div>
                <span className='error-placeholder' />
              </div>
          }

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  progress: state.progress,
  errorText: state.errorText,
  errorState: state.errorState,
  configState: state.configState,
  success: state.success
});

export default connect(mapStateToProps)(Config);