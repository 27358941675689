import React, { Component } from 'react';
import '../css/custom.css';
import { connect } from 'react-redux';
import Users from './Users';
import NewUser from "./admin/NewUser";
import { Route, Switch } from 'react-router-dom';
import Login from "./Login";
import Forgot from "./Forgot";
import NewGame from "./NewGame";
import Loading from "./Loading";
import Admin from "./Admin";
import NA from "./NA";
import Initial from './game/Initial';
import AdminUsers from "./admin/AdminUsers";
import Roles from "./game/Roles";
import Games from "./Games";
import Night from "./game/Night";
import Day from "./game/Day";
import Vote from "./game/Vote";
import End from "./game/End";
import AdminGames from './admin/AdminGames';
import Account from './Account';
import Tournaments from './Tournaments';
import Tournament from './Tournament';
import NewTournament from './admin/NewTournament';
import PublicGame from './PublicGame';

class RoutesHolder extends Component {

  render() {
    return (
      <div className='container'>

        <Switch>
          <Route path='/' exact component={Login} />
          <Route path='/users' exact component={Users} />
          <Route path='/new' exact component={NewUser} />
          <Route path='/forgot' exact component={Forgot} />
          <Route path='/game' exact component={NewGame} />
          <Route path='/games' exact component={Games} />
          <Route path='/loading' exact component={Loading} />
          <Route path='/admin' exact component={Admin} />
          <Route path='/account' exact component={Account} />

          {/*Game*/}
          <Route path='/game/initial' exact component={Initial} />
          <Route path='/game/roles' exact component={Roles} />
          <Route path='/game/night' exact component={Night} />
          <Route path='/game/day' exact component={Day} />
          <Route path='/game/vote' exact component={Vote} />
          <Route path='/game/end' exact component={End} />

          {/*Tournament*/}
          <Route path='/tournaments' exact component={Tournaments} />
          <Route path='/tournaments/new' exact component={NewTournament} />
          <Route path='/tournaments/:tourId' exact component={Tournament} />

          {/*Public Game*/}
          <Route path='/public-game' exact component={PublicGame} />

          {/*Admin*/}
          <Route path='/users/:userId' exact component={AdminUsers} />
          <Route path='/admin/games' exact component={AdminGames} />

          {/*Else*/}
          <Route component={NA} />
        </Switch>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  path: state.path
});

export default connect(mapStateToProps)(RoutesHolder);