import React, { Component } from 'react';
import { connect } from 'react-redux';

class NoGames extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return <div id='nothing-to-display-page' className={this.props.pageHeight === 'full' ? 'page-wrap center' : ''}>
            {this.props.type === 'game'
                ?
                this.props.config.labels.adminGamesNothing
                : this.props.type === 'tournament' ?
                    <div className='mt-3'>No tournaments to display</div> //TODO: config
                    :
                    <div className='mt-3'>Empty</div>}
        </div>
    }
}

const mapStateToProps = state => ({
    config: state.config
});

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(NoGames);