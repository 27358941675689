import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// import axios from 'axios';
// import server from '../../server';
import store from '../../redux/store';
import { noProgress, inProgress } from '../../actions';
// import trimString from '../../helpers/trim-string';
// import howMuchTrim from '../../helpers/trimAmount';
import NoGames from '../additional/Nothing';

//SVG
// import rating from '../../images/ratings/rating.svg';
// import games from '../../images/ratings/games.svg';
// import number from '../../images/ratings/number.svg';
// import nickname from '../../images/ratings/nick.svg';
// import win from '../../images/ratings/win.svg';
// import lose from '../../images/ratings/lose.svg';
// import percent from '../../images/ratings/percent.svg';
import delIcon from '../../images/trash.svg';

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: this.props.currentTournament.games.sort((a, b) => b.mainScore - a.mainScore),
    };
  }

  componentDidMount() {
  }

  render() {
    return this.state.games.length === 0
      ?
      <NoGames type='game' />
      :
      (
        <div id='games-page' className='page-wrap'>

          <table className='table table-sm'>
            <thead className='thead-light'>
              <tr>
                <th>{this.props.config.labels.adminGamesPhase}</th>
                <th>{this.props.config.labels.adminGamesDate}</th>
                <th>{this.props.config.labels.adminGamesTime}</th>
                {this.props.currentUser.admin === true || this.props.currentUser.moderator === true
                  ?
                  <th>D</th>
                  :
                  null
                }
              </tr>
            </thead>

            <tbody>
              {this.state.games.map((el, i) =>
                <tr key={el._id}>
                  <td width="30%"><Link to={el.phase}>
                    <span className='link-item hover-item'
                      onClick={() =>
                        store.dispatch({
                          type: 'GAME',
                          payload: el
                        })}>
                      <b>{`${el.phase.slice(6)} ${el.cycle}`}</b></span></Link></td>
                  <td width="30%">{el.startDate.split('_')[0]}</td>
                  <td width="20%">{el.startDate.split('_')[1]}</td>
                  {this.props.currentUser.admin === true || this.props.currentUser.moderator === true
                    ?
                    <td width="20%">
                      <img onClick={() => this.removeGame(el._id)}
                        src={delIcon}
                        alt='delete'
                        height={16}
                        className='hover-item' />
                    </td>
                    :
                    null
                  }
                </tr>
              )}
            </tbody>
          </table>

        </div>
      );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  currentTournament: state.currentTournament
});

const mapDispatchToProps = dispatch => ({
  inProgress: () => dispatch(inProgress()),
  noProgress: () => dispatch(noProgress())
})

export default connect(mapStateToProps, mapDispatchToProps)(Games);