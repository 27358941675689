import React, { Component } from 'react';
import { connect } from 'react-redux';

class Restricted extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div id='error-login' className="page-wrap center">
                {this.props.admin ?
                    'Only admin can access this area!'
                    :
                    this.props.moderator ?
                        'Only admin or moderator can access this area!'
                        :
                        'Log in to access this area!'
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    config: state.config
});

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Restricted);