const initialState = {
    platform: 'desktop',
    users: [],
    currentUser: {},
    currentGame: {},
    currentTournament: {
        players: [],
        games: []
    },
    errorState: false,
    errorText: '',
    success: false,
    loading: true,
    progress: false,
    itemProgress: false,
    item: 0,
    preloading: false,
    popup: false,
    isGame: false,
    almostDone: false,
    showPass: false,
    url: '',
    configState: 'buttons',
    registrationSuccess: false,
    countdownTimer: 0,
    countdownVolume: 'low',
    timerIsActive: false,
    timerButtonID: '',
    config: {
        version: "1.0",
        roles: [
            {
                role: 'Mafia',
                translation: 'Mafia',
                quantity: 2
            },
            {
                role: 'Citizen',
                translation: 'Citizen',
                quantity: 6
            },
            {
                role: 'Don',
                translation: 'Don',
                quantity: 1
            },
            {
                role: 'Sheriff',
                translation: 'Sheriff',
                quantity: 1
            }
        ],
        usersPerPage: 10,
        copyright: "The Maf Place",
        title: "The Maf Place",
        labels: {
            hi: 'Hi',
            accountFName: "First Name",
            accountPassword: "Password",
            accountEmail: "Email",
            accountLName: "Last Name",
            accountNickname: "Nickname",
            accountWins: "Wins",
            accountLoses: "Loses",
            accountRating: "Rating",
            accountVisits: "Visits",
            moderator: "Moderator",
            search: "Search by name or nickname",
            ratingGame: "Rating",
            funGame: "Fun",
            players: "Players",
            game: "Game",
            mister: "mister",
            madam: "madam",
            gameRoles: "Game Roles",
            selectRole: "- select -",
            gameDay: "Day",
            nominations: "Nominations:",
            comment: "Comment",
            voting: "Voting",
            fouls: "Fouls:",
            night: "Night",
            mafiaShooting: "Mafia shooting:",
            shootingPlaceholder: "- kill -",
            donChecking: "Don looking for Sheriff:",
            sheriffChecking: "Sheriff looking for Mafia:",
            donCheckingPlaceholder: "- check -",
            sheriffCheckingPlaceholder: "- check -",
        },
        buttons: {
            account: "My Account",
            addComment: "Add",
            all: "Ratings",
            games: "Games",
            logout: "Logout",
            new: "Register",
            newGame: "New Game",
            results: "Results",
            login: 'Login',
            register: 'Register',
            remind: 'Remind Password',
            clear: 'Clear',
            game: 'New Game',
            config: 'Config',
            update: 'Update',
            revert: 'Reset',
            next: 'Next',
            endGame: 'End Game',
            continue: 'Continue Game',
            start: 'Start Game',
            back: 'Back',
        },
        texts: {
            openGameTxt: 'Игра объявляется открытой!\nМужчины стоя аплодируют присутствующим дамам.',
            beginNightTxt: 'В городе наступает ночь!\nВсе игроки надевают маски, скрещивают руки на груди и опускают голову под углом 45 градусов.',
            roleTxt: 'Игроки по очереди с закрытыми глазами выбирают игровую карту. Затем снимают маску, знакомятся с игровой ролью и обратно надевают маску.\nВсе игроки низко наклоняют головы и скрещивают руки на груди. При необходимости, по команде судьи, закрывают уши руками.',
            mafiaTxt0: 'Город крепко спит. Просыпается Дон и знакомится с ведущим. У Дона есть 10 секунд, чтобы осмотреть город. Просыпается Мафия и знакомится друг с другом. Дон обозначает себя. У Мафии есть ровно минута, чтобы договориться.',
            sheriffTxt0: 'Мафия засыпает.\nПросыпается Шериф и знакомится с ведущим. У Шерифа есть 10 секунд, чтобы осмотреть город. Шериф засыпает.',
            mafiaTxt: 'В городе ночь. Все игроки крепко спят, одев маски, скрестив руки на груди и опутив голову под углом 45 градусов!\nНе спит только Мафия, которая выходит на охоту, высоко поднимая свои пистолеты.\nИ проходит возле домов игроков\n1 2 3 4 5 6 7 8 9 10',
            donTxt: 'Мафия уходит.\nПросыпается Дон и ищет Шерифа.',
            sheriffTxt: 'Дон засыпает.\nПросыпается Шериф и ищет Мафию.',
            endNightTxt: 'Шериф засыпает',
            morning1: 'В городе утро! Утро, к сожалению, не доброе - в городе завелась Мафия!',
            goodMorning: 'В городе утро! Утро доброе - мафия промахнулась!',
            badMorning: 'В городе утро! Утро, к сожалению, не доброе - был убит игрок под номером N! Ваша прощальная минута.',
            openTable: 'Стол открывает игрок под номером N. У Вас ровно 1 минута!',
            applause: 'Аплодисменты игроку номер N, который покидает наш стол.',
            greetings: 'Давайте поприветствуем игроков, находящихся за игровым столом:',
            noNominations: 'Никто не был выставлен!',
            allStay: 'Все игроки остаются за игровым столом!',
            singleNominee0: 'Поскольку голосование не проводится в нулевой день при единственном выставленном игроке, то игрок #{N} остается за игровым столом.',
            singleNomineeN: 'Поскольку игрок #{N} это единственный выставленный игрок, то он автоматически покидает игровой стол.\nВаша прощальная минута.',
            allLeave: 'По мнению города игроки под номерами {NN} должны покинуть игровой стол. У кождого из вас есть прощальная минута.',
            applauseToAll: 'Аплодисменты игрокам, которые покидают наш стол.',
            oneLeaves: 'Игрок #{N}, против Вас проголосовало большинство жителей города. К сожалению, Вы покидаете эту игру. Ваша прощальная минута.',
            because: 'Поскольку мнения жителей города снова разделились между игроками',
            shootout: 'У нас перестрелка между игроками:',
            playersVoted: 'На голосование высталены игроки:',
            vootingForAllLeave: 'я ставлю на голосование вопрос о том, чтобы все игроки на голосовании покинули игровой стол.',
            shortTimeAll: 'У каждого есть 30 секунд, чтобы высказать свою позицию:',
            votingOrder: 'Голосование проводится в том же порядке:',
            votingFouls: 'Внимание город! С Этого момента я начинаю ставить замечание за любую жестикуляцию.',
            supportAllLeave: 'Кто за то, чтобы все игроки на голосовании покинули наш город?',
            supportOneLeave: 'Кто за то, чтобы наш город покинул игрок номер ',
            supportAllStay: 'Кто за то, чтобы все игроки на голосовании остались?'
        },
        ratings: {
            donWin: 1,
            mafiaWin: 1,
            sheriffWin: 1,
            citizenWin: 1,
            donLose: 0,
            mafiaLose: 0,
            sheriffLose: 0,
            citizenLose: 0
        },
        messages: {
            yesSheriff: 'ДА, это Шериф!',
            noSheriff: 'НЕТ, это не Шериф!',
            yesMafia: 'ДА, это Мафия!',
            noMafia: 'НЕТ, это не Мафия!',
            playerKilled: 'Убит игрок #{N}'
        },
        currentLanguage: 'RU',
        translations: {
            RU: {
                roles: ['Mafia', 'Citizen', 'Don', 'Sheriff'],
                copyright: "Intellectual Games Club Sacramento",
                title: "Club Sacramento",
                labels: {
                    hi: 'Hi',
                    accountFName: "First Name",
                    accountPassword: "Password",
                    accountEmail: "Email",
                    accountLName: "Last Name",
                    accountNickname: "Nickname",
                    accountWins: "Wins",
                    accountLoses: "Loses",
                    accountRating: "Rating",
                    accountVisits: "Visits",
                    moderator: "Moderator",
                    search: "Search by name or nickname",
                    ratingGame: "Rating",
                    funGame: "Fun",
                    players: "Players",
                    game: "Game",
                    mister: "mister",
                    madam: "madam",
                    gameRoles: "Game Roles",
                    selectRole: "- select -",
                    gameDay: "Day",
                    nominations: "Nominations:",
                    comment: "Comment",
                    voting: "Voting",
                    fouls: "Fouls:",
                    night: "Night",
                    mafiaShooting: "Mafia shooting:",
                    shootingPlaceholder: "- kill -",
                    donChecking: "Don looking for Sheriff:",
                    sheriffChecking: "Sheriff looking for Mafia:",
                    donCheckingPlaceholder: "- check -",
                    sheriffCheckingPlaceholder: "- check -",
                },
                buttons: {
                    account: "My Account",
                    addComment: "Add",
                    all: "Ratings",
                    games: "Games",
                    logout: "Logout",
                    new: "Register",
                    newGame: "New Game",
                    results: "Results",
                    login: 'Login',
                    register: 'Register',
                    remind: 'Remind Password',
                    clear: 'Clear',
                    game: 'New Game',
                    config: 'Config',
                    update: 'Update',
                    revert: 'Reset',
                    next: 'Next',
                    endGame: 'End Game',
                    continue: 'Continue Game',
                    start: 'Start Game',
                },
                texts: {
                    openGameTxt: 'Игра объявляется открытой!\nМужчины стоя аплодируют присутствующим дамам.',
                    beginNightTxt: 'В городе наступает ночь!\nВсе игроки надевают маски, скрещивают руки на груди и опускают голову под углом 45 градусов.',
                    roleTxt: 'Игроки по очереди с закрытыми глазами выбирают игровую карту. Затем снимают маску, знакомятся с игровой ролью и обратно надевают маску.\nВсе игроки низко наклоняют головы и скрещивают руки на груди. При необходимости, по команде судьи, закрывают уши руками.',
                    mafiaTxt0: 'Город крепко спит. Просыпается Дон и знакомится с ведущим. У Дона есть 10 секунд, чтобы осмотреть город. Просыпается Мафия и знакомится друг с другом. Дон обозначает себя. У Мафии есть ровно минута, чтобы договориться.',
                    sheriffTxt0: 'Мафия засыпает.\nПросыпается Шериф и знакомится с ведущим. У Шерифа есть 10 секунд, чтобы осмотреть город. Шериф засыпает.',
                    mafiaTxt: 'В городе ночь. Все игроки крепко спят, одев маски, скрестив руки на груди и опутив голову под углом 45 градусов!\nНе спит только Мафия, которая выходит на охоту, высоко поднимая свои пистолеты.\nИ проходит возле домов игроков\n1 2 3 4 5 6 7 8 9 10',
                    donTxt: 'Мафия уходит.\nПросыпается Дон и ищет Шерифа.',
                    sheriffTxt: 'Дон засыпает.\nПросыпается Шериф и ищет Мафию.',
                    endNightTxt: 'Шериф засыпает',
                    morning1: 'В городе утро! Утро, к сожалению, не доброе - в городе завелась Мафия!',
                    goodMorning: 'В городе утро! Утро доброе - мафия промахнулась!',
                    badMorning: 'В городе утро! Утро, к сожалению, не доброе - был убит игрок под номером N! Ваша прощальная минута.',
                    openTable: 'Стол открывает игрок под номером N. У Вас ровно 1 минута!',
                    applause: 'Аплодисменты игроку номер N, который покидает наш стол.',
                    greetings: 'Давайте поприветствуем игроков, находящихся за игровым столом:',
                    noNominations: 'Никто не был выставлен!',
                    allStay: 'Все игроки остаются за игровым столом!',
                    singleNominee0: 'Поскольку голосование не проводится в нулевой день при единственном выставленном игроке, то игрок #{N} остается за игровым столом.',
                    singleNomineeN: 'Поскольку игрок #{N} это единственный выставленный игрок, то он автоматически покидает игровой стол.\nВаша прощальная минута.',
                    allLeave: 'По мнению города игроки под номерами {NN} должны покинуть игровой стол. У кождого из вас есть прощальная минута.',
                    applauseToAll: 'Аплодисменты игрокам, которые покидают наш стол.',
                    oneLeaves: 'Игрок #{N}, против Вас проголосовало большинство жителей города. К сожалению, Вы покидаете эту игру. Ваша прощальная минута.',
                    because: 'Поскольку мнения жителей города снова разделились между игроками',
                    shootout: 'У нас перестрелка между игроками:',
                    playersVoted: 'На голосование высталены игроки:',
                    vootingForAllLeave: 'я ставлю на голосование вопрос о том, чтобы все игроки на голосовании покинули игровой стол.',
                    shortTimeAll: 'У каждого есть 30 секунд, чтобы высказать свою позицию:',
                    votingOrder: 'Голосование проводится в том же порядке:',
                    votingFouls: 'Внимание город! С Этого момента я начинаю ставить замечание за любую жестикуляцию.',
                    supportAllLeave: 'Кто за то, чтобы все игроки на голосовании покинули наш город?',
                    supportOneLeave: 'Кто за то, чтобы наш город покинул игрок номер ',
                    supportAllStay: 'Кто за то, чтобы все игроки на голосовании остались?'
                },
            }
        }
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'INITIAL':
            return {
                ...initialState
            };

        case 'LOGIN':
            return {
                ...state,
                currentUser: action.payload.user
            };

        case 'LOGOUT':
            return {
                ...state,
                currentUser: {}
            };

        case 'LOADING':
            return {
                ...state,
                loading: true
            };

        case 'NO_LOADING':
            return {
                ...state,
                loading: false
            };

        case 'PROGRESS':
            return {
                ...state,
                progress: true
            };

        case 'NO_PROGRESS':
            return {
                ...state,
                progress: false
            };

        case 'PROGRESS_ITEM':
            return {
                ...state,
                itemProgress: true,
                item: action.payload
            };

        case 'NO_PROGRESS_ITEM':
            return {
                ...state,
                itemProgress: false,
                item: 0
            };

        case 'CONFIG':
            return {
                ...state,
                config: action.payload
            };

        case 'PRE_LOADING':
            return {
                ...state,
                preloading: true
            };

        case 'NO_PRE_LOADING':
            return {
                ...state,
                preloading: false
            };

        case 'ERROR':
            return {
                ...state,
                errorState: true,
                errorText: action.payload.errorText,
                success: action.payload.success ? true : false
            };

        case 'NO_ERROR':
            return {
                ...state,
                errorState: false,
                errorText: ''
            };

        case 'LOAD_USERS':
            return {
                ...state,
                users: action.payload.users
            };

        case 'GAME':
            return {
                ...state,
                isGame: true,
                currentGame: { ...action.payload }
            };

        case 'TOURNAMENT':
            return {
                ...state,
                currentTournament: { ...action.payload }
            };

        case 'END_GAME':
            return {
                ...state,
                almostDone: true
            };

        case 'NO_END_GAME':
            return {
                ...state,
                almostDone: false
            };

        case 'SHOW_PASS':
            return {
                ...state,
                showPass: !state.showPass
            };

        case 'NEW_GAME':
            return {
                ...initialState,
                isGame: false,
                platform: state.platform,
                currentUser: state.currentUser,
                users: state.users,
                loading: state.loading,
                config: state.config,
                url: state.url,
                almostDone: false
            };

        case 'URL':
            return {
                ...state,
                url: action.payload
            };

        case 'CONFIG_STATE':
            return {
                ...state,
                configState: action.payload
            };

        case 'SUCCESS_REGISTRATION':
            return {
                ...state,
                registrationSuccess: true
            };

        case 'PLATFORM':
            return {
                ...state,
                platform: action.payload
            };

        case 'ACTIVATE_TIMER':
            return {
                ...state,
                timerIsActive: true,
                countdownTimer: action.payload.timer,
                countdownVolume: action.payload.volume,
                timerButtonID: action.payload.id
            };

        case 'DEACTIVATE_TIMER':
            return {
                ...state,
                timerIsActive: false,
                countdownTimer: 0,
                timerButtonID: ''
            };

        default:
            return state;
    }
};

export default reducer;