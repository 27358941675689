import breakpoint from "./breakpoints";

export default function nicknameWidth(page) {
    let width;
    if (breakpoint() === 'XL') {
        width = 'nickname-width-45';
    } else if (breakpoint() === 'L') {
        width = 'nickname-width-40';
    } else if (breakpoint() === 'M') {
        width = 'nickname-width-30';
    } else if (breakpoint() === 'S') {
        width = page === 'day' || page === 'end' ? 'nickname-width-15' : 'nickname-width-20';
    } else if (breakpoint() === 'XS') {
        width = page === 'public-game' ? 'nickname-width-20' : 'nickname-width-10';
    }
    return width;
}