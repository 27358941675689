import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from "../../../redux/store";

class ConfigButtons extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
  }

  updateButtons(value, button) {
    const buttons = this.state.buttons;
    buttons[button] = value;
    this.setState({ buttons: buttons },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  render() {
    return (
      <div className="text-center">
        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>General buttons:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="login">Login</label>
              <input value={this.state.buttons.login}
                onChange={(e) => this.updateButtons(e.target.value, 'login')}
                type="text"
                id="login"
                maxLength="40"
                aria-label="Login"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="logout">Remind Password</label>
              <input value={this.state.buttons.remind}
                onChange={(e) => this.updateButtons(e.target.value, 'remind')}
                type="text"
                id="logout"
                maxLength="40"
                aria-label="Logout"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="update">Update</label>
              <input value={this.state.buttons.update}
                onChange={(e) => this.updateButtons(e.target.value, 'update')}
                type="text"
                id="update"
                maxLength="40"
                aria-label="Update"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="reset">Reset</label>
              <input value={this.state.buttons.revert}
                onChange={(e) => this.updateButtons(e.target.value, 'revert')}
                type="text"
                id="reset"
                maxLength="40"
                aria-label="Reset"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="register">Register</label>
              <input value={this.state.buttons.register}
                onChange={(e) => this.updateButtons(e.target.value, 'register')}
                type="text"
                id="register"
                maxLength="40"
                aria-label="Register"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="clear">Clear</label>
              <input value={this.state.buttons.clear}
                onChange={(e) => this.updateButtons(e.target.value, 'clear')}
                type="text"
                id="clear"
                maxLength="40"
                aria-label="Clear"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>
        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Header buttons:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="backToLoginButton">Back to Login</label>
              <input value={this.state.buttons.backToLoginButton}
                onChange={(e) => this.updateButtons(e.target.value, 'backToLoginButton')}
                type="text"
                id="backToLoginButton"
                maxLength="40"
                aria-label="Back to Login"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="myAccountButton">My Account</label>
              <input value={this.state.buttons.myAccountButton}
                onChange={(e) => this.updateButtons(e.target.value, 'myAccountButton')}
                type="text"
                id="myAccountButton"
                maxLength="40"
                aria-label="My Account Button"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="ratingButton">Rating</label>
              <input value={this.state.buttons.ratingButton}
                onChange={(e) => this.updateButtons(e.target.value, 'ratingButton')}
                type="text"
                id="ratingButton"
                maxLength="40"
                aria-label="Rating Button"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="gameButton">Game</label>
              <input value={this.state.buttons.gameButton}
                onChange={(e) => this.updateButtons(e.target.value, 'gameButton')}
                type="text"
                id="gameButton"
                maxLength="40"
                aria-label="Game Button"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="registerHeaderButton">Register</label>
              <input value={this.state.buttons.registerHeaderButton}
                onChange={(e) => this.updateButtons(e.target.value, 'registerHeaderButton')}
                type="text"
                id="registerHeaderButton"
                maxLength="40"
                aria-label="Register"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="tournamentsButton">Tournaments</label>
              <input value={this.state.buttons.tournamentsButton}
                onChange={(e) => this.updateButtons(e.target.value, 'tournamentsButton')}
                type="text"
                id="tournamentsButton"
                maxLength="40"
                aria-label="Tournaments"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="logoutButton">Logout</label>
              <input value={this.state.buttons.logoutButton}
                onChange={(e) => this.updateButtons(e.target.value, 'logoutButton')}
                type="text"
                id="logoutButton"
                maxLength="40"
                aria-label="Logout"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>
        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Game buttons:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="end-game">End Game</label>
              <input value={this.state.buttons.endGame}
                onChange={(e) => this.updateButtons(e.target.value, 'endGame')}
                type="text"
                id="end-game"
                maxLength="40"
                aria-label="End-Game"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="add-comment">Add</label>
              <input value={this.state.buttons.addComment}
                onChange={(e) => this.updateButtons(e.target.value, 'addComment')}
                type="text"
                id="add-comment"
                maxLength="40"
                aria-label="Add-Comment"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="next">Next</label>
              <input value={this.state.buttons.next}
                onChange={(e) => this.updateButtons(e.target.value, 'next')}
                type="text"
                id="next"
                maxLength="40"
                aria-label="Next"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="new-game">New Game</label>
              <input value={this.state.buttons.newGame}
                onChange={(e) => this.updateButtons(e.target.value, 'newGame')}
                type="text"
                id="new-game"
                maxLength="40"
                aria-label="New-Game"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="results">Results</label>
              <input value={this.state.buttons.results}
                onChange={(e) => this.updateButtons(e.target.value, 'results')}
                type="text"
                id="results"
                maxLength="40"
                aria-label="Results"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="continue">Continue Game</label>
              <input value={this.state.buttons.continue}
                onChange={(e) => this.updateButtons(e.target.value, 'continue')}
                type="text"
                id="continue"
                maxLength="40"
                aria-label="Continue"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="start">Start Game</label>
              <input value={this.state.buttons.start}
                onChange={(e) => this.updateButtons(e.target.value, 'start')}
                type="text"
                id="start"
                maxLength="40"
                aria-label="Start"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="game-search">Game Search</label>
              <input value={this.state.buttons.gameSearch}
                onChange={(e) => this.updateButtons(e.target.value, 'gameSearch')}
                type="text"
                id="game-search"
                maxLength="40"
                aria-label="Game Search"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="search">Search</label>
              <input value={this.state.buttons.search}
                onChange={(e) => this.updateButtons(e.target.value, 'search')}
                type="text"
                id="search"
                maxLength="40"
                aria-label="Search"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="create">Create Game</label>
              <input value={this.state.buttons.create}
                onChange={(e) => this.updateButtons(e.target.value, 'create')}
                type="text"
                id="create"
                maxLength="40"
                aria-label="Create Game"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="back">Back</label>
              <input value={this.state.buttons.back}
                onChange={(e) => this.updateButtons(e.target.value, 'back')}
                type="text"
                id="back"
                maxLength="40"
                aria-label="Back"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="miss">Miss</label>
              <input value={this.state.buttons.miss}
                onChange={(e) => this.updateButtons(e.target.value, 'miss')}
                type="text"
                id="miss"
                maxLength="40"
                aria-label="Miss"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
});

export default connect(mapStateToProps)(ConfigButtons);