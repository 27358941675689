import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from "react-router-dom";
import store from "../../redux/store";
import axios from "axios";
import server from '../../server';
import TimerButtons from './helpers/TimerButtons';
import trimString from '../../helpers/trim-string';
import howMuchTrim from '../../helpers/trim-amount';
import nickNameWidth from '../../helpers/nickname-width';
import nominationsRender from '../../helpers/nominations-render';

import { IoVolumeMuteSharp } from "react-icons/io5";
import { GiDeadHead } from "react-icons/gi";
import { BsExclamationOctagon } from "react-icons/bs";
import { FaBan } from "react-icons/fa";
import { FaMedkit } from "react-icons/fa";

class Day extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentGame.startDate !== undefined ? this.props.currentGame : {};
    this.state.stopTalkFlag = false;
  }

  componentDidMount() {
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
    if (this.props.currentGame.activePlayer !== undefined) {
      if (this.props.currentGame.activePlayer === 0) {
        this.setState({ activePlayer: this.state.firstWord },
          () => this.updateGame(this.state))
      }
      let talked = [];
      if (!this.criticalRound()) {
        talked = this.state.talked
        this.state.players.forEach(el => {
          this.state.cycle === el.mutedCycle && talked.push(el.no);
        })
        this.setState({ talked: talked },
          () => this.updateGame(this.state))
      }
      if (this.props.currentGame.cycle === 2 && (this.props.currentGame.deadPlayers.length + this.props.currentGame.disqualified.length) < 3 && this.props.currentGame.killedLastNight === true) {
        this.setState({ bestMovePlayer: this.justKilled() }, () => this.updateGame(this.state))
      }
      if (this.drawCheck()) {
        store.dispatch({
          type: 'END_GAME'
        })
      }
    }
  }

  drawCheck() {
    let lastThreeDays = this.findThreeLastDays([], this.props.currentGame);
    if (lastThreeDays.length === 4) {
      return lastThreeDays[0].length === lastThreeDays[3].length;
    } else return false

  }

  findThreeLastDays(array, game) {
    if (array.length < 4 && game) {
      if (game.phase === '/game/day') {
        array = [...array, game.deadPlayers]
        return this.findThreeLastDays(array, game.prevState)
      } else {
        return this.findThreeLastDays(array, game.prevState)
      }
    } else return array;
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    })
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
  }

  back(game) {
    this.updateGame(game.prevState);
    this.props.history.goBack();
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + this.state._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  firstWord() {
    this.props.currentGame.players.find(el => {
      return el.no === this.props.currentGame.firstWord
    })
    const game = this.props.currentGame;
    if (game.firstWord > 10) {
      game.firstWord = 1;
    }
    while (game.deadPlayers.includes(game.firstWord) || (this.state.players.some(el => el.no === game.firstWord) && this.state.players.find(el => el.no === game.firstWord).mutedCycle === this.props.currentGame.cycle)) {
      game.firstWord++;
    }
    return game.firstWord;
  }

  justKilled() {
    let killed = this.props.currentGame.deadPlayers;
    return killed[killed.length - 1];
  }

  updatePlayer(no, event) {
    this.refreshState();
    const gamers = this.state.players;
    let talked = this.props.currentGame.talked;
    if (event === '+') {
      gamers.forEach(el => {
        if (el.no === no) {
          el.fouls = el.fouls.concat('•');
          if (el.fouls === '•••') {
            if (talked.includes(no)) {
              el.mutedCycle = this.state.cycle + 1;
            } else {
              el.mutedCycle = this.state.cycle;
              talked.push(no);
            }
            this.setState({ players: gamers, talked: talked }, () => store.dispatch({
              type: 'GAME',
              payload: this.state
            }));
          } else if (el.fouls === '••••') {
            talked = talked.filter(t => t !== no)
            this.setState({ players: gamers, talked: talked }, () => store.dispatch({
              type: 'GAME',
              payload: this.state
            }));
            this.banPlayer(no);
          }
        }
      })
    } else if (event === '-') {
      gamers.forEach(el => {
        if (el.no === no) {
          if (el.fouls === '•••') {
            if (el.mutedCycle === this.state.cycle) talked = talked.filter(t => t !== no)
            delete el.mutedCycle;
          }
          el.fouls = el.fouls.slice(1);
          this.setState({ players: gamers, talked: talked }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
        }
      });
    } else if (event === 'life') {
      gamers.forEach(el => {
        if (el.no === no) {
          el.fouls = el.fouls.slice(1);
          this.setState({ players: gamers, talked: talked }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
          this.allivePlayer(no);
        }
      })
    }
  }

  vote(value, event) {
    this.refreshState();
    if (event === '+') {
      const array = this.state.vote;
      if (array.find(el => el === value) === undefined) {
        array.push(value);
        this.setState({
          vote: array
        }, () =>
          store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
      }
    }
    if (event === '-') {
      const array = this.state.vote.filter(el => el !== value);
      this.setState({
        vote: array
      }, () =>
        store.dispatch({
          type: 'GAME',
          payload: this.state
        }));
    }
  }

  playerDead(number) {
    const player = this.props.currentGame.players.find(el => el.no === number);
    return !player.alive;
  }

  whoWon() {
    if (this.drawCheck()) {
      return 0
    } else {
      const gamers = this.state.players;
      const civilians = gamers
        .filter(el => el.alive === true)
        .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
      const mafia = gamers
        .filter(el => el.alive === true)
        .filter(el => el.role === 'Mafia' || el.role === 'Don');
      return mafia.length >= civilians.length ? 1 : 2
    }
  }

  getTime() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    return `${year}/${month + 1}/${day}_${hours}:${minutes < 10 ? 0 + minutes.toString() : minutes}`;
  }

  killPlayer(no) {
    const gamers = this.state.players;
    const dead = this.state.deadPlayers;
    gamers.forEach(el => el.no === no ? el.alive = false : null);
    dead.push(no);
    this.setState({
      players: gamers,
      deadPlayers: dead,
      vote: []
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (civilians.length <= mafia.length || mafia.length === 0) {
      store.dispatch({
        type: 'END_GAME'
      })
    }
  }

  banPlayer(no) {
    const gamers = this.state.players;
    const disqualified = this.state.disqualified;
    gamers.forEach(el => el.no === no ? el.alive = false : null);
    disqualified.push(no);
    this.setState({
      players: gamers,
      disqualified: disqualified,
      vote: [],
      skipNextVoting: this.state.cycle
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (civilians.length <= mafia.length || mafia.length === 0) {
      store.dispatch({
        type: 'END_GAME'
      })
    }
  }

  allivePlayer(no) {
    const gamers = this.state.players;
    gamers.forEach(el => el.no === no ? el.alive = true : null);
    const dead = this.state.deadPlayers.filter(el => el !== no);
    const banned = this.state.disqualified.filter(el => el !== no);
    this.setState({
      players: gamers,
      deadPlayers: dead,
      disqualified: banned,
      skipNextVoting: 0
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (!(civilians.length <= mafia.length || mafia.length === 0)) {
      store.dispatch({
        type: 'NO_END_GAME'
      })
    }
  }

  addComment() {
    const allComments = this.state.comments;
    let comment = this.state.currentComment;
    allComments.push(comment);
    this.setState({
      currentComment: '',
      comments: allComments
    });
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.addComment()
    }
  }

  processPlayer(el) {
    let res = '';
    if (!el.alive) {
      res = 'grayBack';
    } else if (el.no === this.props.currentGame.activePlayer) {
      res = 'active-player';
    } else if (this.props.currentGame.talked.includes(el.no) || el.mutedCycle === this.props.currentGame.cycle) {
      res = 'talked-player';
    }
    return res;
  }

  refreshState() {
    this.setState({ ...this.props.currentGame });
  }

  unmute(no) {
    let gamers = this.state.players;
    let talked = this.state.talked;
    gamers.forEach(el => {
      if (el.no === no) {
        delete el.mutedCycle;
        talked = talked.filter(t => t !== no);
      }
    })
    this.setState({ players: gamers, talked: talked }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
  }

  criticalRound() {
    return this.props.currentGame.players.filter(el => el.alive).length <= 4;
  }

  stopTalk(value) {
    this.setState({ stopTalkFlag: value });
  }

  disableVotingButtons(el) {
    return this.playerDead(el) || this.state.skipNextVoting === this.state.cycle;
  }

  render() {
    return this.props.currentUser.email === undefined && this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/public-game' />
      :
      this.props.currentGame.startDate === undefined
        ?
        <Redirect to='/game' />
        :
        (
          <div className={this.props.isGame ? '' : 'page-wrap'} id='day-page'>

            <div className="mt-3 mb-3">

              {this.props.currentGame.type === 'public' ?
                <div className="card">
                  <div className='card-body p-1 text-sm'>
                    {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                  </div>
                </div>
                :
                null
              }

              <div className="card mt-2 custom-thead">
                <div className='card-body p-1'>
                  <strong>{this.props.config.labels.gameDay} #{this.state.cycle}</strong>
                </div>
              </div>

              <div className="card">
                <div className='card-body p-2'>
                  <div>
                    <div className="stream-text">
                      {this.props.config.messages.streamDay}
                    </div>
                    <div>{this.props.config.texts.goodMorningCity}</div>

                    {
                      this.props.currentGame.cycle === 1
                        ?
                        <span>
                          {this.props.config.texts.morning1}
                        </span>
                        :
                        this.props.currentGame.killedLastNight === true
                          ?
                          <div>
                            <span>{this.props.config.texts.badMorning.replace('{N}', this.justKilled())}</span>
                            <div className='mt-2 mb-2'>
                              <TimerButtons
                                timer={60}
                                volume={'low'}
                                size={'big'}
                                singleButton={true}
                                id={'badMorning'}
                              />
                            </div>
                          </div>
                          :
                          <span>
                            {this.props.config.texts.goodMorning}
                          </span>
                    }
                  </div>
                  {(this.props.currentGame.killedLastNight === true)
                    ?
                    <div>
                      <span>{this.props.config.texts.applause.replace('{N}', this.justKilled())}</span>
                    </div>
                    :
                    null
                  }
                  <div>
                    {this.props.config.texts.openTable.replace('{N}', this.firstWord())}
                  </div>
                </div>
              </div>

              {this.props.currentGame.players.map(el =>
                <div
                  key={el._id}
                  className={`d-flex justify-content-between p-1 ${this.processPlayer(el)}`}
                >
                  <span>
                    <strong className='mr-1 number-container'>{el.no}</strong>
                    {el.alive ?
                      <FaBan
                        alt='Ban'
                        title='Ban this player'
                        className='hover-item button-icon mb-1'
                        onClick={() => this.banPlayer(el.no)}
                      />
                      :
                      <FaMedkit
                        alt='help'
                        title='Return this player'
                        className='hover-item button-icon mb-1'
                        onClick={() => this.updatePlayer(el.no, 'life')}
                      />
                    }
                  </span>

                  <span className={nickNameWidth('day')}>
                    <b>{trimString(el.nickName, howMuchTrim('day'))}</b>
                  </span>

                  <span className='right-most-container'>
                    {el.alive ?
                      < span >
                        {el.mutedCycle !== this.props.currentGame.cycle && el.mutedCycle !== this.props.currentGame.cycle + 1 ?
                          <TimerButtons
                            timer={60}
                            volume={'low'}
                            size={'small'}
                            activate={true}
                            player={el.no}
                            singleButton={true}
                            id={`day-player-${el.no}`}
                          />
                          :
                          this.criticalRound() ?
                            <TimerButtons
                              timer={30}
                              volume={'low'}
                              size={'small'}
                              activate={true}
                              player={el.no}
                              singleButton={true}
                              id={`day-player-${el.no}`}
                            />
                            :
                            <span className='content-middle'>
                              <IoVolumeMuteSharp
                                onClick={() => this.unmute(el.no)}
                                alt='Unmute'
                                className='hover-item timer-icon-sm mr-1'
                              />
                            </span>
                        }
                        <BsExclamationOctagon
                          alt='Foul'
                          title='Add a foul'
                          className='hover-item timer-icon-sm'
                          onClick={() => this.updatePlayer(el.no, '+')}
                        />
                        <span
                          className='ml-1 mr-1 remarks-container hover-item'
                          title='Remove a foul'
                          onClick={() => this.updatePlayer(el.no, '-')}>
                          <b>{el.fouls}</b>
                        </span>
                      </span>
                      :
                      <span className='content-middle'>
                        <GiDeadHead
                          alt='dead'
                          className='timer-icon-sm mr-1'
                        />
                        <FaMedkit
                          alt='help'
                          title='Return this player'
                          className='hover-item timer-icon-sm'
                          onClick={() => this.updatePlayer(el.no, 'life')}
                        />
                        <span className='ml-1 mr-1 remarks-container'></span>
                      </span>
                    }
                  </span>
                </div>
              )}

              <div className="card mt-2 mb-2">
                <div className='card-body d-flex justify-content-between p-1'>
                  {
                    this.state.prevState &&
                    <button
                      id='back-btn'
                      className='btn btn-sm btn-warning m-1 day-back-button nav-button'
                      onClick={() => this.back(this.state)}>
                      {this.props.config.buttons.back}
                    </button>
                  }
                  <div>
                    {nominationsRender().buttons
                      ? <div>
                        <div>{[1, 2, 3, 4, 5].map(el => //TODO: config
                          <button id={`vote-${el}`}
                            key={`vote-${el}`}
                            className={`vote-button 
                            ${nominationsRender().small ? 'vote-button-xs' : 'vote-button-sm'} 
                            ${(this.props.currentGame.vote.includes(el) || this.disableVotingButtons(el)) && 'vote-selected'}
                            ${this.disableVotingButtons(el) ? 'custom-tooltip' : null}`}
                            onClick={() => this.props.currentGame.vote.includes(el) ? this.vote(el, '-') : this.vote(el, '+')}
                            disabled={this.disableVotingButtons(el)}>
                            {el}
                            {this.disableVotingButtons(el) ? <span id='skip-voting-tooltip' className="custom-tooltiptext">Voting is cancelled due to a banned player</span> : null}
                          </button>)}</div>
                        <div>{[6, 7, 8, 9, 10].map(el => //TODO: config
                          <button id={`vote-${el}`}
                            key={`vote-${el}`}
                            className={`vote-button 
                            ${nominationsRender().small ? 'vote-button-xs' : 'vote-button-sm'} 
                            ${(this.props.currentGame.vote.includes(el) || this.disableVotingButtons(el)) && 'vote-selected'}
                            ${this.disableVotingButtons(el) ? 'custom-tooltip' : null}`}
                            onClick={() => this.props.currentGame.vote.includes(el) ? this.vote(el, '-') : this.vote(el, '+')}
                            disabled={this.disableVotingButtons(el)}>
                            {el}
                            {this.disableVotingButtons(el) ? <span id='skip-voting-tooltip' className="custom-tooltiptext">Voting is cancelled due to a banned player</span> : null}
                          </button>)}</div>
                      </div>
                      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(el => //TODO: config
                        <button id={`vote-${el}`}
                          key={`vote-${el}`}
                          className={`vote-button 
                            ${nominationsRender().small ? 'vote-button-xs' : 'vote-button-sm'} 
                            ${(this.props.currentGame.vote.includes(el) || this.disableVotingButtons(el)) && 'vote-selected'}`}
                          onClick={() => this.props.currentGame.vote.includes(el) ? this.vote(el, '-') : this.vote(el, '+')}
                          disabled={this.disableVotingButtons(el)}>
                          {el}
                        </button>
                      )
                    }

                  </div>
                  {this.props.almostDone
                    ?
                    <Link className="nav-button" to={'/game/end'}>
                      <button
                        id='end-btn'
                        className='btn btn-sm btn-warning m-1'
                        onClick={() => this.updateGame({
                          ...this.state,
                          prevState: JSON.parse(JSON.stringify(this.state)),
                          phase: '/game/end',
                          endDate: this.getTime(),
                          whoWon: this.whoWon()
                        })}>{this.props.config.buttons.endGame}
                      </button>
                    </Link>
                    :
                    <Link className="nav-button" to={'/game/vote'}>
                      <button
                        id='next-btn'
                        className='btn btn-sm btn-warning m-1'
                        //disabled={!(this.props.currentGame.players.filter(el => el.alive === true).length === this.props.currentGame.talked.length)}
                        onClick={() => this.updateGame({
                          ...this.state,
                          prevState: JSON.parse(JSON.stringify(this.state)),
                          phase: '/game/vote',
                          killedLastNight: false,
                          activePlayer: 0,
                          talked: []
                        })}>{this.props.config.buttons.next}
                      </button>
                    </Link>
                  }
                </div>
              </div>

              <div className="input-group">

                <input
                  className="form-control form-control-sm"
                  value={this.state.currentComment}
                  placeholder={this.props.config.labels.comment}
                  maxLength='250'
                  id='add_comment_text'
                  onKeyDown={(e) => this.pressEnter(e)}
                  onChange={(e) =>
                    this.setState({
                      currentComment: e.target.value
                    }, () =>
                      store.dispatch({
                        type: 'NO_ERROR'
                      }))}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-sm btn-primary align-right"
                    id='add_comment'
                    disabled={this.state.currentComment.trim().length === 0}
                    onClick={() => this.addComment()}>
                    {this.props.config.buttons.addComment}
                  </button>
                </div>
              </div>

            </div>

          </div >
        );
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  currentUser: state.currentUser,
  almostDone: state.almostDone,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(withRouter(Day));