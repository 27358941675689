import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import axios from 'axios';
import server from '../server';
import Spinner from './additional/Spinner'; //For payment functionlaity
import { saveUrl, inProgressItem, noProgressItem, loadUsers, inProgress, noProgress } from '../actions';
import trimString from '../helpers/trim-string';
import howMuchTrim from '../helpers/trim-amount';
import now from '../helpers/time-now';
import addMargin from '../helpers/add-margin';
import breakpoint from '../helpers/breakpoints';
import { GrUpdate } from 'react-icons/gr';
import { AiOutlineNumber } from "react-icons/ai";
import { RiNumbersFill } from "react-icons/ri";
import { FaMedal, FaPlusCircle, FaMinusCircle, FaPercent } from "react-icons/fa";

const nowInMS = new Date().valueOf();
const today = new Date(nowInMS).toLocaleDateString("fr-CA");
const monthBack = new Date(nowInMS - 2629800000).toLocaleDateString("fr-CA"); //month back

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      pages: 0,
      show: [],
      currentPage: 1,
      search: '',
      dateFrom: monthBack,
      dateTo: today
    };
  }

  componentDidMount() {
    this.props.saveUrl(this.props.location.pathname);
    this.getUsers();
  }

  getUsers() {
    this.props.inProgress();
    const content = this;
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        const data = res.data;
        data.forEach((el, i) => {
          el.no = i + 1;
        });
        content.setState({
          users: data,
          pages: Math.ceil(data.length / this.props.config.usersPerPage),
          show: data.slice(0, this.props.config.usersPerPage)
        }, () => content.props.loadUsers(data));
      })
      .catch(error => console.log(error))
      .finally(() => {
        content.getRating();
      });
  }

  getRating() {
    const dateFrom = `${this.state.dateFrom} 00:00:00`;
    const dateTo = `${this.state.dateTo} 23:59:59`;
    const dateFromInMS = new Date(dateFrom).valueOf();
    const dateToInMS = new Date(dateTo).valueOf()
    const content = this;
    axios.get(server.address + '/rating/period?fromDate=' + dateFromInMS + '&toDate=' + dateToInMS + '&combined=true')
      .then(res => {
        content.setState({
          rating: res.data,
        });
      })
      .catch(error => console.log(error))
      .finally(() => {
        content.getCombinedRating()
      });
  }

  getCombinedRating() {
    const ratingArray = this.state.rating;
    const users = this.state.users
      .filter(el => ratingArray.find(rating => rating.userId === el._id) !== undefined)
      .map(player => {
        const rating = ratingArray.find(rat => rat.userId === player._id);
        const playerObj = {
          dob: player.dob,
          email: player.email,
          firstName: player.firstName,
          lastName: player.lastName,
          nickName: player.nickName,
          no: player.no,
          sex: player.sex,
          visits: player.visits,
          _id: player._id,
          rating: rating.rating,
          additionalPoints: rating.additionalPoints,
          firstKilled: rating.firstKilled,
          loses: rating.lose,
          wins: rating.win,
          penaltyPoints: rating.penaltyPoints,
          roleGames: rating.roleGames,
          roleWins: rating.roleWins
        }
        return playerObj;
      });
    const data = users.sort((a, b) =>
      (b.rating + b.additionalPoints - b.penaltyPoints) -
      (a.rating + a.additionalPoints - a.penaltyPoints)
    );
    data.forEach((el, i) => {
      el.no = i + 1;
    });
    this.setState({
      users: data,
      pages: Math.ceil(data.length / this.props.config.usersPerPage),
      show: data.slice(0, this.props.config.usersPerPage)
    }, () => {
      this.props.loadUsers(data);
      this.props.noProgress();
    });
  }

  searchUsers() {
    const content = this;
    let list = this.props.users;
    let array = this.state.search.split(' ');
    let compareArray = list;

    array.forEach(function (word) {
      let res = list.filter(el =>
        el.firstName.toLowerCase().includes(word.toLowerCase()) ||
        el.lastName.toLowerCase().includes(word.toLowerCase()) ||
        el.nickName.toLowerCase().includes(word.toLowerCase()));
      const intersection = compareArray.filter(element => res.includes(element));
      compareArray = intersection;
      content.setState({
        users: intersection,
        currentPage: 1,
        pages: Math.ceil(intersection.length / content.props.config.usersPerPage),
        show: intersection.slice(0, content.props.config.usersPerPage),
      });
    });
  }

  paidNow(user) {
    if ((now() - user.lastTimePaid) < 86400000) {
      user.lastTimePaid = 0;
      user.visits = user.visits - 1;
    } else {
      user.lastTimePaid = now();
      user.visits = user.visits + 1;
    }
    this.updateData(user);
  }

  updateData(user) {
    const content = this;
    this.props.inProgressItem(user.no)
    axios.patch(server.address + '/mafia-users/' + user._id, user)
      .then(() => content.updateList())
      .catch(error => console.log(error))
  }

  updateList() {
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        const data = res.data.sort((a, b) => b.rating - a.rating);
        data.forEach((el, i) => el.no = i + 1);
        this.props.loadUsers(data);
      })
      .then(() => {
        this.setState({ users: this.props.users },
          () => this.props.noProgressItem());
      })
      .catch(error => console.log(error))
  }

  pagePagination() {
    if (this.state.pages > 9) { //if more than 9 pages then trim

      if (this.state.currentPage <= 5) {  //if the current page is below or equal to 5 then just show:
        let bar = [];

        for (let i = 1; i <= 7; i++) { //first 7 pages
          bar.push(<li key={i} id={`nav-page-btn-${i}`} className="page-item hover-item">
            <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
              onClick={() => this.handleClick(i)}>{i}
            </span></li>);
        }

        //ellipsis
        bar.push(<li key={'elipsis-1'} id={'elipsis-1'} className="page-item">
          <span className="page-elipsis">...</span></li>);

        // last page
        bar.push(<li key={this.state.pages} id={`nav-page-btn-${this.state.pages}`} className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(this.state.pages)}>{this.state.pages}</span></li>);
        return bar;
      } else if (this.state.currentPage > 5 && this.state.currentPage < this.state.pages - 4) {
        let bar = [];

        //first page
        bar.push(<li key='1' id='nav-page-btn-1' className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(1)}>1</span></li>);

        //ellipsis
        bar.push(<li key='elipsis-1' id='elipsis-1' className="page-item">
          <span className="page-elipsis">...</span></li>);

        for (let i = this.state.currentPage - 2; i <= this.state.currentPage + 2; i++) {
          bar.push(<li key={i} id={`nav-page-btn-${i}`} className="page-item hover-item">
            <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
              onClick={() => this.handleClick(i)}>{i}</span></li>);
        }

        //ellipsis
        bar.push(<li key={'elipsis-2'} id={'elipsis-2'} className="page-item">
          <span className="page-elipsis">...</span></li>);

        // last page
        bar.push(<li key={this.state.pages} id={`nav-page-btn-${this.state.pages}`} className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(this.state.pages)}>{this.state.pages}</span></li>);

        return bar;
      } else if (this.state.currentPage >= this.state.pages - 4) { //else just show six pages around the current one
        let bar = [];

        //first page
        bar.push(<li key='1' id='nav-page-btn-1' className="page-item hover-item">
          <span className="page-link"
            onClick={() => this.handleClick(1)}>1</span></li>);

        //ellipsis
        bar.push(<li key='elipsis-1' id='elipsis-1' className="page-item">
          <span className="page-elipsis">...</span></li>);

        for (let i = this.state.pages - 6; i <= this.state.pages; i++) {
          bar.push(<li key={i} id={`nav-page-btn-${i}`} className="page-item hover-item">
            <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
              onClick={() => this.handleClick(i)}>{i}</span></li>);
        }

        return bar;
      }
    } else { //if 9 pages or less then just show the list of all pages
      let bar = [];

      for (let i = 1; i <= this.state.pages; i++) {
        bar.push(<li key={i} className="page-item hover-item">
          <span className={`page-link ${this.state.currentPage === i ? 'current-link' : ''}`}
            onClick={() => this.handleClick(i)}>{i}</span></li>);
      }

      return bar;
    }
  }

  handleClick(page) {
    if (this.state.currentPage > 1 && page === 'prev') {
      this.setState({ currentPage: this.state.currentPage - 1 }, () => this.updateShow())
    } else if (this.state.currentPage < this.state.pages && page === 'next') {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => this.updateShow())
    } else if (Number.isInteger(page)) {
      this.setState({ currentPage: page }, () => this.updateShow())
    }
  }

  updateShow() {
    this.setState({
      show: this.state.users.slice((this.state.currentPage - 1) * this.props.config.usersPerPage,
        this.props.config.usersPerPage * this.state.currentPage)
    });
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.setState({ search: '' }, () => this.refreshList())
    }
  }

  refreshList() {
    const list = this.props.users;
    this.setState({
      users: list,
      pages: Math.ceil(list.length / this.props.config.usersPerPage),
      show: list.slice(0, this.props.config.usersPerPage)
    })
  }

  render() {
    if (this.props.progress === true) {
      return (<Spinner margin={true} />)
    } else {
      return (
        <div id='ratings' className="page-wrap center">

          <div className="form-row mt-3">
            <div className="form-group col-md-2">
              <span id='filter-title' style={{ verticalAlign: "middle" }}>{this.props.config.labels.dateRangeLabel}</span>
            </div>
            <div className="form-group col-md-4">
              <input className="form-control form-control-sm"
                type="date"
                id="date-from-input"
                value={this.state.dateFrom}
                onChange={(e) => this.setState({ dateFrom: e.target.value })}
              />
            </div>
            <div className="form-group col-md-4">
              <input className="form-control form-control-sm"
                type="date"
                id="date-to-input"
                value={this.state.dateTo}
                onChange={(e) => this.setState({ dateTo: e.target.value })}
              />
            </div>
            <div className="form-group col-md-2">
              <button className='btn btn-sm btn-outline-primary' onClick={() => this.getUsers()}><GrUpdate className="mr-2" />
                {this.props.config.buttons.update}
              </button>
            </div>
          </div>

          <div>
            <input className="form-control form-control-sm"
              type="text"
              id="search"
              maxLength={100}
              value={this.state.search}
              placeholder={this.props.config.labels.search}
              onChange={(e) => this.setState({ search: e.target.value }, () => this.searchUsers())}
              onKeyPress={(e) => this.pressEnter(e)}
            />
          </div>
          <table
            id='rating-table'
            className={addMargin(this.state.show.length)}
          >
            <thead id='rating-table-head' className='thead-light'>
              <tr className='players-table-row'>
                <th id='number'>{breakpoint() === 'XS' ? <AiOutlineNumber className='button-icon' /> : this.props.config.labels.placeLabel}</th>
                <th id='nickname'>{this.props.config.labels.nicknameLabel}</th>
                <th id='rating'>{breakpoint() === 'XS' ? <RiNumbersFill className='button-icon' /> : this.props.config.labels.ratingLabel}</th>
                {breakpoint() === 'XS' ? null : <th id='games'>{this.props.config.labels.numberOfGamesLabel}</th>}
                <th id='wins'>{breakpoint() === 'XS' ? <FaMedal className='button-icon' /> : this.props.config.labels.victoriesLabel}</th>
                <th id='bonus'>{breakpoint() === 'XS' ? <FaPlusCircle className='button-icon' /> : this.props.config.labels.bonusLabel}</th>
                <th id='penalty'>{breakpoint() === 'XS' ? <FaMinusCircle className='button-icon' /> : this.props.config.labels.penaltyLabel}</th>
                <th id='percent'>{breakpoint() === 'XS' ? <FaPercent className='button-icon' /> : this.props.config.labels.percentVictoriesLabel}</th>
                {breakpoint() === 'XS' ? null : <th id='first-killed'>{this.props.config.labels.firstKilledLabel}</th>}
                {breakpoint() === 'XS' ? null : <th id='roles-rating'>{this.props.config.labels.rolesRatingLabel}</th>}
              </tr>
            </thead>
            {this.state.show.length > 0 ?
              <tbody id='rating-table-body'>
                {this.state.show.map((el, i) =>
                  <tr
                    key={el._id}
                    id={`player_${el.no || 0}`}
                    className='players-row players-table-row'>
                    <td id={`no_${el.no || 0}`}>{el.no ? el.no : 0}</td>
                    <td id={`nickname_${el.no || 0}`} title={`${el.firstName} ${el.lastName}`}>
                      {this.props.currentUser.admin === true || this.props.currentUser.moderator === true ?
                        <div onClick={() => this.props.inProgress()}>
                          <Link to={`/users/${el._id}`}>
                            {trimString(el.nickName, howMuchTrim())}
                          </Link>
                        </div>
                        :
                        trimString(el.nickName, howMuchTrim())}
                    </td>
                    <td id={`rating_${el.no || 0}`}>{Math.round((el.rating + el.additionalPoints - el.penaltyPoints) * 100) / 100}</td>
                    {breakpoint() === 'XS' ? null : <td id={`games_${el.no || 0}`}>{el.wins ? (el.wins + el.loses) : 0}</td>}
                    <td id={`wins_${el.no || 0}`}>{el.wins ? el.wins : 0}</td>
                    <td id={`additional_points_${el.no || 0}`}>{el.additionalPoints ? Math.round((el.additionalPoints) * 100) / 100 : 0}</td>
                    <td id={`penalty_points_${el.no || 0}`}>{el.penaltyPoints ? Math.round((el.penaltyPoints) * 100) / 100 : 0}</td>
                    <td id={`percent_${el.no || 0}`}>{el.wins ? el.wins === 0 ? 0 : parseInt(el.wins / (el.wins + el.loses) * 100) : 0}</td>
                    {breakpoint() === 'XS' ? null : <td id={`times_first_killed_${el.no || 0}`}>{el.firstKilled ? el.firstKilled : 0}</td>}
                    {breakpoint() === 'XS' ? null : <td id={`roles_${el.no || 0}`}> {`${el.roleWins ? `${el.roleWins.Don || 0}/${el.roleWins.Mafia || 0}/${el.roleWins.Sheriff || 0}/${el.roleWins.Citizen || 0}` : `0`}`}</td>}
                  </tr>
                )}
              </tbody>
              :
              <tbody id='search-nothing'>
                <tr>
                  <td colSpan={this.props.currentUser.admin === true || this.props.currentUser.moderator === true ? 8 : 7}>
                    <div className='empty-list'>
                      {this.props.config.messages.noSearchRes}
                    </div>
                  </td>
                </tr>
              </tbody>
            }
          </table>

          {
            this.state.pages > 1
            &&
            <nav aria-label="Page navigation" id='page-navigation'>
              <ul className="pagination content-middle">
                {this.pagePagination()}
              </ul>
            </nav>
          }

        </div >
      );
    }
  }
}

const mapStateToProps = state => ({
  users: state.users,
  config: state.config,
  currentUser: state.currentUser,
  itemProgress: state.itemProgress,
  item: state.item,
  progress: state.progress
});

const mapDispatchToProps = dispatch => ({
  saveUrl: args => dispatch(saveUrl(args)),
  inProgressItem: args => dispatch(inProgressItem(args)),
  noProgressItem: () => dispatch(noProgressItem()),
  loadUsers: args => dispatch(loadUsers(args)),
  inProgress: () => dispatch(inProgress()),
  noProgress: () => dispatch(noProgress())
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);