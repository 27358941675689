import React, { Component } from 'react';
import '../css/custom.css';
import { connect } from 'react-redux';
import store from "../redux/store";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Spinner from "./additional/Spinner";
import server from '../server';
import Logo from './logo/Logo';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      sent: false
    };
  }

  componentDidMount() {
    store.dispatch({ type: 'NO_ERROR', });
    store.dispatch({ type: 'NO_PROGRESS' });
    store.dispatch({ type: 'URL', payload: this.props.location.pathname });
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.firstCheck()
    }
  }

  firstCheck() {
    this.state.email.trim().length === 0 ?
      store.dispatch({
        type: 'ERROR',
        payload: {
          errorText: this.props.config.messages.specifyEmail,
          success: false
        }
      })
      :
      this.forgotValidation();
  }

  doesNotExist() {
    store.dispatch({
      type: 'ERROR',
      payload: {
        errorText: this.props.config.messages.doesNotExist,
        success: false
      }
    });
    store.dispatch({ type: 'NO_PROGRESS' });
  }

  forgotValidation() {
    store.dispatch({ type: 'PROGRESS' });
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        let find = res.data.find(el => el.email === this.state.email);
        find !== undefined

          ?

          this.sendEmail(find)

          :

          this.doesNotExist()

      })
      .catch(function (error) {
        console.log(error);
      })
  }

  sendEmail(find) {
    const content = this;
    axios.post(server.address + '/mafia-email/', find)
      .then(res => {
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: content.props.config.messages.reminderSent,
            success: true
          }
        });
        setTimeout(() => this.setState({ sent: true }), 3000);
      })
      .catch(function (error) {
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: content.props.config.messages.serverError,
            success: false
          }
        })
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  render() {
    if (this.props.currentUser.email !== undefined) {
      return <Redirect to='/users' />
    } else if (this.state.sent === true) {
      return <Redirect to='/' />
    } else {
      return (
        <div id='forgot-page' className="page-wrap center">

          <div className="login-form">

            <Logo />

            <div className='match-with-login'>
              <input type="email"
                className="form-control mb-3 mt-3"
                id="email"
                maxLength="100"
                aria-describedby="emailHelp"
                placeholder={this.props.config.labels.loginEmail}
                value={this.state.email}
                onKeyDown={(e) => this.pressEnter(e)}
                onChange={(input) => this.setState({ email: input.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
              />

              <button
                className="btn btn-sm btn-primary mb-3"
                id='btn-remind'
                onClick={() => this.firstCheck()}>
                {this.props.config.buttons.remind}
              </button>

              {this.props.progress ?
                <Spinner />
                :
                this.props.errorState ?
                  <div>
                    <span className={this.props.success ? 'green-text' : 'error-text'} id='error-text'>
                      {this.props.errorText}
                    </span>
                  </div>
                  :
                  <div>
                    <span className='error-placeholder' />
                  </div>
              }

            </div>
          </div>

        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  errorState: state.errorState,
  errorText: state.errorText,
  progress: state.progress,
  config: state.config,
  success: state.success,
  currentUser: state.currentUser
});

export default connect(mapStateToProps)(Forgot);