import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import store from "../../redux/store";
import Spinner from '../additional/Spinner';
import Restricted from '../additional/Restricted';
import axios from 'axios';
import server from '../../server';

import { FaMale, FaFemale } from "react-icons/fa";

const initialState = {
  firstName: '',
  lastName: '',
  nickName: '',
  email: '',
  pass: '',
  admin: false,
  moderator: false,
  sex: '',
  dob: '',
  lastTimePaid: now()
}

function now() {
  let date = new Date();
  return date.getTime();
}

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    store.dispatch({ type: 'NO_ERROR', });
    store.dispatch({ type: 'URL', payload: this.props.location.pathname });
    store.dispatch({ type: 'NO_PROGRESS' });
    let password = this.randomPass(10);
    this.setState({
      pass: password
    });
  }

  randomPass(n) {
    const digitsLetters = [
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i',
      'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r',
      's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
    ];
    let res = '';
    while (n > 0) {
      res += digitsLetters[Math.floor(Math.random() * 35)];
      n--;
    }
    return res;
  }

  submitReg() {
    this.checkFields() ?
      store.dispatch({
        type: 'ERROR',
        payload: {
          errorText: this.props.config.messages.fillAllFields
        }
      })
      :
      this.checkGender() ?
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: this.props.config.messages.selSex
          }
        })
        :
        this.compareUsers();
  }

  checkGender() {
    return this.state.sex.trim().length === 0;
  }

  compareUsers() {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        let findEmail = res.data.find(el => el.email === this.state.email);
        let findUser = res.data.find(el => el.nickName === this.state.nickName);
        findEmail !== undefined ?
          store.dispatch({
            type: 'ERROR',
            payload: {
              errorText: content.props.config.messages.emailTaken
            }
          })
          :
          findUser !== undefined ?
            store.dispatch({
              type: 'ERROR',
              payload: {
                errorText: content.props.config.messages.nickTaken
              }
            })
            :
            this.postData()
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  clearAll() {
    this.setState(initialState)
  }

  postData() {
    const content = this;
    axios.post(server.address + '/mafia-users/', this.state)
      .then(function (response) {
        const user = response.data.user;
        content.pushRating(user._id)
        content.clearAll();
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: content.props.config.messages.userReg.replace('{user}', user.nickName),
            success: true
          }
        });
        setTimeout(() =>
          content.componentDidMount(), 3000)
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  pushRating(id) {
    const endDate = Date.now()
    const ratingObj = {
      userId: id,
      gameId: null,
      rating: 0,
      role: null,
      additionalPoints: 0,
      penaltyPoints: 0,
      firstKilled: false,
      win: 0,
      lose: 0,
      date: endDate,
      tournament: false,
      tournamentId: null,
    };
    axios.post(server.address + '/rating', ratingObj)
      .then(function (res) {
        store.dispatch({ type: 'NO_PROGRESS' });
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  checkFields() {
    return this.state.email.trim().length === 0 || this.state.nickName.trim().length === 0
      || this.state.firstName.trim().length === 0 || this.state.lastName.trim().length === 0
  }

  render() {
    if (this.props.currentUser.firstName === undefined) {
      return <Restricted />
    } else if (this.props.currentUser.admin !== true && this.props.currentUser.moderator !== true) {
      return <Restricted moderator={true} />
    } else {
      return (
        <div id='new-user-page' className='page-wrap center'>

          <div className="text-center mt-3 mb-3">

            <div className='mb-3'>
              <span className='ml-2 mr-3'>
                <FaMale
                  className={this.state.sex === 'male' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                  onClick={() => this.setState({ sex: 'male' }, () => store.dispatch({ type: 'NO_ERROR', }))}
                />
              </span>
              <span className='ml-2 mr-3'>
                <FaFemale
                  className={this.state.sex === 'female' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                  onClick={() => this.setState({ sex: 'female' }, () => store.dispatch({ type: 'NO_ERROR', }))}
                />
              </span>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName">
                  <span id='firstName-label'>{this.props.config.labels.accountFName}</span>
                </label>
                <input value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="firstName"
                  maxLength="100"
                  aria-label="First name"
                  placeholder="required *"
                  className="form-control" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastName">
                  <span id="lastName-label">{this.props.config.labels.accountLName}</span>
                </label>
                <input value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="lastName"
                  maxLength="100"
                  aria-label="Last name"
                  placeholder="required *"
                  className="form-control" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="email">
                  <span id='email-label'>{this.props.config.labels.accountEmail}</span>
                </label>
                <input value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="email"
                  id="email"
                  maxLength="100"
                  aria-label="Email"
                  placeholder="required *"
                  className="form-control" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="nickname">
                  <span id="nickname-label">{this.props.config.labels.accountNickname}</span>
                </label>
                <input value={this.state.nickName}
                  onChange={(e) => this.setState({ nickName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="nickname"
                  maxLength="100"
                  aria-label="Nickname"
                  placeholder="required *"
                  className="form-control" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="dob">
                  <span id='dob-label'>{this.props.config.labels.accountDOB}</span>
                </label>
                <input value={this.state.dob}
                  onChange={(e) => this.setState({ dob: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="date"
                  id="dob"
                  maxLength="100"
                  aria-label="DOB"
                  className="form-control" />
              </div>
            </div>

            <table width="100%" className='mb-3'>
              <tbody>
                <tr>
                  <td width="33%" className="text-left">

                  </td>
                  <td width="33%" className="text-center">

                  </td>
                  <td width="33%" className="content-center text-right">
                    <span>{this.props.config.labels.moderator}<input
                      type="checkbox"
                      name="moderator"
                      className='ml-2 hover-item'
                      checked={this.state.moderator}
                      onChange={() => this.setState({ moderator: !this.state.moderator }, () => store.dispatch({ type: 'NO_ERROR', }))} />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="mb-3">
              <button className="btn btn-sm btn-primary ml-3 mr-3"
                id="registration"
                onClick={() => this.submitReg()}>
                {this.props.config.buttons.register}
              </button>
              <button className="btn btn-sm btn-success ml-3 mr-3"
                id="clear"
                onClick={() => this.clearAll()}>
                {this.props.config.buttons.clear}
              </button>
            </div>

            {this.props.progress ?
              <Spinner />
              :
              this.props.errorState ?
                <div>
                  <span className={this.props.success ? 'green-text' : 'error-text'} id='error-text'>
                    {this.props.errorText}
                  </span>
                </div>
                :
                <div>
                  <span className='error-placeholder' />
                </div>
            }

          </div>

        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  users: state.users,
  currentUser: state.currentUser,
  config: state.config,
  errorText: state.errorText,
  errorState: state.errorState,
  progress: state.progress,
  success: state.success
});

export default connect(mapStateToProps)(NewUser);
