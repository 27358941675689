import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from "react-router-dom";
import '../../css/dropdown.css';
import store from "../../redux/store";
import axios from "axios";
import server from '../../server';
import trimString from '../../helpers/trim-string';
import howMuchTrim from '../../helpers/trim-amount';
import breakpoint from '../../helpers/breakpoints';

//role icons
import { GiLawStar, GiCutDiamond } from "react-icons/gi";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentGame.startDate !== undefined ? this.props.currentGame : {};
  }

  componentDidMount() {
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    });
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
  }

  back(game) {
    this.updateGame(game.prevState);
    this.props.history.goBack();
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + game._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  removeRole(role, id) {
    const gamers = this.state.players;
    const rolesTemp = this.state.roles;
    gamers.forEach(el => {
      if (el._id === id) {
        el.role = '';
        rolesTemp[role] = rolesTemp[role] + 1;
        this.setState({ roles: rolesTemp })
      }
    });
    this.setState({ players: gamers }, () => this.updateGame(this.state));
  }

  addRole(role, id) {
    const gamers = this.state.players;
    const rolesTemp = this.state.roles;
    gamers.forEach(el => {
      if (el._id === id) {
        if (el.role !== '') { rolesTemp[el.role] = rolesTemp[el.role] + 1 }
        el.role = role;
        rolesTemp[role] = rolesTemp[role] - 1;
        this.setState({ roles: rolesTemp })
      }
    });
    this.setState({ players: gamers }, () => this.updateGame(this.state));
  }

  rolesValidation() {
    if (this.state.roles !== undefined) {
      const rolesRemaining = Object.values(this.state.roles).reduce((acc, curr) => acc + curr);
      if (rolesRemaining === 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  dropdown() {
    let arr = this.props.config.roles.filter(el => this.props.currentGame.roles[el.role] > 0).map(el => el.translation);
    arr.push(this.props.config.labels.selectRole);
    return arr;
  }

  getRoleValue(role) {
    const find = this.props.config.roles.find(el => el.role === role);
    if (find === undefined) {
      return role;
    } else {
      return find.translation;
    }
  }

  render() {
    return this.props.currentUser.email === undefined && this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/public-game' />
      :
      this.props.currentGame.startDate === undefined
        ?
        <Redirect to='/game' />
        :
        (<div className={this.props.isGame ? '' : 'page-wrap'} id='roles-page'>
          <div className='mt-3 mb-3'>

            {this.props.currentGame.type === 'public' ?
              <div className="card">
                <div className='card-body p-1 text-sm'>
                  {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                </div>
              </div>
              :
              null
            }

            <div className="card mt-2">
              <div className='card-body p-2'>
                {this.props.config.texts.roleTxt.split('\n').map((el, i) => <div key={i}>{el}</div>)}
              </div>
            </div>

            <div className="card custom-thead mt-2">
              <div className='card-body p-1'>
                <strong>{this.props.config.labels.gameRoles}</strong>
              </div>
            </div>

            <table className='table table-sm mb-0'>
              <tbody>
                {this.props.currentGame.players.map(el =>
                  <tr key={el._id} id={`player-row-${el.no}`}>
                    {breakpoint() !== 'XS' && <td width="5%" className='content-middle'>{el.no}</td>}
                    <td className='content-middle' id={`player-nickname-${el.no}`}>
                      <b>{trimString(el.nickName, howMuchTrim())}</b>
                    </td>
                    <td className='content-middle roles-selector' id={`roles-selector-${el.no}`}>
                      <button id={`sheriff-role-for-${el.no}`}
                        key={`sheriff-role-for-${el.no}`}
                        className={`role-button role-button-sm ${el.role === 'Sheriff' && 'red-selected'} m-1`}
                        onClick={el.role === 'Sheriff' ?
                          () => this.removeRole('Sheriff', el._id)
                          :
                          () => this.addRole('Sheriff', el._id)
                        }
                        disabled={(el.role !== 'Sheriff' && this.props.currentGame.roles.Sheriff === 0)
                          || el._id === 'dummy'
                          || (el.role !== 'Sheriff' && el.role !== '')}>
                        <GiLawStar
                          className="button-icon"
                          alt='sheriff-role'
                          id='sheriff-role-icon'
                          title={this.props.config.roles.find(el => el.role === 'Sheriff').translation}
                        />
                      </button>
                      <button id={`citizen-role-for-${el.no}`}
                        key={`citizen-role-for-${el.no}`}
                        className={`role-button role-button-sm ${el.role === 'Citizen' && 'red-selected'} m-1`}
                        onClick={el.role === 'Citizen' ?
                          () => this.removeRole('Citizen', el._id)
                          :
                          () => this.addRole('Citizen', el._id)
                        }
                        disabled={(el.role !== 'Citizen' && this.props.currentGame.roles.Citizen === 0)
                          || el._id === 'dummy'
                          || (el.role !== 'Citizen' && el.role !== '')}>
                        <FaRegThumbsUp
                          className="button-icon"
                          alt='citizen-role'
                          id='citizen-role-icon'
                          title={this.props.config.roles.find(el => el.role === 'Citizen').translation}
                        />
                      </button>
                      <button id={`don-role-for-${el.no}`}
                        key={`don-role-for-${el.no}`}
                        className={`role-button role-button-sm ${el.role === 'Don' && 'black-selected'} m-1`}
                        onClick={el.role === 'Don' ?
                          () => this.removeRole('Don', el._id)
                          :
                          () => this.addRole('Don', el._id)
                        }
                        disabled={(el.role !== 'Don' && this.props.currentGame.roles.Don === 0)
                          || el._id === 'dummy'
                          || (el.role !== 'Don' && el.role !== '')}>
                        <GiCutDiamond
                          className="button-icon"
                          alt='don-role'
                          id='don-role-icon'
                          title={this.props.config.roles.find(el => el.role === 'Don').translation}
                        />
                      </button>
                      <button id={`mafia-role-for-${el.no}`}
                        key={`mafia-role-for-${el.no}`}
                        className={`role-button role-button-sm ${el.role === 'Mafia' && 'black-selected'} m-1`}
                        onClick={el.role === 'Mafia' ?
                          () => this.removeRole('Mafia', el._id)
                          :
                          () => this.addRole('Mafia', el._id)
                        }
                        disabled={(el.role !== 'Mafia' && this.props.currentGame.roles.Mafia === 0)
                          || el._id === 'dummy'
                          || (el.role !== 'Mafia' && el.role !== '')}>
                        <FaRegThumbsDown
                          className="button-icon"
                          alt='mafia-role'
                          id='mafia-role-icon'
                          title={this.props.config.roles.find(el => el.role === 'Mafia').translation}
                        />
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>


            <div className="card">
              <div className='card-body d-flex justify-content-between p-1'>
                {this.state.prevState
                  &&
                  <button
                    id='back-btn'
                    className='btn btn-sm btn-warning m-1'
                    onClick={() => this.back(this.state)}>
                    {this.props.config.buttons.back}
                  </button>}
                <Link to={'/game/night'}>
                  <button
                    id='next-btn'
                    className='btn btn-sm btn-warning m-1 custom-tooltip button-with-tooltip'
                    disabled={this.rolesValidation()}
                    onClick={() => this.updateGame({
                      ...this.state,
                      prevState: JSON.parse(JSON.stringify(this.state)),
                      phase: '/game/night',
                    })}>{this.props.config.buttons.next}
                    {this.rolesValidation() && <span className="custom-tooltiptext">{this.props.config.labels.selectAllRoles}</span>}
                  </button>
                </Link>
              </div>
            </div>

          </div>
        </div>);
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  currentUser: state.currentUser,
  config: state.config,
  platform: state.platform,
  isGame: state.isGame
});

export default connect(mapStateToProps)(withRouter(Roles));