import React, { Component } from 'react';
import '../css/custom.css';
import { connect } from 'react-redux';
import AdminMenu from "./admin/AdminMenu";
import store from "../redux/store";
import packageJSON from '../../package.json';

const version = packageJSON.version;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateLanguage(lang) {
    const config = { ...this.props.config };
    config.currentLanguage = lang;
    config.roles = config.translations[lang].roles;
    config.copyright = config.translations[lang].copyright;
    config.title = config.translations[lang].title;
    config.buttons = config.translations[lang].buttons;
    config.texts = config.translations[lang].texts;
    config.labels = config.translations[lang].labels;
    config.messages = config.translations[lang].messages;
    store.dispatch({
      type: 'CONFIG',
      payload: config
    });
  }

  render() {
    return (
      !this.props.isGame ?
        <div className='card-footer site-footer' id='footer'>

          {this.props.currentUser.admin === true
            ?
            <AdminMenu />
            :
            <div className='content-top text-sm' id='footer-links'>
              {Object.keys(this.props.config.translations).filter(el => el !== this.props.config.currentLanguage).map(el =>
                <span key={el} id={`${el}-lang-toggle`} className='ml-2 mr-2 link-item' onClick={() => this.updateLanguage(el)}>
                  {el}
                </span>
              )}
            </div>
          }

          <div id='copyright-text'>© {this.props.config.copyright}</div>
          <div className='text-small mb-3' id='version-text'>
            <span className='mr-2'>
              <span id='version-label'>{this.props.config.labels.versionText}</span> <span id='version-value'>{version}</span>
            </span>
            <span className='ml-2'>
              <span id='developer-prepend'>{this.props.config.labels.websiteByText} </span>
              <a
                href='https://www.reactsmart.dev/'
                id='developer-link'
                target='_blank'
                rel="noopener noreferrer">
                React Smart Development
              </a>
            </span>
          </div>

        </div>
        :
        null
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(Footer);