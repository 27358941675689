import React, { Component } from 'react';
import Spinner from './additional/Spinner';
import Restricted from './additional/Restricted';
import { connect } from 'react-redux';
import axios from "axios";
import store from "../redux/store";
import server from '../server';

import show from '../images/show.svg';
import hide from '../images/hide.svg';
import male from '../images/male.svg';
import female from '../images/female.svg';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
      nickName: '',
      dob: '',
      rating: 0,
      visits: 0,
      wins: 0,
      loses: 0,
      sex: ''
    };
  }

  componentDidMount() {
    store.dispatch({ type: 'NO_ERROR' });
    store.dispatch({ type: 'PROGRESS' });
    store.dispatch({ type: 'URL', payload: this.props.location.pathname })

    this.getUser();
  }

  getUser() {
    const content = this;
    axios.get(server.address + '/mafia-users/' + this.props.currentUser._id, {
      params: {
        account: true
      }
    })
      .then(function (res) {
        content.setState(res.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  updateUser() {
    this.firstCheck() ?
      store.dispatch({
        type: 'ERROR',
        payload: {
          errorText: this.props.config.messages.fillAllFields,
          success: false
        }
      })
      :
      this.compareUsers()
  }

  compareUsers() {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        let findEmail = res.data.find(el => el.email === this.state.email);
        let findUser = res.data.find(el => el.nickName === this.state.nickName);
        findEmail !== undefined && findEmail._id !== this.state._id ?
          store.dispatch({
            type: 'ERROR',
            payload: {
              errorText: content.props.config.messages.emailTaken
            }
          })
          :
          findUser !== undefined && findUser._id !== this.state._id ?
            store.dispatch({
              type: 'ERROR',
              payload: {
                errorText: content.props.config.messages.nickTaken
              }
            })
            :
            this.updateData()
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  firstCheck() {
    const values = Object.values(this.state).find(el => el.length === 0 || el.toString().length === 0 || el === undefined);
    return values !== undefined;
  }

  updateData() {
    const content = this;
    store.dispatch({ type: 'PROGRESS' });
    axios.patch(server.address + '/mafia-users/' + this.state._id, this.state, {
      params: {
        account: true
      }
    })
      .then(function (res) {
        content.updateList();
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_PROGRESS' });
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: content.props.config.messages.infoSaved,
            success: true
          }
        })
      });
  }


  updateList() {
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        const data = res.data.sort((a, b) => b.rating - a.rating);
        data.forEach((el, i) => el.no = i + 1);
        store.dispatch({
          type: 'LOAD_USERS',
          payload: {
            users: data
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  revertData() {
    store.dispatch({ type: 'NO_ERROR', });
    store.dispatch({ type: 'PROGRESS' });
    this.componentDidMount();
  }

  render() {
    if (this.props.currentUser.nickName === undefined) {
      return <Restricted />
    } else if (this.props.progress === true) {
      return (<Spinner margin={true} />)
    } else {
      return (
        <div className="page-wrap center" id='account-page'>

          <div className="text-center mt-3">

            <div className='mb-3'>
              <span className='ml-2 mr-3'>
                <img src={male}
                  alt='male'
                  id='male-icon'
                  className={this.state.sex === 'male' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                  onClick={() => this.setState({ sex: 'male' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
              </span>
              <span className='ml-2 mr-3'>
                <img src={female}
                  alt='female'
                  id='female-icon'
                  className={this.state.sex === 'female' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                  onClick={() => this.setState({ sex: 'female' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
              </span>
            </div>

            <div className="input-group mb-3" id='text-password'>
              <div className="input-group-prepend">
                <span id='text-password' className='input-group-text'>{this.props.config.labels.accountPassword}
                  <img src={this.props.showPass ? hide : show}
                    height={24} alt='show'
                    id={this.props.showPass ? 'pass-show' : 'pass-hide'}
                    className='ml-3 hover-item'
                    onClick={() => store.dispatch({ type: 'SHOW_PASS', })} />
                </span>
              </div>
              <input type={this.props.showPass ? 'text' : 'password'}
                className='form-control'
                id='pass'
                maxLength='100'
                placeholder='*'
                value={this.state.pass}
                onChange={(e) => this.setState({ pass: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstName">
                  <span id='firstName-label'>{this.props.config.labels.accountFName}</span>
                </label>
                <input value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="firstName"
                  maxLength="100"
                  aria-label="First name"
                  placeholder="*"
                  className="form-control" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastName">
                  <span id="lastName-label">{this.props.config.labels.accountLName}</span>
                </label>
                <input value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="lastName"
                  maxLength="100"
                  aria-label="Last name"
                  placeholder="*"
                  className="form-control" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="email">
                  <span id='email-label'>{this.props.config.labels.accountEmail}</span>
                </label>
                <input value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="email"
                  id="email"
                  maxLength="100"
                  aria-label="Email"
                  placeholder="*"
                  className="form-control" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="nickname">
                  <span id="nickname-label">{this.props.config.labels.accountNickname}</span>
                </label>
                <input value={this.state.nickName}
                  onChange={(e) => this.setState({ nickName: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="text"
                  id="nickname"
                  maxLength="100"
                  aria-label="Nickname"
                  placeholder="*"
                  className="form-control" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="dob">
                  <span id='dob-label'>{this.props.config.labels.accountDOB}</span>
                </label>
                <input value={this.state.dob}
                  onChange={(e) => this.setState({ dob: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                  type="date"
                  id="dob"
                  maxLength="100"
                  aria-label="DOB"
                  className="form-control" />
              </div>
            </div>

            <table width="100%" className='mb-3'>
              <tbody>
                <tr>
                  <td width="25%" id='title-rating'>{this.props.config.labels.accountRating}</td>
                  <td width="25%" id='title-visits'>{this.props.config.labels.accountVisits}</td>
                  <td width="25%" id='title-wins'>{this.props.config.labels.accountWins}</td>
                  <td width="25%" id='title-loses'>{this.props.config.labels.accountLoses}</td>
                </tr>
                <tr className='text-bold'>
                  <td width="25%" id='value-rating'>{this.state.rating.toString()}</td>
                  <td width="25%" id='value-visits'>{this.state.visits.toString()}</td>
                  <td width="25%" id='value-wins'>{this.state.wins.toString()}</td>
                  <td width="25%" id='value-loses'>{this.state.loses.toString()}</td>
                </tr>
              </tbody>
            </table>

            <div className="mb-3">
              <button className="btn btn-sm btn-primary ml-3 mr-3"
                id="update"
                onClick={() => this.updateUser()}>
                {this.props.config.buttons.update}
              </button>
              <button className="btn btn-sm btn-success ml-3 mr-3"
                id="revert"
                onClick={() => this.revertData()}>
                {this.props.config.buttons.revert}
              </button>
            </div>

            {
              this.props.errorState ?
                <div>
                  <span className={this.props.success ? 'green-text' : 'error-text'} id='error-text'>
                    {this.props.errorText}
                  </span>
                </div>
                :
                <div>
                  <span className='error-placeholder' />
                </div>
            }

          </div>

        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  users: state.users,
  config: state.config,
  progress: state.progress,
  currentUser: state.currentUser,
  errorState: state.errorState,
  errorText: state.errorText,
  showPass: state.showPass,
  success: state.success
});

export default connect(mapStateToProps)(Account);