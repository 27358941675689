export default function breakpoint() {
    const width = window.innerWidth;
    if (width >= 1200) {
        return 'XL';
    } else if (width < 1200 && width >= 992) {
        return 'L';
    } else if (width < 992 && width >= 768) {
        return 'M';
    } else if (width < 768 && width >= 576) {
        return 'S';
    } else {
        return 'XS';
    }
}