import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import store from "../../redux/store";
import Spinner from '../additional/Spinner';
import Restricted from '../additional/Restricted';
import axios from 'axios';
import server from '../../server';

//SVG
import cup1 from '../../images/tournament/cup1.svg';
import cup2 from '../../images/tournament/cup2.svg';
import cup3 from '../../images/tournament/cup3.svg';
import cup4 from '../../images/tournament/cup4.svg';
import gun from '../../images/tournament/gun.svg';
import hat from '../../images/tournament/hat.svg';
import star from '../../images/tournament/star.svg';
import city from '../../images/tournament/city.svg';
import mafia from '../../images/tournament/mafia.svg';
import sheriff from '../../images/tournament/sheriff.svg';

const initialState = {
  name: '',
  icon: 'cup1',
  startDate: '',
  endDate: '',
  registrationEnds: '',
  players: [],
  maxPlayers: 0,
  games: [],
  active: true
}

class NewTournament extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    store.dispatch({ type: 'NO_ERROR', });
    store.dispatch({ type: 'NO_PROGRESS' });
  }

  clearAll() {
    this.setState(initialState)
  }

  submit() {
    this.checkFields() ?
      store.dispatch({
        type: 'ERROR',
        payload: {
          errorText: this.props.config.messages.fillAllFields
        }
      })
      :
      this.checkMaxPlayers() ?
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: 'Number of players cannot be less than 10' //TODO: config
          }
        })
        :
        this.checkDates() ?
          store.dispatch({
            type: 'ERROR',
            payload: {
              errorText: 'End Date cannot be earlier than Start Date' //TODO: config
            }
          })
          :
          this.checkRegDates() ?
            store.dispatch({
              type: 'ERROR',
              payload: {
                errorText: 'Registration cannot end later than Start Date' //TODO: config
              }
            })
            :
            this.postTournament();
  }

  checkFields() {
    return this.state.name.trim().length === 0 || this.state.startDate.trim().length === 0
      || this.state.endDate.trim().length === 0 || this.state.registrationEnds.trim().length === 0
  }

  checkMaxPlayers() {
    return this.state.maxPlayers < 10
  }

  checkDates() {
    let startDate = new Date(this.state.startDate);
    let endDate = new Date(this.state.endDate);
    return endDate < startDate;
  }

  checkRegDates() {
    let startDate = new Date(this.state.startDate);
    let registrationEnds = new Date(this.state.registrationEnds);
    return registrationEnds > startDate;
  }

  postTournament() {
    store.dispatch({ type: 'PROGRESS' });
    const content = this;
    axios.post(server.address + '/mafia-tournaments/', this.state)
      .then((res) => {
        store.dispatch({
          type: 'ERROR',
          payload: {
            errorText: 'Tournament created', //TODO: config
            success: true
          }
        })

      })
      .then(() => {
        content.setState(initialState)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        store.dispatch({ type: 'NO_PROGRESS' });
      });
  }

  render() {
    if (this.props.currentUser.admin !== true && this.props.currentUser.moderator !== true) {
      return <Restricted admin={true} />
    }
    return (
      <div id='new-tournament-page' className='page-wrap center'>

        <div className="text-center mt-3 mb-3">

          <div className='mb-3' id='t-icons'>
            <span>
              <img src={cup1}
                alt='cup1'
                className={this.state.icon === 'cup1' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'cup1' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={cup2}
                alt='cup2'
                className={this.state.icon === 'cup2' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'cup2' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={cup3}
                alt='cup3'
                className={this.state.icon === 'cup3' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'cup3' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={cup4}
                alt='cup4'
                className={this.state.icon === 'cup4' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'cup4' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={gun}
                alt='gun'
                className={this.state.icon === 'gun' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'gun' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={hat}
                alt='hat'
                className={this.state.icon === 'hat' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'hat' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={star}
                alt='star'
                className={this.state.icon === 'star' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'star' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={mafia}
                alt='mafia'
                className={this.state.icon === 'mafia' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'mafia' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={sheriff}
                alt='sheriff'
                className={this.state.icon === 'sheriff' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'sheriff' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
            <span>
              <img src={city}
                alt='city'
                className={this.state.icon === 'city' ? 'icon-tm icon-active' : 'icon-tm hover-item'}
                onClick={() => this.setState({ icon: 'city' }, () => store.dispatch({ type: 'NO_ERROR', }))} />
            </span>
          </div>

          <div className="input-group mb-3">
            <input value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
              type="text"
              id="t-name"
              maxLength="100"
              aria-label="Tournament Name"
              placeholder='Tournament Name *' //TODO: config
              className="form-control" />
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="t-start-date">Start Date</label> {/* TODO: config */}
              <input value={this.state.startDate}
                onChange={(e) => this.setState({ startDate: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                type="date"
                id="t-start-date"
                maxLength="20"
                aria-label="Tournament Start Date"
                className="form-control" />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="t-end-date">End Date</label> {/* TODO: config */}
              <input value={this.state.endDate}
                onChange={(e) => this.setState({ endDate: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                type="date"
                id="t-end-date"
                maxLength="20"
                aria-label="Tournament End Date"
                className="form-control" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="t-reg-end-date">Registration Ends</label> {/* TODO: config */}
              <input value={this.state.registrationEnds}
                onChange={(e) => this.setState({ registrationEnds: e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                type="date"
                id="t-reg-end-date"
                maxLength="20"
                aria-label="Tournament Registration End Date"
                className="form-control" />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="t-max-players">Max Players</label> {/* TODO: config */}
              <input value={this.state.maxPlayers}
                onChange={(e) => this.setState({ maxPlayers: +e.target.value }, () => store.dispatch({ type: 'NO_ERROR', }))}
                type="text"
                id="t-max-players"
                maxLength="3"
                aria-label="Tournament Max Players"
                className="form-control" />
            </div>
          </div>

          <div className="mb-3">
            <button className="btn btn-sm btn-primary ml-3 mr-3"
              id="registration"
              onClick={() => this.submit()}>
              {this.props.config.buttons.register}
            </button>
            <button className="btn btn-sm btn-success ml-3 mr-3"
              id="clear"
              onClick={() => this.clearAll()}>
              {this.props.config.buttons.clear}
            </button>
          </div>

          {this.props.progress ?
            <Spinner />
            :
            this.props.errorState ?
              <div>
                <span className={this.props.success ? 'green-text' : 'error-text'} id='error-text'>
                  {this.props.errorText}
                </span>
              </div>
              :
              <div>
                <span className='error-placeholder' />
              </div>
          }

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  config: state.config,
  errorText: state.errorText,
  errorState: state.errorState,
  progress: state.progress,
  success: state.success
});

export default connect(mapStateToProps)(NewTournament);
