import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import store from "../../redux/store";

//helpers
import breakpoint from '../../helpers/breakpoints';

//SVG
import { GiCardAceSpades } from "react-icons/gi";
import { TiChevronLeftOutline, TiThList } from "react-icons/ti";
import { VscAccount } from "react-icons/vsc";
import { AiOutlineUserAdd } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";
// import { TbTournament } from "react-icons/tb";

class Menu extends Component {

  logout() {
    localStorage.removeItem('token');
    store.dispatch({ type: 'LOGOUT' });
  }

  hideLabels() {
    return breakpoint() === 'S' || breakpoint() === 'XS'
  }

  render() {
    return (
      <table width="100%" id='header-menu'>
        <tbody>
          <tr>
            <td width="15%" className='content-left'>
              {this.props.currentUser.email !== undefined ?
                <span id='welcome-section'>
                  <Link to={'/account'}>
                    <button className={`btn btn-sm ${this.props.url === '/account' ? 'btn-primary' : 'btn-outline-primary'} m-1`} id='btn-user'>
                      <VscAccount className="button-icon" />
                      {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.myAccountButton}</span>}
                    </button>
                  </Link>
                </span>
                :
                this.props.url === '/' ?
                  null
                  :
                  <Link to={'/'}>
                    <button className={'btn btn-sm btn-outline-primary'} id='btn-back-to-login'>
                      <TiChevronLeftOutline className="button-icon" />
                      {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.backToLoginButton}</span>}
                    </button>
                  </Link>
              }
            </td>
            <td width="70%" className='pl-1 pr-1'>

              <Link to={'/users'}>
                <button className={`btn btn-sm ${this.props.url === '/users' ? 'btn-info' : 'btn-outline-info'} m-1`} id='btn-all'>
                  <TiThList className="button-icon" />
                  {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.ratingButton}</span>}
                </button>
              </Link>

              {this.props.currentUser.admin === true || this.props.currentUser.moderator === true ?
                <span>

                  <Link to={'/new'}>
                    <button className={`btn btn-sm ${this.props.url === '/new' ? 'btn-info' : 'btn-outline-info'} m-1`} id='btn-new'>
                      <AiOutlineUserAdd className="button-icon" />
                      {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.registerHeaderButton}</span>}
                    </button>
                  </Link>

                  <Link to={'/game'}>
                    <button className={`btn btn-sm ${this.props.url === '/game' ? 'btn-info' : 'btn-outline-info'} m-1`} id='btn-game'>
                      <GiCardAceSpades className="button-icon" />
                      {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.gameButton}</span>}
                    </button>
                  </Link>

                  {/* <Link to={'/tournaments'}>
                    <button className={`btn btn-sm ${this.props.url === '/tournaments' ? 'btn-info' : 'btn-outline-info'} m-1`} id='btn-tournaments'>
                      < TbTournament className="button-icon" />
                      {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.tournamentsButton}</span>}
                    </button>
                  </Link> */}

                </span>
                :
                <Link to={'/public-game'}>
                  <button className={`btn btn-sm ${this.props.url === '/public-game' ? 'btn-info' : 'btn-outline-info'} m-1`} id='btn-game'>
                    <GiCardAceSpades className="button-icon" />
                    {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.gameButton}</span>}
                  </button>
                </Link>
              }

            </td>
            <td width="15%" className='content-right'>
              {this.props.currentUser.email !== undefined ?
                <Link to={'/'}>
                  <button className='btn btn-sm btn-outline-danger m-1'
                    id='btn-logout'
                    onClick={() => this.logout()}>
                    <RiLogoutBoxRLine className="button-icon" />
                    {this.hideLabels() ? null : <span className='header-button-title'>{this.props.config.buttons.logoutButton}</span>}
                  </button>
                </Link>
                :
                null}
            </td>
          </tr>
        </tbody>
      </table >
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  currentUser: state.currentUser,
  location: state.location,
  url: state.url
});

export default connect(mapStateToProps)(Menu);
