import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import axios from 'axios';
import store from "../../redux/store";
import server from '../../server';
import Music from '../game/helpers/Music';
import Timer from '../game/helpers/Timer';

//SVG
import { RiLogoutBoxRLine } from "react-icons/ri";

class GameHeader extends Component {
  constructor(props) {
    super(props);

    this.mobile = this.props.platform === "mobile";

    this.initialState = {
      muted: false
    }

    this.state = this.initialState;
  }

  checkIfMuted() {
    const phase = this.props.currentGame.phase;
    if (phase === '/game/initial'
      || phase === '/game/day'
      || phase === '/game/vote'
      || phase === '/game/end') {
      return true
    } else {
      return false
    }
  }

  newGame() {
    if (this.props.currentGame.type === 'public') {
      axios.delete(server.address + '/mafia-games/' + this.props.currentGame._id)
        .then(() => {
          store.dispatch({
            type: 'NEW_GAME'
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      store.dispatch({
        type: 'NEW_GAME'
      });
    }
  }

  render() {
    return (
      <table width="100%" id='header-menu'>
        <tbody>
          <tr>
            <td width="15%" className='content-left'>
              <Music volume={1} muted={this.checkIfMuted()} />
            </td>
            <td width="70%" className='pl-1 pr-1'>
              <Timer
                timer={60}
                volume={'high'}
                size={'big'}
                removePause={true}
                displayPlayButtons={false}
                displayAddButtons={true}
                displayClock={true}
              />
            </td>
            <td width="15%" className='content-right'>
              <RiLogoutBoxRLine
                alt='Exit Game'
                id='btn-exit'
                onClick={() => this.newGame()}
                className='game-header-icon hover-item'
              />
            </td>
          </tr>
        </tbody>
      </table >
    );
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame
});

export default connect(mapStateToProps)(GameHeader);
