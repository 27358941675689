import React, { Component } from 'react';
import YouTube from '@u-wave/react-youtube';
import '../../../css/custom.css';
import { connect } from 'react-redux';
import { IoVolumeHighSharp, IoVolumeMuteSharp } from "react-icons/io5";

//Music
import positive from "../../../mp3/Positive-Fuse.mp3";
import whereWeWannaGo from "../../../mp3/Where-We-Wanna-Go.mp3";
import tropical from "../../../mp3/Tropic-Fuse.mp3";
const songs = [positive, whereWeWannaGo, tropical];

let musicPlayer;

class Music extends Component {

  constructor(props) {
    super(props);

    this.mobile = this.props.platform === "mobile";
    this.local = this.mobile ? true : this.props.config.audios.localMusic;

    this.initialState = {
      localMusic: this.local,
      paused: this.props.muted,
      volume: this.mobile ? 1 : this.props.config.audios.musicVolume,
      YTid: this.props.config.audios.YTid,
      YTwidth: this.props.config.audios.showYT ? this.props.size === 'med' ? '45' : '67' : '0',
      YTheight: this.props.config.audios.showYT ? this.props.size === 'med' ? '26' : '38' : '0',
      buttonHeight: this.props.size === 'med' ? '26px' : '31px'
    }

    this.state = this.initialState;
  }

  componentDidMount() {
    if (this.local) {
      musicPlayer = document.getElementById('music-player');
      musicPlayer.volume = this.state.volume;
    }
    if (this.state.paused) {
      this.pause()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.muted !== this.props.muted && this.props.muted !== this.state.paused) {
      this.setState({ paused: this.props.muted }, () => {
        if (this.state.paused) {
          this.pause()
        } else {
          this.play()
        }
      })
    }
  }

  localMusicList() {
    return songs[Math.floor(Math.random() * songs.length)]
  }

  YTready() {
    //do something once YT player is ready
  }

  play() {
    this.setState({ paused: false, volume: 1 })
    if (this.state.localMusic) {
      musicPlayer.play();
    }
  }

  pause() {
    this.setState({ paused: true, volume: 0 });
    if (this.state.localMusic) {
      musicPlayer.pause();
    }
  }

  render() {
    return (

      <span className={this.props.size === 'med' ? 'mr-2' : 'ml-3 mr-3'}>
        {
          this.state.paused ?
            <span>
              <IoVolumeMuteSharp
                onClick={() => this.play()}
                id='music-btn-muted'
                alt='unmute'
                height={this.state.buttonHeight}
                className='hover-item music-align game-header-icon'
              />
            </span>
            :
            <span>
              <IoVolumeHighSharp
                onClick={() => this.pause()}
                id='music-btn-playing'
                alt='mute'
                height={this.state.buttonHeight}
                className='hover-item music-align game-header-icon'
              />
            </span>
        }
        {
          this.state.localMusic ?
            <audio autoPlay loop id='music-player'>
              <source src={this.localMusicList()} type="audio/mpeg" />
              If you see this message your browser does not support the audio element.
            </audio>
            :
            <YouTube
              autoplay
              video={this.state.YTid}
              width={this.state.YTwidth}
              height={this.state.YTheight}
              paused={this.state.paused}
              volume={this.state.volume}
              controls={false}
              allowFullscreen={false}
              annotations={false}
              showRelatedVideos={false}
              showInfo={false}
              onReady={() => this.YTready()}
              className={`youtube-player ${this.props.config.audios.showYT && 'ml-2'}`}
            />
        }

      </span >

    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  platform: state.platform,
  currentGame: state.currentGame
});

export default connect(mapStateToProps)(Music);