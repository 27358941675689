import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Spinner from './additional/Spinner';
import Nothing from './additional/Nothing';
import server from '../server';
import {
  noProgress,
  noError,
  error,
  inProgress,
  saveUrl
} from '../actions';

//SVG
import plus from '../images/plus.svg';

class Tournaments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      futureAndActive: [],
      past: [],
      list: []
    };
  }

  componentDidMount() {
    this.props.saveUrl(this.props.location.pathname);
    this.props.inProgress();
    const content = this;
    axios.get(server.address + '/mafia-tournaments/')
      .then(res => {
        const active = res.data.filter(el => el.active === true).sort((a, b) => b.startDate - a.startDate);
        const past = res.data.filter(el => el.active === false).sort((a, b) => b.startDate - a.startDate);
        content.setState({ futureAndActive: active, list: active });
        content.setState({ past: past })
      })
      .catch(error => console.log(error))
      .finally(function () {
        content.props.noProgress();
      });
  }

  getIcon(image) {
    const icon = require(`../images/tournament/${image}.svg`);
    return icon;
  }

  render() {
    return this.props.progress ?
      <Spinner margin={true} />
      : (
        <div id='tournaments-page' className='page-wrap'>

          <div className='mt-2 mb-2'>
            <span className="text-center">
              <input type="radio"
                name="game"
                value="rating"
                checked={this.state.active === true}
                onChange={() => this.setState({ active: true, list: this.state.futureAndActive })} />
              <span className='ml-2 mr-3'>Future or Active</span> {/* TODO: in config */}

              <input type="radio"
                name="game"
                value="fun"
                checked={this.state.active === false}
                onChange={() => this.setState({ active: false, list: this.state.past })} />
              <span className='ml-2 mr-3'>Past</span> {/* TODO: config */}

            </span>
            {this.props.currentUser.admin === true || this.props.currentUser.moderator === true ?
              <span className='ml-3'>
                <Link to={'/tournaments/new'}>
                  <img src={plus} alt='new' className='icon-sm mr-1' />
                New {/* TODO: config */}
                </Link>
              </span>
              :
              null
            }
          </div>
          <div className='container'>
            {this.state.list.length === 0
              ?
              <Nothing type='tournament' />
              :
              <div className='row justify-content-md-center'>
                {this.state.list.map(el =>
                  <div className="card col-xl-5 m-1" key={el._id}>
                    <div className='card-body d-flex justify-content-between align-items-center pl-0 pr-0'>
                      <img src={this.getIcon(el.icon)} alt={`${el.name} icon`} className='icon-lg mr-2' />
                      <Link to={`/tournaments/${el._id}`} title={`${el.name}`}>
                        {el.name}
                      </Link>
                      <span className="badge badge-primary badge-pill">{el.players.length}/{el.maxPlayers}</span>
                    </div>
                  </div>)}
              </div>
            }
          </div>

        </div >
      );
  }
}

const mapStateToProps = state => ({
  errorState: state.errorState,
  errorText: state.errorText,
  progress: state.progress,
  config: state.config,
  currentUser: state.currentUser
});

const mapDispatchToProps = dispatch => ({
  noProgress: () => dispatch(noProgress()),
  inProgress: () => dispatch(inProgress()),
  error: args => dispatch(error(args)),
  noError: () => dispatch(noError()),
  saveUrl: args => dispatch(saveUrl(args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tournaments);