import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { saveUrl, updateGame, inProgress, noProgress } from "../actions"
import Spinner from "./additional/Spinner";
import server from '../server';
import trimString from '../helpers/trim-string';
import howMuchTrim from '../helpers/trim-amount';
import addMargin from '../helpers/add-margin';
import { CgRemove } from "react-icons/cg";
import { FaMale, FaFemale } from "react-icons/fa";

class PublicGame extends Component {
  constructor(props) {
    super(props);
    this.foundGame = {};
    this.state = {
      players: [],
      nickName: '',
      gender: 'male',
      search: '',
      rating: false,
      random: false,
      state: 'game',
      startGame: false,
      createGame: false,
      _id: '',
      foundGame: {}
    };
  }

  componentDidMount() {
    this.props.saveUrl(this.props.location.pathname);
  }

  getTime() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    return `${year}/${month + 1}/${day}_${hours}:${minutes < 10 ? 0 + minutes.toString() : minutes}`;
  }

  startGame() {
    const gamers = this.state.players;
    if (this.state.random === true) {
      gamers.forEach(el => el.randomNo = this.randomNum());
      gamers.sort((a, b) => a.randomNo - b.randomNo);
    }
    gamers.forEach((el, i) => el.no = i + 1);
    const game = {
      type: 'public',
      startDate: this.getTime(),
      rating: this.state.rating,
      endDate: '',
      players: gamers,
      roles: {
        Mafia: this.findQuantity('Mafia'),
        Don: this.findQuantity('Don'),
        Sheriff: this.findQuantity('Sheriff'),
        Citizen: this.findQuantity('Citizen')
      },
      whoWon: 0,
      phase: '/game/initial',
      cycle: 0,
      killedLastNight: false,
      deadPlayers: [],
      disqualified: [],
      firstWord: 1,
      vote: [],
      voting: [],
      comments: [],
      activePlayer: 0,
      talked: [],
      currentComment: ''
    };
    this.postData(game);
  }

  findQuantity(role) {
    let quantity = this.props.config.roles.find(el => el.role === role).quantity;
    return quantity;
  }

  postData(game) {
    this.props.inProgress();
    const content = this;
    axios.post(server.address + '/mafia-games/', game)
      .then((res) => {
        content.props.updateGame(res.data.game);
        content.setState({ createGame: true, players: res.data.game.players, id: res.data.game._id });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.noProgress();
      });
  }

  randomNum() {
    return parseInt(Math.random() * 1000000)
  }

  now() {
    let date = new Date();
    return date.getTime();
  }

  addPlayerToGame() {
    const game = this.state.players;
    const gamer = {
      _id: this.randomNum(),
      firstName: "Public",
      lastName: "Player",
      nickName: this.state.nickName,
      sex: this.state.gender,
    };
    gamer.role = '';
    gamer.alive = true;
    gamer.fouls = '';
    gamer.muted = false;
    game.push(gamer);
    this.setState({ players: game, nickName: '', gender: 'male' });
  }

  removePlayer(player) {
    const game = this.state.players.filter(el => el._id !== player._id);
    this.setState({ players: game });
  }

  pressEnter(enter, pressCase) {
    if (enter.key === 'Enter' && pressCase === 'search' && this.state._id.trim().length !== 0) {
      this.searchGame();
    } else if (enter.key === 'Enter' && this.state.nickName.trim().length !== 0 && this.state.players.length < 10) {
      this.addPlayerToGame();
    }
  }

  searchGame() {
    this.props.inProgress();
    const content = this;
    axios.get(server.address + '/mafia-games/' + this.state._id)
      .then((res) => {
        if (res.data !== null) {
          content.setState({ foundGame: res.data });
        } else {
          content.setState({ foundGame: { error: 'error' } });
        }
      })
      .catch((error) => {
        console.log(error);
        content.setState({ foundGame: { error: 'error' } });
      })
      .finally(() => {
        this.props.noProgress();
      });
  }

  render() {
    if (this.props.currentUser.nickName !== undefined && (this.props.currentUser.moderator || this.props.currentUser.admin)) {
      return <Redirect to='/game' />
    } else {
      return this.props.progress
        ?
        <Spinner margin={true} />
        :
        this.state.startGame === true
          ?
          (<Redirect to='/game/initial' />)
          :
          (<div id='public-game' className="page-wrap">

            <div className='mt-3 mb-3'>

              <ul className="nav nav-tabs mb-2">
                <li className="nav-item" id="new-game-tab">
                  <span
                    className={`nav-link hover-item ${this.state.state === 'game' ? 'active' : null}`}
                    onClick={() => this.setState({ state: 'game' })}>
                    {this.props.config.buttons.newGame}
                  </span>
                </li>
                <li className="nav-item" id="search-tab">
                  <span
                    className={`nav-link hover-item ${this.state.state === 'search' ? 'active' : null}`}
                    onClick={() => this.setState({ state: 'search' })}>
                    {this.props.config.buttons.gameSearch}
                  </span>
                </li>
              </ul>

              {this.state.state === 'game'
                ?
                <div id='new-game-section'>

                  {
                    this.state.createGame ?
                      <div id="created-game-info">
                        <div className='mt-2 mb-2'>
                          <div className="row">
                            <div className='col-lg-6'>
                              <div className="card m-1">
                                <div className='card-body p-1'>
                                  <div>
                                    <span id='id-label'>{this.props.config.labels.uniqueID}: </span>
                                    <span id='id-value'>{this.props.currentGame._id}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className="card m-1">
                                <div className='card-body p-1'>
                                  <span className='' id='save-id-text'>{this.props.config.labels.saveID}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div id="game-config">

                        <div className='mt-2 mb-2'>
                          <div className="row">
                            <div className='col-lg-9'>
                              <div className="input-group mb-3">
                                <span className='mr-2'>
                                  <FaMale
                                    alt='male'
                                    id='male-icon'
                                    className={`icon-sex-sm ${this.state.gender === 'male' ? 'icon-active' : 'hover-item'}`}
                                    onClick={() => this.setState({ gender: 'male' })}
                                  />
                                </span>
                                <span className='mr-2'>
                                  <FaFemale
                                    alt='female'
                                    id='female-icon'
                                    className={`icon-sex-sm ${this.state.gender === 'female' ? 'icon-active' : 'hover-item'}`}
                                    onClick={() => this.setState({ gender: 'female' })}
                                  />
                                </span>

                                <input
                                  className="form-control form-control-sm"
                                  value={this.state.nickName}
                                  placeholder={this.props.config.labels.nicknameName}
                                  id='add_player_field'
                                  onKeyDown={(e) => this.pressEnter(e)}
                                  maxLength='45'
                                  onChange={(e) =>
                                    this.setState({
                                      nickName: e.target.value
                                    })}
                                />
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-sm btn-primary align-right"
                                    id='add_player_button'
                                    disabled={this.state.nickName.trim().length === 0 || this.state.players.length >= 10}
                                    onClick={() => this.addPlayerToGame()}>
                                    {this.props.config.buttons.addComment}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-3'>
                              <span className="content-middle">
                                <input
                                  type="checkbox"
                                  name="random-seating"
                                  id="random-seating"
                                  className='mr-2 hover-item'
                                  checked={this.state.random}
                                  onChange={() => this.setState({ random: !this.state.random })} />
                                <span id='random-seating-label'>Random seating numbers</span> {/* TODO: config */}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                  }

                  <table id='players-table' className={addMargin(this.state.players.length === 0 ? 1 : this.state.players.length)}>
                    <thead id='players-table-head' className='thead-light'>
                      <tr className='players-table-row'>
                        <th id='players-table-game-label' colSpan={3}>{this.props.config.labels.game}</th>
                      </tr>
                    </thead>
                    <tbody id='players-table-body'>
                      {
                        this.state.players.length === 0 ?
                          <tr>
                            <td id='players-table-empty' className='players-table-row' colSpan={2}>{this.props.config.labels.publicGameList}</td>
                          </tr>
                          :
                          this.state.players.map((el, i) =>
                            <tr key={el._id} className='players-row players-table-row' id={`players-table-row-${i + 1}`}>
                              {
                                this.state.createGame ?
                                  <td width="5%" id={`player-number-${el.no}`}>{el.no}</td>
                                  :
                                  <td width="5%" id={`player-number-${i + 1}`}>{i + 1}</td>
                              }
                              <td width="90%" id={`player-nickname-${i + 1}`}>{trimString(el.nickName, howMuchTrim('public-game'))}</td>
                              {
                                this.state.createGame ?
                                  <td width="5%"></td>
                                  :
                                  <td width="5" id={`player-delete-${i + 1}`}>
                                    <CgRemove
                                      alt='del'
                                      id={`del-${i + 1}`}
                                      className='hover-item button-icon'
                                      onClick={() => this.removePlayer(el)}
                                    />
                                  </td>
                              }
                            </tr>
                          )
                      }
                    </tbody>
                  </table>

                  {
                    this.state.createGame
                      ?
                      <button className={`btn btn-sm btn-warning`}
                        id="start-game-button"
                        onClick={() => this.setState({ startGame: true })}>
                        {this.props.config.buttons.start}
                      </button>
                      :
                      <button className={`${this.state.players.length !== 10 ? 'custom-tooltip' : null} btn btn-sm btn-warning button-with-tooltip`}
                        id="create-game-button"
                        disabled={this.state.players.length !== 10}
                        onClick={() => this.startGame()}>
                        {this.props.config.buttons.create}
                        {this.state.players.length !== 10 ? <span id='not-enough-players-tooltip' className="custom-tooltiptext">{this.props.config.labels.notEnough}</span> : null}
                      </button>
                  }
                </div>
                :
                this.state.state === 'search'
                  ?
                  <div id='search-tab'>
                    <div className="input-group mb-2">
                      <input
                        className="form-control form-control-sm"
                        value={this.state._id}
                        placeholder={this.props.config.labels.uniqueID}
                        id='search_game_field'
                        onKeyDown={(e) => this.pressEnter(e, 'search')}
                        maxLength='24'
                        onChange={(e) =>
                          this.setState({
                            _id: e.target.value
                          })}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-sm btn-primary align-right"
                          id='search_game_button'
                          disabled={this.state._id.trim().length === 0}
                          onClick={() => this.searchGame()}>
                          {this.props.config.buttons.search}
                        </button>
                      </div>
                    </div>

                    {this.state.foundGame._id !== undefined
                      ?
                      <div>
                        <div className='card' id='search-result'>
                          <div className='card-body p-2'>
                            <table className='table table-sm mb-0 mt-2' id='search-result-table'>
                              <thead className='thead-light' id='search-result-table-head'>
                                <tr>
                                  <th id='result-id-title'>{this.props.config.labels.uniqueID}</th>
                                  <th id='result-phase-title'>{this.props.config.labels.adminGamesPhase}</th>
                                  <th id='result-date-title'>{this.props.config.labels.adminGamesDate}</th>
                                </tr>
                              </thead>
                              <tbody id='search-result-table-body'>
                                <tr>
                                  <td id='result-id'>{this.state.foundGame._id}</td>
                                  <td id='result-phase'>{`${this.state.foundGame.phase.slice(6)} ${this.state.foundGame.cycle}`}</td>
                                  <td id='result-date'>{this.state.foundGame.startDate.split('_')[0]}</td>
                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>

                        <Link to={this.state.foundGame.phase}>
                          <button
                            className="btn btn-sm btn-primary mt-2"
                            id='continue-game-button'
                            onClick={() => this.props.updateGame(this.state.foundGame)}>
                            {this.props.config.buttons.continue}
                          </button>
                        </Link>

                      </div>
                      :
                      this.state.foundGame.error === 'error'
                        ?
                        <div id='no-game-message'>{this.props.config.messages.noGame}</div>
                        :
                        null
                    }

                  </div>
                  :
                  null
              }

            </div>
          </div >
          );
    }
  }
}

const mapStateToProps = state => ({
  config: state.config,
  progress: state.progress,
  currentUser: state.currentUser,
  currentGame: state.currentGame
});

const mapDispatchToProps = dispatch => ({
  updateGame: args => dispatch(updateGame(args)),
  saveUrl: args => dispatch(saveUrl(args)),
  inProgress: () => dispatch(inProgress()),
  noProgress: () => dispatch(noProgress()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicGame);