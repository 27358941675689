import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import Spinner from './additional/Spinner';
import Logo from './logo/Logo';
import server from '../server';
import {
  saveUrl,
  noProgress,
  noError,
  error,
  inProgress,
  login
} from '../actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: ''
    };
  }

  componentDidMount() {
    this.props.noError();
    this.props.noProgress();
    this.props.saveUrl(this.props.location.pathname);
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.firstCheck()
    }
  }

  firstCheck() {
    this.state.email.trim().length === 0 || this.state.pass.trim().length === 0 ?
      this.props.error(this.props.config.messages.emptyEmailPass)
      :
      this.loginValidation();
  }

  loginValidation() {
    const content = this;
    this.props.inProgress();
    axios.get(server.address + '/mafia-users/')
      .then(res => {
        let find = res.data.find(el => el.email === this.state.email);
        find !== undefined

          ?

          axios.get(server.address + '/mafia-users/' + find._id, {
            params: {
              email: this.state.email,
              pass: this.state.pass
            }
          })
            .then(res => {
              if (res.data.message === 'authorized') {
                localStorage.setItem('token', res.data.token);
                this.setState({
                  email: '',
                  pass: ''
                });
                this.props.login(res.data.user);
              }
            })
            .catch(error => {
              console.log(error);
              this.props.error(content.props.config.messages.incorrectPass);
            })
            .finally(() => this.props.noProgress())

          :

          this.props.error(content.props.config.messages.doesNotExist);
      })
      .catch(error => console.log(error))
      .finally(() => this.props.noProgress());
  }

  render() {
    if (this.props.currentUser.email !== undefined) {
      return <Redirect to='/users' />
    }

    return (
      <div id='login-page' className='page-wrap center'>

        <div className='login-form' id='login-form'>

          <Logo />

          <div className="input-group mb-3 mt-3">
            <input type="email"
              className="form-control"
              id="username"
              maxLength="100"
              placeholder={this.props.config.labels.loginEmail}
              aria-describedby="emailHelp"
              value={this.state.email}
              onKeyDown={(e) => this.pressEnter(e)}
              onChange={(input) => this.setState({ email: input.target.value }, () => this.props.noError())}
            />
          </div>

          <div className="input-group mb-3">
            <input type="password"
              className="form-control"
              id="pass"
              maxLength="100"
              placeholder={this.props.config.labels.loginPassword}
              value={this.state.pass}
              onKeyDown={(e) => this.pressEnter(e)}
              onChange={(input) => this.setState({ pass: input.target.value }, () => this.props.noError())}
            />
          </div>

          <div>
            <button className="btn btn-sm btn-primary mb-2"
              id="btn-login"
              onClick={() => this.firstCheck()}>{this.props.config.buttons.login}
            </button>
          </div>
          <Link to={'/forgot'}>
            <button className="btn btn-sm btn-link mb-2"
              id="btn-forgot" >{this.props.config.buttons.remind}
            </button>
          </Link>


          {this.props.progress ?
            <Spinner />
            :
            this.props.errorState ?
              <div>
                <span className='error-text' id='error-text'>{this.props.errorText}</span>
              </div>
              :
              <div>
                <span className='error-placeholder' id='error-placeholder' />
              </div>
          }

        </div>

      </div >
    );
  }
}

const mapStateToProps = state => ({
  errorState: state.errorState,
  errorText: state.errorText,
  currentUser: state.currentUser,
  loading: state.loading,
  progress: state.progress,
  config: state.config
});

const mapDispatchToProps = dispatch => ({
  saveUrl: args => dispatch(saveUrl(args)),
  noProgress: () => dispatch(noProgress()),
  inProgress: () => dispatch(inProgress()),
  login: args => dispatch(login(args)),
  error: args => dispatch(error(args)),
  noError: () => dispatch(noError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);