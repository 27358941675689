import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../images/logo.svg';

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {

    return (
      <div>
        <img src={logo} alt='Logo' className='login-logo' id='logo' />
      </div >
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Logo);