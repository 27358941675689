import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from "../../../redux/store";

class ConfigTexts extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
  }

  updateTexts(value, text) {
    const texts = this.state.texts;
    texts[text] = value;
    this.setState({ texts: texts },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  render() {
    return (
      <div className="text-center">
        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Game starts:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="openGameTxt">Game opening</label>
              <textarea value={this.state.texts.openGameTxt}
                onChange={(e) => this.updateTexts(e.target.value, 'openGameTxt')}
                type="text"
                id="openGameTxt"
                aria-label="Text for opening game"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="greetings">Greetings</label>
              <textarea value={this.state.texts.greetings}
                onChange={(e) => this.updateTexts(e.target.value, 'greetings')}
                type="text"
                id="greetings"
                aria-label="Greetings"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Zero Night:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="beginNightTxt">Night starts</label>
              <textarea value={this.state.texts.beginNightTxt}
                onChange={(e) => this.updateTexts(e.target.value, 'beginNightTxt')}
                type="text"
                id="beginNightTxt"
                aria-label="Text for opening game"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="roleTxt">Choosing roles</label>
              <textarea value={this.state.texts.roleTxt}
                onChange={(e) => this.updateTexts(e.target.value, 'roleTxt')}
                type="text"
                id="roleTxt"
                aria-label="Text for choosing role"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="mafiaTxt0">Mafia wakes up</label>
              <textarea value={this.state.texts.mafiaTxt0}
                onChange={(e) => this.updateTexts(e.target.value, 'mafiaTxt0')}
                type="text"
                id="mafiaTxt0"
                aria-label="Mafia wakes up"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="sheriffTxt0">Sheriff wakes up</label>
              <textarea value={this.state.texts.sheriffTxt0}
                onChange={(e) => this.updateTexts(e.target.value, 'sheriffTxt0')}
                type="text"
                id="sheriffTxt0"
                aria-label="Sheriff at first night"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Day:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="morning1">First morning</label>
              <textarea value={this.state.texts.morning1}
                onChange={(e) => this.updateTexts(e.target.value, 'morning1')}
                type="text"
                id="morning1"
                aria-label="Mafia wakes up"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="openTable">Table opening</label>
              <textarea value={this.state.texts.openTable}
                onChange={(e) => this.updateTexts(e.target.value, 'openTable')}
                type="text"
                id="openTable"
                aria-label="Table opening"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="goodMorning">Mafia missed</label>
              <textarea value={this.state.texts.goodMorning}
                onChange={(e) => this.updateTexts(e.target.value, 'goodMorning')}
                type="text"
                id="goodMorning"
                aria-label="Mafia missed"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="badMorning">Killed at night</label>
              <textarea value={this.state.texts.badMorning}
                onChange={(e) => this.updateTexts(e.target.value, 'badMorning')}
                type="text"
                id="badMorning"
                aria-label="Killed at night"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="applause">Applause</label>
              <textarea value={this.state.texts.applause}
                onChange={(e) => this.updateTexts(e.target.value, 'applause')}
                type="text"
                id="applause"
                aria-label="Applause"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="goodMorningCity">Good Morning</label>
              <textarea value={this.state.texts.goodMorningCity}
                onChange={(e) => this.updateTexts(e.target.value, 'goodMorningCity')}
                type="text"
                id="goodMorningCity"
                aria-label="Good Morning"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Voting:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="noNominations">Nobody nominated</label>
              <textarea value={this.state.texts.noNominations}
                onChange={(e) => this.updateTexts(e.target.value, 'noNominations')}
                type="text"
                id="noNominations"
                aria-label="Nobody nominated"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="singleNominee0">Single Nominee Zero</label>
              <textarea value={this.state.texts.singleNominee0}
                onChange={(e) => this.updateTexts(e.target.value, 'singleNominee0')}
                type="text"
                id="singleNominee0"
                aria-label="Single Nominee"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="singleNomineeN">Single Nominee Later</label>
              <textarea value={this.state.texts.singleNomineeN}
                onChange={(e) => this.updateTexts(e.target.value, 'singleNomineeN')}
                type="text"
                id="singleNomineeN"
                aria-label="Single Nominee Later"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="playersVoted">Players Voted</label>
              <textarea value={this.state.texts.playersVoted}
                onChange={(e) => this.updateTexts(e.target.value, 'playersVoted')}
                type="text"
                id="playersVoted"
                aria-label="Players Voted"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="votingOrder">Voting order</label>
              <textarea value={this.state.texts.votingOrder}
                onChange={(e) => this.updateTexts(e.target.value, 'votingOrder')}
                type="text"
                id="votingOrder"
                aria-label="Voting order"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="votingFouls">Voting fouls</label>
              <textarea value={this.state.texts.votingFouls}
                onChange={(e) => this.updateTexts(e.target.value, 'votingFouls')}
                type="text"
                id="votingFouls"
                aria-label="Voting fouls"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="supportOneLeave">Support one leave?</label>
              <textarea value={this.state.texts.supportOneLeave}
                onChange={(e) => this.updateTexts(e.target.value, 'supportOneLeave')}
                type="text"
                id="supportOneLeave"
                aria-label="Support one leave?"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="shootout">Shotout</label>
              <textarea value={this.state.texts.shootout}
                onChange={(e) => this.updateTexts(e.target.value, 'shootout')}
                type="text"
                id="shootout"
                aria-label="Shotout between players"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="shortTimeAll">30 sec for all</label>
              <textarea value={this.state.texts.shortTimeAll}
                onChange={(e) => this.updateTexts(e.target.value, 'shortTimeAll')}
                type="text"
                id="shortTimeAll"
                aria-label="30 sec for all"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="because">Shotout again</label>
              <textarea value={this.state.texts.because}
                onChange={(e) => this.updateTexts(e.target.value, 'because')}
                type="text"
                id="because"
                aria-label="Because draw"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="vootingForAllLeave">Voting for all leave</label>
              <textarea value={this.state.texts.vootingForAllLeave}
                onChange={(e) => this.updateTexts(e.target.value, 'vootingForAllLeave')}
                type="text"
                id="vootingForAllLeave"
                aria-label="Voting for all leave"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="supportAllLeave">Support all leave?</label>
              <textarea value={this.state.texts.supportAllLeave}
                onChange={(e) => this.updateTexts(e.target.value, 'supportAllLeave')}
                type="text"
                id="supportAllLeave"
                aria-label="Support all leave?"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="supportAllStay">Support all stay?</label>
              <textarea value={this.state.texts.supportAllStay}
                onChange={(e) => this.updateTexts(e.target.value, 'supportAllStay')}
                type="text"
                id="supportAllStay"
                aria-label="Support all stay?"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Voting results:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="allStay">All stay</label>
              <textarea value={this.state.texts.allStay}
                onChange={(e) => this.updateTexts(e.target.value, 'allStay')}
                type="text"
                id="allStay"
                aria-label="All stay"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="oneLeaves">One leaves</label>
              <textarea value={this.state.texts.oneLeaves}
                onChange={(e) => this.updateTexts(e.target.value, 'oneLeaves')}
                type="text"
                id="oneLeaves"
                aria-label="Single Nominee Later"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="allLeave">All leave</label>
              <textarea value={this.state.texts.allLeave}
                onChange={(e) => this.updateTexts(e.target.value, 'allLeave')}
                type="text"
                id="allLeave"
                aria-label="All leave"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="applauseToAll">Applause to all</label>
              <textarea value={this.state.texts.applauseToAll}
                onChange={(e) => this.updateTexts(e.target.value, 'applauseToAll')}
                type="text"
                id="applauseToAll"
                aria-label="All stay"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="votingCancelled">Voting Cancelled</label>
              <textarea value={this.state.texts.votingCancelled}
                onChange={(e) => this.updateTexts(e.target.value, 'votingCancelled')}
                type="text"
                id="votingCancelled"
                aria-label="Voting Cancelled"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="leavesImmediately">Leaves Immediately</label>
              <textarea value={this.state.texts.leavesImmediately}
                onChange={(e) => this.updateTexts(e.target.value, 'leavesImmediately')}
                type="text"
                id="leavesImmediately"
                aria-label="Leaves Immediately"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Night:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="mafiaTxt">Mafia acts at night</label>
              <textarea value={this.state.texts.mafiaTxt}
                onChange={(e) => this.updateTexts(e.target.value, 'mafiaTxt')}
                type="text"
                id="mafiaTxt"
                aria-label="Mafia acts at night"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="donTxt">Don wakes up</label>
              <textarea value={this.state.texts.donTxt}
                onChange={(e) => this.updateTexts(e.target.value, 'donTxt')}
                type="text"
                id="donTxt"
                aria-label="Don wakes up"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="sheriffTxt">Sheriff wakes up</label>
              <textarea value={this.state.texts.sheriffTxt}
                onChange={(e) => this.updateTexts(e.target.value, 'sheriffTxt')}
                type="text"
                id="sheriffTxt"
                aria-label="Sheriff wakes up"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="bestMove">Best Move</label>
              <textarea value={this.state.texts.bestMove}
                onChange={(e) => this.updateTexts(e.target.value, 'bestMove')}
                type="text"
                id="bestMove"
                aria-label="Best Move"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>End:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="mafiaWins">Mafia wins</label>
              <textarea value={this.state.texts.mafiaWins}
                onChange={(e) => this.updateTexts(e.target.value, 'mafiaWins')}
                type="text"
                id="mafiaWins"
                aria-label="Mafia wins"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="cityWins">City wins</label>
              <textarea value={this.state.texts.cityWins}
                onChange={(e) => this.updateTexts(e.target.value, 'cityWins')}
                type="text"
                id="cityWins"
                aria-label="City wins"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="nobodyWins">Nobody wins</label>
              <textarea value={this.state.texts.nobodyWins}
                onChange={(e) => this.updateTexts(e.target.value, 'nobodyWins')}
                type="text"
                id="nobodyWins"
                aria-label="Nobody wins"
                placeholder="*"
                rows={3}
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
});

export default connect(mapStateToProps)(ConfigTexts);