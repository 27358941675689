import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from "react-router-dom";
import store from "../../redux/store";
import axios from "axios";
import server from '../../server';
import TimerButtons from './helpers/TimerButtons';
import votingRender from '../../helpers/voting-render';
import foulsRender from '../../helpers/fouls-render';

import { BsExclamationOctagon } from "react-icons/bs";
import { FaMedkit, FaUsersSlash, FaUsers } from "react-icons/fa";
import { VscChecklist } from "react-icons/vsc";

class Vote extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentGame.startDate !== undefined ?
      {
        ...this.props.currentGame,
        shooting: false,
        shootingAgain: false,
        voted: 0,
        voting: this.prepareVoting(),
        whoKilled: undefined
      }
      :
      {}
  }

  componentDidMount() {
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
    if (this.props.currentGame.startDate !== undefined) {
      if (this.props.currentGame.vote.length === 1 && this.props.currentGame.cycle > 1) {
        this.killPlayer(this.props.currentGame.vote[0])
      }
    }
  }

  prepareVoting(final) {
    const array = [];
    if (final === 'all') {
      array.push({
        no: 'all',
        votes: []
      })
      array.push({
        no: 'none',
        votes: []
      })
    } else {
      this.props.currentGame.vote.forEach(el => {
        array.push({
          no: el,
          votes: []
        })
      });
    }
    return array;
  }

  vote(votedPlayer, votingPlayer, action) {
    const votingArray = this.state.voting;
    const votesArray = votingArray.find(el => el.no === votedPlayer).votes;
    if (action === '+') {
      votesArray.push(votingPlayer);
    } else if (action === '-') {
      const index = votesArray.indexOf(votingPlayer);
      votesArray.splice(index, 1);
    }
    this.setState({ voting: votingArray })
  }

  checkAll(votedPlayer) {
    const whoVoted = [];
    const votingArray = this.state.voting;
    votingArray.forEach(el => whoVoted.push(...el.votes));
    const whoDidNotVote = this.state.players.filter(el => el.alive).map(el => el.no).filter(el => !whoVoted.includes(el));
    const voteForArray = votingArray.find(el => el.no === votedPlayer).votes;
    if (whoDidNotVote.length >= 1) {
      voteForArray.push(...whoDidNotVote);
    } else {
      voteForArray.length = 0;
    }
    this.setState({ voting: votingArray });
  }

  playerVoted(votedPlayer, votingPlayer) {
    return this.state.voting.find(obj => obj.no === votedPlayer).votes.includes(votingPlayer)
  }

  disableVoted(votedPlayer, votingPlayer) {
    const otherPlayerVotes = this.state.voting.filter(obj => obj.no !== votedPlayer)
    let voted = false;
    otherPlayerVotes.forEach(obj => {
      if (obj.votes.includes(votingPlayer)) {
        voted = true;
      }
    })
    return voted;
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    });
    store.dispatch({
      type: 'NO_END_GAME'
    });
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
  }

  back(game) {
    this.updateGame(game.prevState);
    this.props.history.goBack();
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + this.state._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  updatePlayer(id, event) {
    const gamers = this.state.players;
    if (event === '+') {
      gamers.forEach(el => {
        if (el._id === id) {
          el.fouls = el.fouls.concat('•');
          if (el.fouls === '•••') el.mutedCycle = this.state.cycle + 1;
          this.setState({ players: gamers }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
          if (el.fouls === '••••') {
            this.banPlayer(el.no);
          }
        }
      })
    } else if (event === '-') {
      gamers.forEach(el => {
        if (el._id === id) {
          if (el.fouls === '•••') delete el.mutedCycle;
          el.fouls = el.fouls.slice(1);
          this.setState({ players: gamers }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
        }
      });
    } else if (event === 'life') {
      gamers.forEach(el => {
        if (el._id === id) {
          el.fouls = el.fouls.slice(1);
          this.setState({ players: gamers }, () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
          this.allivePlayer(el.no);
        }
      })
    } else {
      this.setState({ players: gamers }, () => this.updateGame(this.state));
      gamers.filter(el => el.alive).forEach(el => el.fouls.length >= 4 ? this.banPlayer(el.no) : null);
    }
  }

  allivePlayer(no) {
    const gamers = this.state.players;
    gamers.forEach(el => el.no === no ? el.alive = true : null);
    const dead = this.state.deadPlayers.filter(el => el !== no);
    const banned = this.state.disqualified.filter(el => el !== no);
    this.setState({
      players: gamers,
      deadPlayers: dead,
      disqualified: banned
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (!(civilians.length <= mafia.length || mafia.length === 0)) {
      store.dispatch({
        type: 'NO_END_GAME'
      })
    }
  }

  currentVote(no) {
    if (Array.isArray(this.state.voting)) {
      const obj = this.state.voting.find(obj => obj.no === no);
      if (obj === undefined) {
        return '0';
      } else {
        return obj.value.toString();
      }
    }
  }

  playerNumbers(no) {
    let currentVotes = 0;
    if (no !== undefined) {
      currentVotes = +this.currentVote(no)
    }
    const numbers = [0];
    let j = 0;
    this.props.currentGame.players.forEach((el) => {
      if (el.alive) {
        j++;
        numbers.push(j)
      }
    });
    if (this.state.voted !== 0) {
      let diff = this.state.voted - currentVotes;
      numbers.splice(numbers.length - diff, diff)
    }
    return numbers;
  }

  availableVotes() {
    return this.state.players.filter(el => el.alive).length;
  }

  resultIsDetermined() {
    const voted = this.state.voting.map(el => el.votes.length).reduce((acc, el) => acc + el);
    return voted >= this.availableVotes();
  }

  filterMaxVotes() {
    const voting = this.state.voting;
    const array = voting.map(el => el.votes.length); //create an array of votes quantity
    const max = Math.max(...array); //get the bigest votes quantity
    const result = voting.filter(el => el.votes.length === max); //get a resulting array with the most votes
    return result;
  }

  results() {
    const res = this.filterMaxVotes();
    if (res.length === 1) { //1. if there is only one result with the most votes
      if (res[0].no === 'all') { //1.1. in case if the result is "all" players
        this.killPlayer(res[0].no);
        this.setState({ whoKilled: 'all' },
          () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
      } else if (res[0].no === 'none') { //1.2. in case if the result is "none" players
        this.setState({ vote: [], whoKilled: 'none' },
          () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
      } else { //1.3. in case if the result is a single player players
        res.forEach(el => this.killPlayer(el.no));
      }
    } else if (res.length > 1) { //2. if there is more that one result with the most votes
      if (res.find(el => el.no === 'all') !== undefined && res.find(el => el.no === 'none') !== undefined) { //2.1. if there is an equal amount of votes for "all" and "none"
        this.setState({ vote: [], whoKilled: 'none' },
          () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
      } else if (this.state.shooting && this.state.vote.length === res.length) { //2.2. if during the shooting we got the the same number of votes again
        this.setState({ shootingAgain: true });
        this.setState({ voting: this.prepareVoting('all') });
      } else { //2.3. everything else. for example, players voted for different players with equal votes
        const vote = res.map(el => el.no);
        this.setState({ vote: vote },
          () => store.dispatch({
            type: 'GAME',
            payload: this.state
          }));
        this.setState({ voting: this.prepareVoting() });
        this.setState({ shooting: true });
      }
    }
  }

  killPlayer(no) {
    const gamers = this.state.players;
    const dead = this.state.deadPlayers;
    if (no === 'all') {
      this.state.vote.forEach(el => dead.push(el));
      this.state.vote.forEach(no => gamers.forEach(el => el.no === no ? el.alive = false : el.alive));
    } else {
      gamers.forEach(el => el.no === no ? el.alive = false : el.alive);
      dead.push(no);
    }
    this.setState({
      players: gamers,
      deadPlayers: dead,
      killedLastNight: true,
    }, () => store.dispatch({
      type: 'GAME',
      payload: this.state
    }));
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (civilians.length <= mafia.length || mafia.length === 0) {
      store.dispatch({
        type: 'END_GAME'
      })
    }
  }

  handleVotingSkip(stopVoting, bannedPlayer, maxVotes) {
    if (stopVoting) {
      return 0
    } else {
      if (maxVotes[0].no === bannedPlayer) {
        return 0
      } else {
        return this.state.cycle + 1
      }
    }
  }

  banPlayer(no) {
    const gamers = this.state.players;
    const disqualified = this.state.disqualified;
    const maxVotes = this.filterMaxVotes();
    const stopVoting = this.calculateDecision(maxVotes, no);
    gamers.forEach(el => el.no === no ? el.alive = false : null);
    disqualified.push(no);
    this.setState({
      players: gamers,
      disqualified: disqualified,
      stopVoting,
      skipNextVoting: this.handleVotingSkip(stopVoting, no, maxVotes)
    }, () => {
      store.dispatch({
        type: 'GAME',
        payload: this.state
      })
    });
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    if (civilians.length <= mafia.length || mafia.length === 0) {
      store.dispatch({
        type: 'END_GAME'
      })
    }
  }

  calculateDecision(maxVotes, bannedPlayer) {
    const votedCount = this.state.voting.map(el => el.votes.length).reduce((acc, el) => acc + el)
    const votesLeft = this.availableVotes() - votedCount;
    const maxVotedCount = maxVotes[0].votes.length;
    if (maxVotes.length > 1) {
      return true;
    } else {
      if (maxVotedCount <= votesLeft) {
        return true;
      } else if (maxVotes[0].no === bannedPlayer) {
        return true;
      } else {
        return false;
      }
    }
  }

  disqualifiedPlayer() {
    const player = this.state.disqualified[this.state.disqualified.length - 1];
    return player;
  }

  remarks() {
    return (
      <div className="card mt-2">
        <div className='card-body p-1'>
          <div id='fouls'>
            <div id='fouls-text'>
              {this.props.config.labels.fouls}
            </div>
            <div id='fouls' className={`d-inline-block ${foulsRender().twoRows ? 'two-fouls-rows' : foulsRender().threeRows ? 'three-fouls-rows' : ''}`}>
              {this.props.currentGame.players.map(el =>
                <span
                  className={`fouls-container ${!el.alive && 'fouls-container-dead'} ${foulsRender().threeRows && 'pl-0 pr-0'}`}
                  key={el._id}>
                  <strong className='mr-1 number-container'>{el.no}</strong>
                  {el.alive ?
                    <span className='inner-fouls-container content-left'>
                      <BsExclamationOctagon
                        alt='add'
                        title='Add a foul'
                        className='hover-item button-icon mb-1'
                        onClick={() => this.updatePlayer(el._id, '+')}
                      />
                      <span
                        className='ml-1 mr-1 remarks-container hover-item'
                        title='Remove a foul'
                        onClick={() => this.updatePlayer(el._id, '-')}>
                        <b>{el.fouls}</b>
                      </span>
                    </span>
                    :
                    <span className='inner-fouls-container m-auto'>
                      <FaMedkit
                        alt='help'
                        title='Return this player'
                        className='hover-item button-icon mb-1'
                        onClick={() => this.updatePlayer(el._id, 'life')}
                      />
                    </span>
                  }
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  whoWon() {
    const gamers = this.state.players;
    const civilians = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Citizen' || el.role === 'Sheriff');
    const mafia = gamers
      .filter(el => el.alive === true)
      .filter(el => el.role === 'Mafia' || el.role === 'Don');
    return mafia.length >= civilians.length ? 1 : 2
  }

  getTime() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = today.getDate();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    return `${year}/${month + 1}/${day}_${hours}:${minutes < 10 ? 0 + minutes.toString() : minutes}`;
  }

  addComment() {
    const allComments = this.state.comments;
    let comment = this.state.currentComment;
    allComments.push(comment);
    this.setState({
      currentComment: '',
      comments: allComments
    });
  }

  playerDead(number) {
    const player = this.props.currentGame.players.find(el => el.no === number);
    return !player.alive;
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.addComment()
    }
  }

  render() {
    return this.props.currentUser.email === undefined && this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/public-game' />
      :
      this.props.currentGame.startDate === undefined
        ?
        <Redirect to='/game' />
        :
        this.state.stopVoting ?
          (<div className='page-wrap center' id='disqualified-player'>
            <div className='mt-3 mb-3'>

              {this.props.currentGame.type === 'public' ?
                <div className="card">
                  <div className='card-body p-1 text-sm'>
                    {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                  </div>
                </div>
                :
                null
              }

              <div className="card mt-2">
                <div className='card-body'>
                  <div>
                    {this.props.config.texts.leavesImmediately.replace('{N}', this.disqualifiedPlayer())}
                  </div>
                  <div className="stream-text">
                    {this.props.config.messages.streamNight}
                  </div>
                </div>
              </div>
              <div>
                <div className='d-flex justify-content-between'>
                  {this.state.prevState
                    &&
                    <button
                      id='back-btn'
                      className='btn btn-sm btn-warning m-1 nav-button'
                      onClick={() => this.back(this.state)}>
                      {this.props.config.buttons.back}
                    </button>}
                  <Link className="nav-button" to={'/game/night'}>
                    <button
                      id='next-btn'
                      className='btn btn-sm btn-warning m-1'
                      onClick={() => this.updateGame({
                        ...this.state,
                        prevState: JSON.parse(JSON.stringify(this.state)),
                        phase: '/game/night',
                        killedLastNight: false,
                        vote: [],
                        voting: [],
                        stopVoting: false
                      })}>{this.props.config.buttons.next}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>)
          :
          this.state.skipNextVoting === this.state.cycle ?
            (<div className='page-wrap center' id='skipped-voting'>
              <div className='mt-3 mb-3'>

                {this.props.currentGame.type === 'public' ?
                  <div className="card">
                    <div className='card-body p-1 text-sm'>
                      {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                    </div>
                  </div>
                  :
                  null
                }

                <div className="card mt-2">
                  <div className='card-body'>
                    <div>
                      {this.props.config.texts.votingCancelled}
                    </div>
                    <div className="stream-text">
                      {this.props.config.messages.streamNight}
                    </div>
                  </div>
                </div>
                <div>
                  <div className='d-flex justify-content-between'>
                    {this.state.prevState
                      &&
                      <button
                        id='back-btn'
                        className='btn btn-sm btn-warning m-1 nav-button'
                        onClick={() => this.back(this.state)}>
                        {this.props.config.buttons.back}
                      </button>}
                    <Link className="nav-button" to={'/game/night'}>
                      <button
                        id='next-btn'
                        className='btn btn-sm btn-warning m-1'
                        onClick={() => this.updateGame({
                          ...this.state,
                          prevState: JSON.parse(JSON.stringify(this.state)),
                          phase: '/game/night',
                          killedLastNight: false,
                          vote: [],
                          voting: [],
                          stopVoting: false
                        })}>{this.props.config.buttons.next}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>)
            :
            this.props.currentGame.vote.length === 0
              ?
              this.state.whoKilled === 'none'
                ?
                (<div className={this.props.isGame ? '' : 'page-wrap center'} id='vote-all-stay'>
                  <div className='mt-3 mb-3'>

                    {this.props.currentGame.type === 'public' ?
                      <div className="card">
                        <div className='card-body p-1 text-sm'>
                          {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                        </div>
                      </div>
                      :
                      null
                    }

                    <div className="card mt-2">
                      <div className='card-body'>
                        <div>
                          {this.props.config.texts.allStay}
                        </div>
                        <div className="stream-text">
                          {this.props.config.messages.streamNight}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='d-flex justify-content-between'>
                        {this.state.prevState
                          &&
                          <button
                            id='back-btn'
                            className='btn btn-sm btn-warning m-1 nav-button'
                            onClick={() => this.back(this.state)}>
                            {this.props.config.buttons.back}
                          </button>}
                        <Link className="nav-button" to={'/game/night'}>
                          <button
                            id='next-btn'
                            className='btn btn-sm btn-warning m-1'
                            onClick={() => this.updateGame({
                              ...this.state,
                              prevState: JSON.parse(JSON.stringify(this.state)),
                              phase: '/game/night',
                              killedLastNight: false,
                              vote: [],
                              voting: []
                            })}>{this.props.config.buttons.next}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>)
                :
                (<div className={this.props.isGame ? '' : 'page-wrap center'} id='vote-nobody-voted'>
                  <div className='mt-3 mb-3'>

                    {this.props.currentGame.type === 'public' ?
                      <div className="card">
                        <div className='card-body p-1 text-sm'>
                          {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                        </div>
                      </div>
                      :
                      null
                    }

                    <div className="card mt-2">
                      <div className='card-body'>
                        <div>{this.props.config.texts.noNominations}</div>
                        <div className="stream-text">
                          {this.props.config.messages.streamNight}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='d-flex justify-content-between'>
                        {this.state.prevState
                          &&
                          <button
                            id='back-btn'
                            className='btn btn-sm btn-warning m-1 nav-button'
                            onClick={() => this.back(this.state)}>
                            {this.props.config.buttons.back}
                          </button>}
                        <Link className="nav-button" to={'/game/night'}>
                          <button
                            id='next-btn'
                            className='btn btn-sm btn-warning m-1'
                            onClick={() => this.updateGame({
                              ...this.state,
                              prevState: JSON.parse(JSON.stringify(this.state)),
                              phase: '/game/night',
                              killedLastNight: false,
                              vote: []
                            })}>{this.props.config.buttons.next}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>)
              :
              this.props.currentGame.vote.length === 1 && this.props.currentGame.cycle === 1
                ?
                (<div className={this.props.isGame ? '' : 'page-wrap center'} id='vote-zero'>
                  <div className='mt-3 mb-3'>

                    {this.props.currentGame.type === 'public' ?
                      <div className="card">
                        <div className='card-body p-1 text-sm'>
                          {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                        </div>
                      </div>
                      :
                      null
                    }

                    <div className="card mt-2">
                      <div className='card-body'>
                        <div> {this.props.config.texts.singleNominee0.replace('{N}', this.props.currentGame.vote.toString())}</div>
                        <div className="stream-text">
                          {this.props.config.messages.streamNight}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='d-flex justify-content-between'>
                        {this.state.prevState
                          &&
                          <button
                            id='back-btn'
                            className='btn btn-sm btn-warning nav-button m-1'
                            onClick={() => this.back(this.state)}>
                            {this.props.config.buttons.back}
                          </button>}
                        <Link className="nav-button" to={'/game/night'}>
                          <button
                            id='next-btn'
                            className='btn btn-sm btn-warning m-1'
                            onClick={() => this.updateGame({
                              ...this.state,
                              prevState: JSON.parse(JSON.stringify(this.state)),
                              phase: '/game/night',
                              killedLastNight: false,
                              vote: []
                            })}>{this.props.config.buttons.next}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>)
                :
                this.props.currentGame.vote.length === 1 && this.props.currentGame.cycle > 1
                  ?
                  (<div className={this.props.isGame ? '' : 'page-wrap center'} id='vote-the-only-one'>
                    <div className='mt-3 mb-3'>

                      {this.props.currentGame.type === 'public' ?
                        <div className="card">
                          <div className='card-body p-1 text-sm'>
                            {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                          </div>
                        </div>
                        :
                        null
                      }

                      <div className="card mt-2">
                        <div className='card-body'>
                          {this.props.config.texts.singleNomineeN.split('\n').map((el, i) =>
                            <div key={i}>{el.replace('{N}', this.props.currentGame.vote.toString())}</div>
                          )}
                          <div className='mt-2 mb-2'>
                            <TimerButtons
                              timer={60}
                              volume={'low'}
                              size={'big'}
                              singleButton={true}
                              id={'singleNomineeN'}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='d-flex justify-content-between'>
                          {this.state.prevState
                            &&
                            <button
                              id='back-btn'
                              className='btn btn-sm btn-warning nav-button m-1'
                              onClick={() => this.back(this.state)}>
                              {this.props.config.buttons.back}
                            </button>}
                          {this.props.almostDone
                            ?
                            <Link className="nav-button" to={'/game/end'}>
                              <button
                                id='end-btn'
                                className='btn btn-sm btn-warning m-1' onClick={() => this.updateGame({
                                  ...this.state,
                                  phase: '/game/end',
                                  endDate: this.getTime(),
                                  whoWon: this.whoWon()
                                })}>{this.props.config.buttons.endGame}
                              </button>
                            </Link>
                            :
                            <Link className="nav-button" to={'/game/night'}>
                              <button
                                id='next-btn'
                                className='btn btn-sm btn-warning m-1'
                                onClick={() => this.updateGame({
                                  ...this.state,
                                  prevState: JSON.parse(JSON.stringify(this.state)),
                                  phase: '/game/night',
                                  killedLastNight: false,
                                  vote: []
                                })}>{this.props.config.buttons.next}
                              </button>
                            </Link>
                          }
                        </div>
                      </div>
                    </div>
                  </div>)
                  :
                  (<div className={this.props.isGame ? '' : 'page-wrap center'} id='vote-all-leave'>
                    <div className='mb-3 mt-3'>

                      {this.props.currentGame.type === 'public' ?
                        <div className="card">
                          <div className='card-body p-1 text-sm'>
                            {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                          </div>
                        </div>
                        :
                        null
                      }

                      <div className="card mt-2 custom-thead">
                        <div className='card-body p-1'>
                          <strong>{this.props.config.labels.voting} #{this.state.cycle} {
                            this.props.currentGame.killedLastNight ?
                              'Results'
                              :
                              this.state.shootingAgain ?
                                'Round 3'
                                :
                                this.state.shooting ?
                                  'Round 2'
                                  :
                                  'Round 1'
                          }</strong>
                        </div>
                      </div>

                      {this.props.currentGame.killedLastNight ?
                        <div>
                          <div className="card mt-2">
                            <div className='card-body p-2'>
                              {this.state.vote.length > 0 && this.state.whoKilled === 'all'
                                ?
                                <div>
                                  {this.props.config.texts.allLeave.replace('{NN}', '///{NN}///').split('///').map(text =>
                                    <div key={text}>{text === '{NN}' ? this.state.vote.map(vote =>
                                      <span key={vote} className="badge rounded-pill bg-primary text-light custom-badge-sm ml-1 mr-1">{vote}</span>
                                    ) : text}</div>)}
                                  <div className='mt-2 mb-2'>
                                    <TimerButtons
                                      timer={60}
                                      volume={'low'}
                                      size={'big'}
                                      singleButton={true}
                                      id={'allLeave'}
                                    />
                                  </div>
                                  {this.props.config.texts.applauseToAll.replace('{NN}', '///{NN}///').split('///').map(text =>
                                    <div key={text}>{text === '{NN}' ? this.state.vote.map(vote =>
                                      <span key={vote} className="badge rounded-pill bg-primary text-light custom-badge-sm ml-1 mr-1">{vote}</span>
                                    ) : text}</div>)
                                  }
                                  <div className="stream-text">
                                    {this.props.config.messages.streamNight}
                                  </div>
                                </div>
                                :
                                <div>
                                  <div>
                                    {this.props.config.texts.oneLeaves.replace('{N}', this.props.currentGame.deadPlayers.reverse()[0])}
                                  </div>
                                  <div className='mt-2 mb-2'>
                                    <TimerButtons
                                      timer={60}
                                      volume={'low'}
                                      size={'big'}
                                      singleButton={true}
                                      id={'oneLeaves'}
                                    />
                                  </div>
                                  <div>
                                    {this.props.config.texts.applause.replace('{N}', this.props.currentGame.deadPlayers[0])}
                                  </div>
                                </div>
                              }
                            </div>
                          </div>

                          <div className="card mt-2">
                            <div className='card-body d-flex justify-content-between p-1'>
                              {this.state.prevState
                                &&
                                <button
                                  id='back-btn'
                                  className='btn btn-sm btn-warning nav-button m-1'
                                  onClick={() => this.back(this.state)}>
                                  {this.props.config.buttons.back}
                                </button>}
                              {this.props.almostDone
                                ?
                                <Link className="nav-button" to={'/game/end'}>
                                  <button
                                    id='end-btn'
                                    className='btn btn-sm btn-warning m-1'
                                    onClick={() => this.updateGame({
                                      ...this.state,
                                      phase: '/game/end',
                                      endDate: this.getTime(),
                                      whoWon: this.whoWon()
                                    })}>{this.props.config.buttons.endGame}
                                  </button>
                                </Link>
                                :
                                <Link className="nav-button" to={'/game/night'}>
                                  <button
                                    id='next-btn'
                                    className='btn btn-sm btn-warning m-1'
                                    onClick={() => this.updateGame({
                                      ...this.state,
                                      prevState: JSON.parse(JSON.stringify(this.state)),
                                      phase: '/game/night',
                                      killedLastNight: false,
                                      vote: []
                                    })}>{this.props.config.buttons.next}
                                  </button>
                                </Link>
                              }
                            </div>
                          </div>
                        </div>
                        :
                        <div>
                          <div className="card mt-2">
                            <div className='card-body p-2'>
                              {this.state.shootingAgain
                                ?
                                <span>{this.props.config.texts.because}</span>
                                :
                                this.state.shooting
                                  ?
                                  <span>{this.props.config.texts.shootout}</span>
                                  :
                                  <span>{this.props.config.texts.playersVoted}</span>
                              }
                              <div>
                                {this.props.currentGame.vote.map(el =>
                                  <span key={el} className="badge rounded-pill bg-primary text-light custom-badge-sm ml-1 mr-1">{el}</span>)}
                              </div>
                              {this.state.shootingAgain
                                ?
                                <span>{this.props.config.texts.vootingForAllLeave}</span>
                                :
                                this.state.shooting
                                  ?
                                  <div>
                                    <div>{this.props.config.texts.shortTimeAll}</div>
                                    <div className='mt-2 mb-2'>
                                      <TimerButtons
                                        timer={30}
                                        volume={'low'}
                                        size={'big'}
                                        singleButton={true}
                                        id={'shortTimeAll'}
                                      />
                                    </div>
                                  </div>
                                  :
                                  null
                              }
                              {this.state.shootingAgain
                                ?
                                null
                                :
                                <div>
                                  {this.props.config.texts.votingOrder}
                                </div>
                              }
                            </div>
                          </div>

                          <div className="card mt-2">
                            <div className='card-body p-2'>
                              {this.props.config.texts.votingFouls}
                            </div>
                          </div>

                          {this.state.shootingAgain
                            ?
                            <div className="card mt-2">
                              <div className='card-body d-flex justify-content-between p-1'>
                                {votingRender().xs
                                  ?
                                  <span className="badge rounded-pill bg-primary text-light text-lg m-auto"><FaUsersSlash className='game-header-icon' /></span>
                                  :
                                  <div className={`d-inline-block m-auto ${votingRender().name && 'vote-name'}`}>
                                    {this.props.config.texts.supportAllLeave}
                                  </div>
                                }
                                <div className={`d-inline-block content-right ${votingRender().buttons ? 'vote-buttons' : votingRender().xs ? 'vote-buttons-xs' : ''}`}>
                                  <VscChecklist
                                    alt='Select all'
                                    title={`Select all who didn't vote`}
                                    className='hover-item game-header-icon'
                                    onClick={() => this.checkAll('all')}
                                  />
                                  {this.state.players.filter(pl => pl.alive).map(pl =>
                                    <button id={`vote-all-${pl.no}`}
                                      key={`vote-all-${pl.no}`}
                                      disabled={this.disableVoted('all', pl.no)}
                                      className={`vote-button ${votingRender().xs ? 'vote-button-xs' : 'vote-button-sm'} ${(this.playerVoted('all', pl.no) || this.disableVoted('all', pl.no)) && 'vote-selected'}`}
                                      onClick={() => this.playerVoted('all', pl.no) ? this.vote('all', pl.no, '-') : this.vote('all', pl.no, '+')}>
                                      {pl.no}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            :
                            this.props.currentGame.vote.map(el =>
                              <div className="card mt-2" key={el}>
                                <div className='card-body d-flex justify-content-between p-1'>
                                  {votingRender().xs
                                    ?
                                    <span className="badge rounded-pill bg-primary text-light text-lg m-auto custom-badge-lg">{el}</span>
                                    :
                                    <div className={`d-inline-block m-auto ${votingRender().name && 'vote-name'}`}>
                                      {this.props.config.texts.supportOneLeave.replace('{N}', el)}
                                    </div>
                                  }

                                  <div className={`d-inline-block content-right ${votingRender().buttons ? 'vote-buttons' : votingRender().xs ? 'vote-buttons-xs' : ''}`}>
                                    <VscChecklist
                                      alt='Select all'
                                      title={`Select all who didn't vote`}
                                      className='hover-item game-header-icon'
                                      onClick={() => this.checkAll(el)}
                                    />
                                    {this.state.players.filter(pl => pl.alive).map(pl =>
                                      <button id={`vote-${el}-${pl.no}`}
                                        key={`vote-${el}-${pl.no}`}
                                        disabled={this.disableVoted(el, pl.no)}
                                        className={`vote-button ${votingRender().xs ? 'vote-button-xs' : 'vote-button-sm'} ${(this.playerVoted(el, pl.no) || this.disableVoted(el, pl.no)) && 'vote-selected'}`}
                                        onClick={() => this.playerVoted(el, pl.no) ? this.vote(el, pl.no, '-') : this.vote(el, pl.no, '+')}>
                                        {pl.no}
                                      </button>
                                    )}
                                  </div>

                                </div>
                              </div>
                            )
                          }
                          {this.state.shootingAgain
                            ?
                            <div className="card mt-2">
                              <div className='card-body d-flex justify-content-between p-1'>
                                {votingRender().xs
                                  ?
                                  <span className="badge rounded-pill bg-primary text-light text-lg m-auto"><FaUsers className='game-header-icon' /></span>
                                  :
                                  <div className={`d-inline-block m-auto ${votingRender().name && 'vote-name'}`}>
                                    {this.props.config.texts.supportAllStay}
                                  </div>
                                }
                                <div className={`d-inline-block content-right ${votingRender().buttons ? 'vote-buttons' : votingRender().xs ? 'vote-buttons-xs' : ''}`}>
                                  <VscChecklist
                                    alt='Select all'
                                    title={`Select all who didn't vote`}
                                    className='hover-item game-header-icon'
                                    onClick={() => this.checkAll('none')}
                                  />
                                  {this.state.players.filter(pl => pl.alive).map(pl =>
                                    <button id={`vote-none-${pl.no}`}
                                      key={`vote-none-${pl.no}`}
                                      disabled={this.disableVoted('none', pl.no)}
                                      className={`vote-button ${votingRender().xs ? 'vote-button-xs' : 'vote-button-sm'} ${(this.playerVoted('none', pl.no) || this.disableVoted('none', pl.no)) && 'vote-selected'}`}
                                      onClick={() => this.playerVoted('none', pl.no) ? this.vote('none', pl.no, '-') : this.vote('none', pl.no, '+')}>
                                      {pl.no}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            :
                            null}

                          <div className="card mt-2">
                            <div className='card-body d-flex justify-content-between p-1'>
                              {this.state.prevState &&
                                <button
                                  id='back-btn'
                                  className='btn btn-sm btn-warning nav-button m-1'
                                  onClick={() => this.back(this.state)}>
                                  {this.props.config.buttons.back}
                                </button>
                              }
                              {this.props.almostDone
                                ?
                                <Link className="nav-button" to={'/game/end'}>
                                  <button
                                    id='end-btn'
                                    className='btn btn-sm btn-warning m-1'
                                    onClick={() => this.updateGame({
                                      ...this.state,
                                      prevState: JSON.parse(JSON.stringify(this.state)),
                                      phase: '/game/end',
                                      endDate: this.getTime(),
                                      whoWon: this.whoWon()
                                    })}>{this.props.config.buttons.endGame}
                                  </button>
                                </Link>
                                :
                                <button
                                  id='results-btn'
                                  className='btn btn-sm btn-info nav-button m-1'
                                  onClick={() => this.results()}
                                  disabled={!this.resultIsDetermined()}>
                                  {this.props.config.buttons.results}
                                </button>
                              }
                            </div>
                          </div>

                        </div>
                      }

                      {this.remarks()}

                      <div className="input-group mt-2">

                        <input
                          className="form-control form-control-sm"
                          value={this.state.currentComment}
                          placeholder={this.props.config.labels.comment}
                          maxLength='250'
                          id='add_comment_text'
                          onKeyDown={(e) => this.pressEnter(e)}
                          onChange={(e) =>
                            this.setState({
                              currentComment: e.target.value
                            }, () =>
                              store.dispatch({
                                type: 'NO_ERROR'
                              }))}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-sm btn-primary align-right"
                            id='add_comment'
                            disabled={this.state.currentComment.trim().length === 0}
                            onClick={() => this.addComment()}>
                            {this.props.config.buttons.addComment}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  currentUser: state.currentUser,
  almostDone: state.almostDone,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(withRouter(Vote));