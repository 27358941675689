import breakpoint from "./breakpoints";

export default function nominationsRender(page) {
    let obj = {};
    if (breakpoint() === 'XL') {
        obj.title = false;
        obj.buttons = false;
    } else if (breakpoint() === 'L') {
        obj.title = false;
        obj.buttons = false;
    } else if (breakpoint() === 'M') {
        obj.title = true;
        obj.buttons = false;
        obj.small = true;
    } else if (breakpoint() === 'S') {
        obj.title = true;
        obj.buttons = true;
    } else if (breakpoint() === 'XS') {
        obj.title = true;
        obj.buttons = true;
        obj.small = true;
    }
    return obj;
}