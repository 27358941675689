import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateTounament, inProgress, noProgress } from '../actions';
import axios from 'axios';
import server from '../server';
import Public from './PublicTournament';
import Admin from './admin/AdminTournament';
import Spinner from './additional/Spinner';
import NA from './NA';

class Tournament extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const content = this;
    content.props.inProgress();
    axios.get(`${server.address}/mafia-tournaments/${params.tourId}`)
      .then(res => {
        content.props.updateTounament(res.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        content.props.noProgress();
      });
  }

  getTourID() {
    const { match: { params } } = this.props;
    return params.tourId;
  }

  render() {
    if (this.props.progress) {
      return (<Spinner margin={true} />)
    } else if (this.props.currentUser.admin !== true && this.props.currentUser.moderator !== true) {
      return <Public id={this.getTourID()} />
    } else if (this.props.currentUser.admin === true || this.props.currentUser.moderator === true) {
      return (<Admin id={this.getTourID()} />)
    } else {
      return (<NA id={this.getTourID()} />)
    }
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  progress: state.progress
});

const mapDispatchToProps = dispatch => ({
  updateTounament: args => dispatch(updateTounament(args)),
  inProgress: () => dispatch(inProgress()),
  noProgress: () => dispatch(noProgress())
})

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);