import React, { Component } from 'react';
import '../css/custom.css';
import { connect } from 'react-redux';
import Spinner from './additional/Spinner';
import { inPreloading } from '../actions';

class Loading extends Component {
  componentDidMount() {
    setTimeout(() => this.props.inPreloading(), 500)
  }

  render() {
    if (this.props.preloading) {
      return (
        <div id='loading-page' className='page-wrap center'>
          <div className='text-lg mb-3' id='loading'>LOADING</div>
          <Spinner size='large' />
        </div>
      )
    } else {
      return <div className='page-wrap center' />
    }
  }
}

const mapStateToProps = state => ({
  preloading: state.preloading,
  config: state.config
});

const mapDispatchToProps = dispatch => ({
  inPreloading: () => dispatch(inPreloading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Loading);