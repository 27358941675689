import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import store from "../../redux/store";
import server from '../../server';
import axios from 'axios';

class AdminMenu extends Component {

  componentDidMount() {

  }

  updateLanguage(lang) {
    const config = this.props.config;
    config.currentLanguage = lang;
    config.roles = config.translations[lang].roles;
    config.copyright = config.translations[lang].copyright;
    config.title = config.translations[lang].title;
    config.buttons = config.translations[lang].buttons;
    config.texts = config.translations[lang].texts;
    config.labels = config.translations[lang].labels;
    config.messages = config.translations[lang].messages;
    this.patchConfig(config);
  }

  patchConfig(content) {
    store.dispatch({ type: 'NO_PRE_LOADING' });
    store.dispatch({ type: 'LOADING' });
    axios.patch(server.address + '/config/' + server.configID, content)
      .then(function () {
        store.dispatch({
          type: 'CONFIG',
          payload: content
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        store.dispatch({ type: 'NO_LOADING' });
      });
  }

  render() {
    return (
      <div className='content-top text-sm' id='admin-links'>
        <Link to={'/admin'} id='config' className='ml-2 mr-2'>
          <span>{this.props.config.buttons.config}</span>
        </Link>
        <Link to={'/admin/games'} id='admin-games' className='ml-2 mr-2'>
          <span>{this.props.config.buttons.games}</span>
        </Link>
        {Object.keys(this.props.config.translations).filter(el => el !== this.props.config.currentLanguage).map(el =>
          <span key={el} id={`${el}-lang-toggle`} className='ml-2 mr-2 link-item' onClick={() => this.updateLanguage(el)}>
            {el}
          </span>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config
});

export default connect(mapStateToProps)(AdminMenu);