import React, { Component } from 'react';
import { connect } from 'react-redux';
import Config from "./admin/Config";
import Restricted from './additional/Restricted';
import { Route, Switch } from 'react-router-dom';

class Admin extends Component {

  render() {
    if (this.props.currentUser.firstName === undefined) {
      return <Restricted />
    } else if (this.props.currentUser.admin !== true) {
      return <Restricted admin={true} />
    } else {
      return (
        <div id='admin'>
          <Switch>
            <Route path='/admin' exact component={Config} />
          </Switch>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps)(Admin);