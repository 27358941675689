import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from './additional/Spinner';
import Standings from './tournament/Standings';
import Games from './tournament/Games';
import axios from "axios";
import store from "../redux/store";
import server from '../server';

//SVG
import male from '../images/male.svg'
import female from '../images/female.svg'

const emptyReg = {
    sex: '',
    firstName: '',
    lastName: '',
    email: '',
    nickName: '',
    wins: 0,
    loses: 0,
    mainScore: 0,
    addScore: 0,
    bestMoveCount: 0,
    bestMoveScore: 0,
    bestPlayerCount: 0,
    bestPlayerScore: 0,
    additionalScore: 0,
    foulScore: 0,
    firstKilledCount: 0,
    firstKilledScore: 0
};

class PublicTournament extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tournament: { ...this.props.currentTournament },
            registration: this.props.currentUser.email === undefined ? { ...emptyReg } : { ...emptyReg, ...this.props.currentUser },
            showReg: false,
            loading: false,
            state: 'standings'
        };
    }

    componentDidMount() {
        store.dispatch({ type: 'NO_ERROR' });
    }

    getIcon(image) {
        let icon;
        try {
            icon = require(`../images/tournament/${image}.svg`);
        }
        catch {
            icon = '/favicon.ico';
        }
        return icon;
    }

    formatDate(date, how) {
        let newDate = new Date(date);
        let options;
        if (how === 'simple') {
            options = { timeZone: 'UTC' };
        } else {
            options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
        }
        return newDate.toLocaleDateString(undefined, options);
    }

    closeRegistration() {
        let regEndDate = new Date(this.state.tournament.registrationEnds);
        let date = new Date();
        return regEndDate >= date;
    }

    updateRegistration(key, data) {
        const user = this.state.registration;
        user[key] = data;
        this.setState({ registration: user }, () => store.dispatch({ type: 'NO_ERROR', }))
    }

    submitReg() {
        this.checkFields() ?
            store.dispatch({
                type: 'ERROR',
                payload: {
                    errorText: this.props.config.messages.fillAllFields
                }
            })
            :
            this.checkGender() ?
                store.dispatch({
                    type: 'ERROR',
                    payload: {
                        errorText: this.props.config.messages.selSex
                    }
                })
                :
                this.compareUsers();
    }

    checkFields() {
        return this.state.registration.email.trim().length === 0 || this.state.registration.nickName.trim().length === 0
            || this.state.registration.firstName.trim().length === 0 || this.state.registration.lastName.trim().length === 0
    }

    checkGender() {
        return this.state.registration.sex.trim().length === 0;
    }

    compareUsers() {
        this.setState({ loading: true })
        const content = this;
        axios.get(`${server.address}/mafia-tournaments/${content.props.id}`)
            .then(res => {
                let findEmail = res.data.players.find(el => el.email === this.state.registration.email);
                let findUser = res.data.players.find(el => el.nickName === this.state.registration.nickName);
                findEmail !== undefined ?
                    store.dispatch({
                        type: 'ERROR',
                        payload: {
                            errorText: content.props.config.messages.emailTaken
                        }
                    })
                    :
                    findUser !== undefined ?
                        store.dispatch({
                            type: 'ERROR',
                            payload: {
                                errorText: content.props.config.messages.nickTaken
                            }
                        })
                        :
                        content.updateTournament()
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                content.setState({ loading: false })
            });
    }

    updateTournament() {
        const obj = this.state.tournament;
        obj.players.push(this.state.registration);
        this.setState({ tournament: obj, registration: { ...emptyReg }, hideForm: true }, () => this.postData());
    }

    postData() {
        this.setState({ loading: true })
        const content = this;
        axios.patch(`${server.address}/mafia-tournaments/${content.props.id}`, this.state.tournament)
            .then(function (res) {
                store.dispatch({ type: 'SUCCESS_REGISTRATION' })
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                content.setState({ loading: false })
            });
    }

    render() {
        return (
            <div className="page-wrap" id='admin-tournament'>
                <div className='mt-3 mb-3'>

                    <div className="row">
                        <div className="col-md-2">
                            <img src={this.getIcon(this.props.currentTournament.icon)}
                                alt={`${this.props.currentTournament.name} icon`}
                                className='icon-xl'
                            />
                        </div>
                        <div className="col-md-6 center">
                            <h1><strong>{this.props.currentTournament.name}</strong></h1>
                        </div>
                        <div className="col-md-4 center">
                            <h6>{this.formatDate(this.props.currentTournament.startDate)}</h6>
                            <div>-</div>
                            <h6>{this.formatDate(this.props.currentTournament.endDate)}</h6>
                        </div>
                    </div>
                    <div className='container'>
                        <div className="row mt-3">
                            <div className="card col-lg m-1">
                                <div className='card-body d-flex justify-content-between align-items-center pl-0 pr-0'>
                                    <span>
                                        Registered players{/* TODO: config */}
                                    </span>
                                    <span className="ml-2 badge badge-primary badge-pill">{this.props.currentTournament.players.length}</span>
                                </div>
                            </div>
                            <div className="card col-lg m-1">
                                <div className='card-body d-flex justify-content-between align-items-center pl-0 pr-0'>
                                    <span>
                                        Max players{/* TODO: config */}
                                    </span>
                                    <span className="ml-2 badge badge-info badge-pill">{this.props.currentTournament.maxPlayers}</span>
                                </div>
                            </div>
                            <div className="card col-lg m-1">
                                <div className='card-body d-flex justify-content-between align-items-center pl-0 pr-0'>
                                    <span>
                                        Registration ends{/* TODO: config */}
                                    </span>
                                    <span className="ml-2 badge badge-danger badge-pill">{this.formatDate(this.props.currentTournament.registrationEnds, 'simple')}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="buttons" className='mt-1 mb-2'>
                        {this.closeRegistration()
                            &&
                            <button
                                className={`btn btn-sm ${this.state.state === 'registration' ? 'btn-primary' : 'btn-info'} m-2`}
                                id='reg-button'
                                onClick={() => this.setState({ state: 'registration' })}>
                                Registration{/* TODO: config */}
                            </button>
                        }
                        <button
                            className={`btn btn-sm ${this.state.state === 'standings' ? 'btn-primary' : 'btn-info'} m-2`}
                            id="standings-button"
                            onClick={() => this.setState({ state: 'standings' })}>
                            Standings{/* TODO: config */}
                        </button>
                        <button
                            className={`btn btn-sm ${this.state.state === 'games' ? 'btn-primary' : 'btn-info'} m-2`}
                            id="games-button"
                            onClick={() => this.setState({ state: 'games' })}>
                            Games{/* TODO: config */}
                        </button>
                    </div>

                    {
                        this.state.state === 'standings' ?
                            <Standings players={this.state.tournament.players} />
                            :
                            this.state.state === 'registration' ?
                                <div className={`mt-3" id="registration-form`}>

                                    {
                                        this.props.registrationSuccess ?
                                            <div className="text-center">
                                                Successfully registered{/* TODO: config */}
                                            </div>
                                            :
                                            <div className="text-center">

                                                <div className='mb-3'>
                                                    <span className='ml-2 mr-3'>
                                                        <img src={female}
                                                            alt='female'
                                                            className={this.state.registration.sex === 'female' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                                                            onClick={() => this.updateRegistration('sex', 'female')} />
                                                    </span>
                                                    <span className='ml-2 mr-3'>
                                                        <img src={male}
                                                            alt='male'
                                                            className={this.state.registration.sex === 'male' ? 'icon-sex icon-active' : 'icon-sex hover-item'}
                                                            onClick={() => this.updateRegistration('sex', 'male')} />
                                                    </span>
                                                </div>

                                                <div className="input-group mb-3">
                                                    <input value={this.state.registration.firstName}
                                                        onChange={(e) => this.updateRegistration('firstName', e.target.value)}
                                                        type="text"
                                                        id="firstName"
                                                        maxLength="100"
                                                        aria-label="First name"
                                                        placeholder={this.props.config.labels.accountFName + ' *'}
                                                        className="form-control" />

                                                    <input value={this.state.registration.lastName}
                                                        onChange={(e) => this.updateRegistration('lastName', e.target.value)}
                                                        type="text"
                                                        id="lastName"
                                                        maxLength="100"
                                                        aria-label="Last name"
                                                        placeholder={this.props.config.labels.accountLName + ' *'}
                                                        className="form-control" />
                                                </div>

                                                <div className="input-group mb-2">
                                                    <input value={this.state.registration.email}
                                                        onChange={(e) => this.updateRegistration('email', e.target.value)}
                                                        type="email"
                                                        id="email"
                                                        maxLength="100"
                                                        aria-label="Email"
                                                        placeholder={this.props.config.labels.accountEmail + ' *'}
                                                        className="form-control" />

                                                    <input value={this.state.registration.nickName}
                                                        onChange={(e) => this.updateRegistration('nickName', e.target.value)}
                                                        type="text"
                                                        id="nickname"
                                                        maxLength="100"
                                                        aria-label="Nickname"
                                                        placeholder={this.props.config.labels.accountNickname + ' *'}
                                                        className="form-control" />
                                                </div>

                                                {
                                                    this.state.loading ?
                                                        <div className="mb-2" >
                                                            <Spinner />
                                                        </div>
                                                        :
                                                        this.props.errorState ?
                                                            <div className="mb-2" >
                                                                <span className={this.props.success ? 'green-text' : 'error-text'} id='error-text'>
                                                                    {this.props.errorText}
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="mb-2">
                                                                <span className='error-placeholder' />
                                                            </div>
                                                }

                                                <div>
                                                    <button className="btn btn-sm btn-info ml-3 mr-3"
                                                        id="Submit"
                                                        onClick={() => this.submitReg()}>
                                                        Submit{/* TODO: config */}
                                                    </button>
                                                </div>

                                            </div>
                                    }
                                </div>
                                :
                                this.state.state === 'games' ?
                                    <Games games={this.state.tournament.games} />
                                    :
                                    null
                    }

                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    currentTournament: state.currentTournament,
    progress: state.progress,
    config: state.config,
    success: state.success,
    errorText: state.errorText,
    errorState: state.errorState,
    registrationSuccess: state.registrationSuccess
});

export default connect(mapStateToProps)(PublicTournament);