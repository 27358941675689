import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import store from "../../redux/store";
import axios from "axios";
import server from '../../server';
import TimerButtons from './helpers/TimerButtons';
import { withRouter } from 'react-router-dom';

class ZeroNight extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentGame.startDate !== undefined ? this.props.currentGame : {};
  }

  componentDidMount() {
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    })
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
  }

  back(game) {
    this.updateGame(game.prevState);
    this.props.history.goBack();
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + this.state._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  addComment() {
    const allComments = this.state.comments;
    let comment = this.state.currentComment;
    allComments.push(comment);
    this.setState({
      currentComment: '',
      comments: allComments
    });
  }

  pressEnter(enter) {
    if (enter.key === 'Enter') {
      this.addComment()
    }
  }

  render() {
    return this.props.currentUser.email === undefined && this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/public-game' />
      :
      this.props.currentGame.startDate === undefined
        ?
        <Redirect to='/game' />
        :
        (
          <div className={this.props.isGame ? '' : 'page-wrap'} id='zero-night'>

            <div className="mt-3 mb-3">

              {this.props.currentGame.type === 'public' ?
                <div className="card">
                  <div className='card-body p-1 text-sm'>
                    {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                  </div>
                </div>
                :
                null
              }

              <div className="card mt-2 custom-thead">
                <div className='card-body p-1'>
                  <strong>{this.props.config.labels.night} #{this.state.cycle}</strong>
                </div>
              </div>

              <div className="card mt-2">
                <div className='card-body p-2'>
                  {this.props.config.texts.mafiaTxt0.split('\n').map((el, i) => <div key={i}>
                    {el === '{countdown}' ?
                      <div className='mt-2 mb-2'>
                        <TimerButtons
                          timer={60}
                          volume={'high'}
                          size={'big'}
                          singleButton={true}
                          id={'mafiaTxt0'}
                        />
                      </div>
                      : el}
                  </div>)}
                </div>
              </div>

              <div className="card mt-2">
                <div className='card-body p-2'>
                  {this.props.config.texts.sheriffTxt0.split('\n').map((el, i) =>
                    <div key={i}>{el.includes('count')
                      ?
                      <div className='mt-2 mb-2'>
                        <TimerButtons
                          timer={20}
                          volume={'high'}
                          size={'big'}
                          singleButton={true}
                          id={'sheriffTxt0'}
                        />
                      </div>
                      : el}
                    </div>)}
                </div>
              </div>

              <div className="card mt-2">
                <div className='card-body d-flex justify-content-between p-1'>
                  {this.state.prevState
                    &&
                    <button
                      id='back-btn'
                      className='btn btn-sm btn-warning m-1'
                      onClick={() => this.back(this.state)}>
                      {this.props.config.buttons.back}
                    </button>
                  }
                  <Link to={'/game/day'}>
                    <button
                      id='next-btn'
                      className='btn btn-sm btn-warning m-1'
                      onClick={() => this.updateGame({
                        ...this.state,
                        prevState: JSON.parse(JSON.stringify(this.state)),
                        phase: '/game/day',
                        cycle: this.props.currentGame.cycle + 1
                      })}>{this.props.config.buttons.next}</button>
                  </Link>
                </div>
              </div>

              <div className="input-group mt-2">
                <input
                  className="form-control form-control-sm"
                  value={this.state.currentComment}
                  placeholder={this.props.config.labels.comment}
                  maxLength='250'
                  id='add_comment_text'
                  onKeyDown={(e) => this.pressEnter(e)}
                  onChange={(e) =>
                    this.setState({
                      currentComment: e.target.value
                    }, () =>
                      store.dispatch({
                        type: 'NO_ERROR'
                      }))}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-sm btn-primary align-right"
                    id='add_comment'
                    disabled={this.state.currentComment.trim().length === 0}
                    onClick={() => this.addComment()}>
                    {this.props.config.buttons.addComment}
                  </button>
                </div>
              </div>

            </div>
          </div >
        );
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  currentUser: state.currentUser,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(withRouter(ZeroNight));