import React, { Component } from 'react';
import '../../../css/custom.css';
import { connect } from 'react-redux';
import store from "../../../redux/store";
import axios from 'axios';
import server from '../../../server';

import { CgPlayButtonR, CgPlayStopR } from "react-icons/cg";

class TimerButtons extends Component {

  startTimer() {
    store.dispatch({
      type: 'ACTIVATE_TIMER',
      payload: {
        timer: this.props.timer,
        volume: this.props.volume,
        id: this.props.id
      }
    })
    this.props.activate && this.activatePlayer(this.props.player);
  }

  stopTimer() {
    store.dispatch({
      type: 'DEACTIVATE_TIMER',
    })
  }

  activatePlayer(player) {
    const game = this.props.currentGame;
    game.activePlayer = player;
    if (!game.talked.includes(player)) {
      game.talked.push(player);
    }
    this.updateGame(game);
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    })
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + game._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  render() {
    return (

      <span className='content-middle'>
        {
          this.props.displayPlayButtons === false
            ?
            null
            :
            this.props.singleButton === false
              ?
              <CgPlayButtonR
                id='play-btn'
                onClick={() => this.startTimer()}
                alt='play'
                className={`${this.props.timerIsActive ? 'disabled-icon mr-1' : 'hover-item mr-1'} ${this.props.size === 'big' ? 'timer-icon-lg' : 'timer-icon-sm'}`}
              />
              :
              this.props.timerIsActive && this.props.id === this.props.timerButtonID ?
                <CgPlayStopR
                  id='stop-btn'
                  onClick={() => this.props.timerIsActive ? this.stopTimer() : this.startTimer()}
                  alt='play'
                  className={`hover-item mr-1 ${this.props.size === 'big' ? 'timer-icon-lg' : 'timer-icon-sm'}`}
                />
                :
                <CgPlayButtonR
                  id='play-btn'
                  onClick={() => this.props.timerIsActive ? this.stopTimer() : this.startTimer()}
                  alt='play'
                  className={`hover-item mr-1 ${this.props.size === 'big' ? 'timer-icon-lg' : 'timer-icon-sm'}`}
                />
        }

        {
          this.props.displayPlayButtons === false
            ?
            null
            :
            this.props.singleButton === false
              ?
              <CgPlayStopR
                id='stop-btn'
                onClick={() => this.stopTimer()}
                alt='play'
                className={`${!this.props.timerIsActive ? 'disabled-icon mr-1' : 'hover-item mr-1'} ${this.props.size === 'big' ? 'timer-icon-lg' : 'timer-icon-sm'}`}
              />
              :
              null
        }

      </span>

    );
  }
}

const mapStateToProps = state => ({
  countdownTimer: state.countdownTimer,
  timerIsActive: state.timerIsActive,
  currentGame: state.currentGame,
  timerButtonID: state.timerButtonID
});

export default connect(mapStateToProps)(TimerButtons);