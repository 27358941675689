const environment = process.env.REACT_APP_ENV;

const object = {
    configID: '5f3b31ff2bcab600c24b0d58'
};

if (environment === 'PROD') {
    object.address = 'https://maf-place-prod-srv.azurewebsites.net';
    object.configID = '5f48f5327d9cdb0023d941e9';
} else if (environment === 'QA') {
    object.address = 'https://maf-place-qa-srv.azurewebsites.net';
} else if (environment === 'DEV') {
    object.address = 'https://maf-place-dev-srv.azurewebsites.net';
} else {
    object.address = 'http://localhost:5001';
}

export default object;