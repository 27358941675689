import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from "../../../redux/store";

class ConfigLabels extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
  }

  updateLabels(value, label) {
    const labels = this.state.labels;
    labels[label] = value;
    this.setState({ labels: labels },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  render() {
    return (
      <div className="text-center">
        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>General labels:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="copyrightText">Copyright</label>
              <input value={this.state.copyright}
                onChange={(e) => this.setState({ copyright: e.target.value },
                  () => store.dispatch({
                    type: 'CONFIG',
                    payload: this.state
                  }))}
                type="text"
                id="copyrightText"
                maxLength="40"
                aria-label="Copyright text"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="versionText">Version</label>
              <input value={this.state.labels.versionText}
                onChange={(e) => this.updateLabels(e.target.value, 'versionText')}
                type="text"
                id="versionText"
                maxLength="40"
                aria-label="Version Text"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="websiteByText">Website By</label>
              <input value={this.state.labels.websiteByText}
                onChange={(e) => this.updateLabels(e.target.value, 'websiteByText')}
                type="text"
                id="websiteByText"
                maxLength="40"
                aria-label="Website By Text"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="adminGamesNothing">No games to display</label>
              <input value={this.state.labels.adminGamesNothing}
                onChange={(e) => this.updateLabels(e.target.value, 'adminGamesNothing')}
                type="text"
                id="adminGamesNothing"
                maxLength="40"
                aria-label="No games to display"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Account labels:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="account-fname-label">First Name</label>
              <input value={this.state.labels.accountFName}
                onChange={(e) => this.updateLabels(e.target.value, 'accountFName')}
                type="text"
                id="account-fname-label"
                maxLength="40"
                aria-label="Account-First-Name"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-lname-label">Last Name</label>
              <input value={this.state.labels.accountLName}
                onChange={(e) => this.updateLabels(e.target.value, 'accountLName')}
                type="text"
                id="account-lname-label"
                maxLength="40"
                aria-label="Account-Last-Name"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-email-label">Email</label>
              <input value={this.state.labels.accountEmail}
                onChange={(e) => this.updateLabels(e.target.value, 'accountEmail')}
                type="text"
                id="account-email-label"
                maxLength="40"
                aria-label="Account-Email"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-password-label">Password</label>
              <input value={this.state.labels.accountPassword}
                onChange={(e) => this.updateLabels(e.target.value, 'accountPassword')}
                type="text"
                id="account-password-label"
                maxLength="40"
                aria-label="Account-Password"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="account-nickname-label">Nickname</label>
              <input value={this.state.labels.accountNickname}
                onChange={(e) => this.updateLabels(e.target.value, 'accountNickname')}
                type="text"
                id="account-nickname-label"
                maxLength="40"
                aria-label="Account-Nickname"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-rating-label">Rating</label>
              <input value={this.state.labels.accountRating}
                onChange={(e) => this.updateLabels(e.target.value, 'accountRating')}
                type="text"
                id="account-rating-label"
                maxLength="40"
                aria-label="Account-Rating"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-visits-label">Visits</label>
              <input value={this.state.labels.accountVisits}
                onChange={(e) => this.updateLabels(e.target.value, 'accountVisits')}
                type="text"
                id="account-visits-label"
                maxLength="40"
                aria-label="Account-Visits"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-dob-label">Date of Birth</label>
              <input value={this.state.labels.accountDOB}
                onChange={(e) => this.updateLabels(e.target.value, 'accountDOB')}
                type="text"
                id="account-dob-label"
                maxLength="40"
                aria-label="Account-DOB"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="account-wins-label">Wins</label>
              <input value={this.state.labels.accountWins}
                onChange={(e) => this.updateLabels(e.target.value, 'accountWins')}
                type="text"
                id="account-wins-label"
                maxLength="40"
                aria-label="Account-Wins"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="account-loses-label">Loses</label>
              <input value={this.state.labels.accountLoses}
                onChange={(e) => this.updateLabels(e.target.value, 'accountLoses')}
                type="text"
                id="account-loses-label"
                maxLength="40"
                aria-label="Account-Loses"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="moderator-label">Moderator</label>
              <input value={this.state.labels.moderator}
                onChange={(e) => this.updateLabels(e.target.value, 'moderator')}
                type="text"
                id="moderator-label"
                maxLength="40"
                aria-label="Moderator-Rating"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Placeholders:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="search-label">Search</label>
              <input value={this.state.labels.search}
                onChange={(e) => this.updateLabels(e.target.value, 'search')}
                type="text"
                id="search-label"
                maxLength="40"
                aria-label="Search"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="comment-label">Comment</label>
              <input value={this.state.labels.comment}
                onChange={(e) => this.updateLabels(e.target.value, 'comment')}
                type="text"
                id="comment-label"
                maxLength="40"
                aria-label="Comment"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="nickname-or-name">Nickname or Name</label>
              <input value={this.state.labels.nicknameName}
                onChange={(e) => this.updateLabels(e.target.value, 'nicknameName')}
                type="text"
                id="nickname-or-name"
                maxLength="40"
                aria-label="Nickname or Name"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="loginEmail">Login Email</label>
              <input value={this.state.labels.loginEmail}
                onChange={(e) => this.updateLabels(e.target.value, 'loginEmail')}
                type="text"
                id="loginEmail"
                maxLength="40"
                aria-label="Login Email"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="loginPassword">Login Password</label>
              <input value={this.state.labels.loginPassword}
                onChange={(e) => this.updateLabels(e.target.value, 'loginPassword')}
                type="text"
                id="loginPasswordl"
                maxLength="40"
                aria-label="Login Password"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Game labels:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="rating-game-label">Rating Game</label>
              <input value={this.state.labels.ratingGame}
                onChange={(e) => this.updateLabels(e.target.value, 'ratingGame')}
                type="text"
                id="rating-game-label"
                maxLength="40"
                aria-label="Rating-Game"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="new-game-paid-players-label">Present Players</label>
              <input value={this.state.labels.players}
                onChange={(e) => this.updateLabels(e.target.value, 'players')}
                type="text"
                id="new-game-paid-players-label"
                maxLength="40"
                aria-label="Paid-Players"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="new-game-players-label">New Game Players</label>
              <input value={this.state.labels.game}
                onChange={(e) => this.updateLabels(e.target.value, 'game')}
                type="text"
                id="new-game-players-label"
                maxLength="40"
                aria-label="New-Game-Players"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="comments">Comments</label>
              <input value={this.state.labels.comments}
                onChange={(e) => this.updateLabels(e.target.value, 'comments')}
                type="text"
                id="comments"
                maxLength="40"
                aria-label="Comments"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="mister-label">Mister</label>
              <input value={this.state.labels.mister}
                onChange={(e) => this.updateLabels(e.target.value, 'mister')}
                type="text"
                id="mister-label"
                maxLength="40"
                aria-label="Mister"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="madam-label">Madam</label>
              <input value={this.state.labels.madam}
                onChange={(e) => this.updateLabels(e.target.value, 'madam')}
                type="text"
                id="madam-label"
                maxLength="40"
                aria-label="Madam"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="game-roles-label">Game Roles</label>
              <input value={this.state.labels.gameRoles}
                onChange={(e) => this.updateLabels(e.target.value, 'gameRoles')}
                type="text"
                id="game-roles-label"
                maxLength="40"
                aria-label="Game-Roles"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="game-day-label">Game Day</label>
              <input value={this.state.labels.gameDay}
                onChange={(e) => this.updateLabels(e.target.value, 'gameDay')}
                type="text"
                id="game-day-label"
                maxLength="40"
                aria-label="Game-Day"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="voting-label">Voting</label>
              <input value={this.state.labels.voting}
                onChange={(e) => this.updateLabels(e.target.value, 'voting')}
                type="text"
                id="voting-label"
                maxLength="40"
                aria-label="Voting"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="night-label">Night</label>
              <input value={this.state.labels.night}
                onChange={(e) => this.updateLabels(e.target.value, 'night')}
                type="text"
                id="night-label"
                maxLength="40"
                aria-label="Night"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="nominations-label">Nominations</label>
              <input value={this.state.labels.nominations}
                onChange={(e) => this.updateLabels(e.target.value, 'nominations')}
                type="text"
                id="nominations-label"
                maxLength="40"
                aria-label="Nominations"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="fouls-label">Fouls</label>
              <input value={this.state.labels.fouls}
                onChange={(e) => this.updateLabels(e.target.value, 'fouls')}
                type="text"
                id="fouls-label"
                maxLength="40"
                aria-label="Fouls"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="mafia-shooting-label">Mafia Shooting</label>
              <input value={this.state.labels.mafiaShooting}
                onChange={(e) => this.updateLabels(e.target.value, 'mafiaShooting')}
                type="text"
                id="mafia-shooting-label"
                maxLength="40"
                aria-label="Mafia-Shooting"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="donChecking-label">Don Checking</label>
              <input value={this.state.labels.donChecking}
                onChange={(e) => this.updateLabels(e.target.value, 'donChecking')}
                type="text"
                id="donChecking-label"
                maxLength="40"
                aria-label="Don Checking"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="sheriffChecking-label">Sheriff Checking</label>
              <input value={this.state.labels.sheriffChecking}
                onChange={(e) => this.updateLabels(e.target.value, 'sheriffChecking')}
                type="text"
                id="sheriffChecking-label"
                maxLength="40"
                aria-label="Sheriff Checking"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="publicGameList-label">Public game list</label>
              <input value={this.state.labels.publicGameList}
                onChange={(e) => this.updateLabels(e.target.value, 'publicGameList')}
                type="text"
                id="publicGameList-label"
                maxLength="40"
                aria-label="Public game list"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div> <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="notEnough">Not enough players</label>
              <input value={this.state.labels.notEnough}
                onChange={(e) => this.updateLabels(e.target.value, 'notEnough')}
                type="text"
                id="notEnough"
                maxLength="40"
                aria-label="Not enough players"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="uniqueID">Unique ID</label>
              <input value={this.state.labels.uniqueID}
                onChange={(e) => this.updateLabels(e.target.value, 'uniqueID')}
                type="text"
                id="uniqueID"
                maxLength="40"
                aria-label="Unique ID"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="createdGameTitle">Game Title</label>
              <input value={this.state.labels.createdGameTitle}
                onChange={(e) => this.updateLabels(e.target.value, 'createdGameTitle')}
                type="text"
                id="createdGameTitle"
                maxLength="40"
                aria-label="Game Title"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="saveID">Save ID</label>
              <input value={this.state.labels.saveID}
                onChange={(e) => this.updateLabels(e.target.value, 'saveID')}
                type="text"
                id="saveID"
                maxLength="40"
                aria-label="Save ID"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="selectAllRoles">Select All Roles</label>
              <input value={this.state.labels.selectAllRoles}
                onChange={(e) => this.updateLabels(e.target.value, 'selectAllRoles')}
                type="text"
                id="selectAllRoles"
                maxLength="40"
                aria-label="Select All Roles"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="bestMove">Best Move</label>
              <input value={this.state.labels.bestMove}
                onChange={(e) => this.updateLabels(e.target.value, 'bestMove')}
                type="text"
                id="bestMove"
                maxLength="40"
                aria-label="Best Move"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Rating Labels:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="placeLabel">Place</label>
              <input value={this.state.labels.placeLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'placeLabel')}
                type="text"
                id="placeLabel"
                maxLength="40"
                aria-label="Place"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="nicknameLabel">Nickname</label>
              <input value={this.state.labels.nicknameLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'nicknameLabel')}
                type="text"
                id="nicknameLabel"
                maxLength="40"
                aria-label="Nickname"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="ratingLabel">Rating</label>
              <input value={this.state.labels.ratingLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'ratingLabel')}
                type="text"
                id="ratingLabel"
                maxLength="40"
                aria-label="Rating"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="numberOfGamesLabel">Number of games</label>
              <input value={this.state.labels.numberOfGamesLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'numberOfGamesLabel')}
                type="text"
                id="numberOfGamesLabel"
                maxLength="40"
                aria-label="Number of games"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="victoriesLabel">Victories</label>
              <input value={this.state.labels.victoriesLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'victoriesLabel')}
                type="text"
                id="victoriesLabel"
                maxLength="40"
                aria-label="Victories"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="percentVictoriesLabel">Percent of victories</label>
              <input value={this.state.labels.percentVictoriesLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'percentVictoriesLabel')}
                type="text"
                id="percentVictoriesLabel"
                maxLength="40"
                aria-label="Percent of victories"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="bonusLabel">Bonus</label>
              <input value={this.state.labels.bonusLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'bonusLabel')}
                type="text"
                id="bonusLabell"
                maxLength="40"
                aria-label="Bonus"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="penaltyLabel">Penalty</label>
              <input value={this.state.labels.penaltyLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'penaltyLabel')}
                type="text"
                id="penaltyLabel"
                maxLength="40"
                aria-label=">Penalty"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="firstKilledLabel">First Killed</label>
              <input value={this.state.labels.firstKilledLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'firstKilledLabel')}
                type="text"
                id="firstKilledLabel"
                maxLength="40"
                aria-label="First Killed"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="rolesRatingLabel">Roles Rating</label>
              <input value={this.state.labels.rolesRatingLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'rolesRatingLabel')}
                type="text"
                id="rolesRatingLabel"
                maxLength="40"
                aria-label="Roles Rating"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="dateRangeLabel">Date Range</label>
              <input value={this.state.labels.dateRangeLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'dateRangeLabel')}
                type="text"
                id="dateRangeLabel"
                maxLength="40"
                aria-label="Date Range"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="gameRoleLabel">Game Role</label>
              <input value={this.state.labels.gameRoleLabel}
                onChange={(e) => this.updateLabels(e.target.value, 'gameRoleLabel')}
                type="text"
                id="gameRoleLabel"
                maxLength="40"
                aria-label="Game Role"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Moderator Games:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="adminGamesPhase">Games Phase</label>
              <input value={this.state.labels.adminGamesPhase}
                onChange={(e) => this.updateLabels(e.target.value, 'adminGamesPhase')}
                type="text"
                id="adminGamesPhase"
                maxLength="40"
                aria-label="Admin Games Phase"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="adminGamesDate">Games Date</label>
              <input value={this.state.labels.adminGamesDate}
                onChange={(e) => this.updateLabels(e.target.value, 'adminGamesDate')}
                type="text"
                id="adminGamesDate"
                maxLength="40"
                aria-label="Admin Games Date"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="adminGamesTime">Games Time</label>
              <input value={this.state.labels.adminGamesTime}
                onChange={(e) => this.updateLabels(e.target.value, 'adminGamesTime')}
                type="text"
                id="adminGamesTime"
                maxLength="40"
                aria-label="Admin Games Time"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
});

export default connect(mapStateToProps)(ConfigLabels);