import breakpoint from "./breakpoints";

export default function trimAmount(page) {
    let trimAmount;
    if (breakpoint() === 'XL') {
        trimAmount = 45;
    } else if (breakpoint() === 'L') {
        trimAmount = 40;
    } else if (breakpoint() === 'M') {
        trimAmount = 30;
    } else if (breakpoint() === 'S') {
        trimAmount = page === 'day' || page === 'end' ? 15 : 20;
    } else if (breakpoint() === 'XS') {
        trimAmount = page === 'public-game' ? 20 : 10;
    }
    return trimAmount;
}