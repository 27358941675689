import React, { Component } from 'react';
import { connect } from 'react-redux';
import Menu from "./header/Menu";
import GameHeader from "./header/GameHeader";

class Header extends Component {

  displayGameHeader() {
    const pagesToDisplay = [
      '/game/initial',
      '/game/roles',
      '/game/night',
      '/game/day',
      '/game/vote'
    ];
    return pagesToDisplay.includes(this.props.currentGame.phase) && this.props.url.includes('/game')
  }

  render() {
    return (
      <div className='card-footer site-header' id='header'>

        {this.displayGameHeader() ?
          <GameHeader />
          :
          <Menu />
        }
      </div>
    );
  }

}

const mapStateToProps = state => ({
  config: state.config,
  currentGame: state.currentGame,
  url: state.url,
  isGame: state.isGame
});

export default connect(mapStateToProps)(Header);