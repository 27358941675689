import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import store from "../../redux/store";
import server from '../../server';
import trimString from '../../helpers/trim-string';
import howMuchTrim from '../../helpers/trim-amount';
import breakpoint from '../../helpers/breakpoints';
import { GrUpdate } from 'react-icons/gr';
import { TfiSave } from "react-icons/tfi";
import { IoCloudDone } from "react-icons/io5";

class End extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props.currentGame };
  }

  componentDidMount() {
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
    if (this.props.currentGame.bestMove !== undefined) {
      this.calcBonus();
    }
  }

  calcBonus() {
    let bestMoveScore;
    let mafiaNo = this.state.players.filter(el => el.role === 'Mafia' || el.role === 'Don').map(el => el.no);
    if (mafiaNo.includes(this.state.bestMovePlayer)) {
      bestMoveScore = 0;
    } else {
      let length = this.state.bestMove.filter(el => mafiaNo.includes(el)).length;
      if (length === 3) {
        bestMoveScore = Number(this.props.config.ratings.bestMove3);
      } else if (length === 2) {
        bestMoveScore = Number(this.props.config.ratings.bestMove2);
      } else if (length === 1) {
        bestMoveScore = Number(this.props.config.ratings.bestMove1);
      } else {
        bestMoveScore = 0;
      }
    }
    let gamers = this.state.players;
    gamers.forEach(el => {
      const rating = this.state.updatedPlayers.find(upd => upd.no === el.no);
      if (rating !== undefined) {
        el.additionalScore = rating.ratingObj.additionalPoints;
      } else if (el.no === this.state.bestMovePlayer) {
        el.additionalScore = bestMoveScore + Number(this.props.config.ratings.autoBonus);
      } else {
        el.additionalScore = Number(this.props.config.ratings.autoBonus);
      }
    });
    this.setState({ bestMoveScore: bestMoveScore, players: gamers }, () => this.calcPenalties());
  }

  calcPenalties() {
    let gamers = this.state.players;
    gamers.forEach(el => {
      const rating = this.state.updatedPlayers.find(upd => upd.no === el.no);
      if (rating !== undefined) {
        el.penaltyScore = rating.ratingObj.penaltyPoints;
      } else if (this.state.disqualified.includes(el.no)) {
        el.penaltyScore = Number(this.props.config.ratings.disqualification);
        el.additionalScore = 0;
      } else {
        el.penaltyScore = 0;
      }
    })
    this.setState({ players: gamers })
  }

  additionalScore(no, value) {
    let players = this.state.players;
    players.forEach(el => el.no === no ? el.additionalScore = value.trim().replace(',', '.') : null);
    this.setState({ players: players })
  }

  penaltyScore(no, value) {
    let players = this.state.players;
    players.forEach(el => el.no === no ? el.penaltyScore = value.trim().replace(',', '.') : null);
    this.setState({ players: players })
  }

  updateUser(user) {
    let rating = Number(parseFloat(this.returnRating(user.role)).toFixed(2));
    let bonus = Number(parseFloat(user.additionalScore).toFixed(2));
    let penalty = Number(parseFloat(user.penaltyScore).toFixed(2));
    let winOrLose = this.returnWin(user.role);
    let win = Number(winOrLose.win);
    let lose = Number(winOrLose.lose);
    this.pushRating(user._id, user.no, rating, bonus, penalty, win, lose, user.role);
  }

  returnRole(role) {
    return this.props.config.roles.find(el => el.role === role).translation
  }

  pushRating(id, no, rating, bonus, penalty, win, lose, role) {
    const content = this;
    const endDate = new Date(content.state.endDate.replace('_', ' ')).valueOf()
    const ratingObj = {
      userId: id,
      gameId: content.state._id,
      rating: rating,
      role: role,
      additionalPoints: bonus,
      penaltyPoints: penalty,
      firstKilled: content.state.bestMovePlayer === no ? true : false,
      win: win,
      lose: lose,
      date: endDate,
      tournament: false,
      tournamentId: null,
    };
    axios.post(server.address + '/rating', ratingObj)
      .then(function (res) {
        if (res.data.message === 'rating saved to /rating') {
          const array = content.state.updatedPlayers.filter(el => el.no !== no);
          array.push({ no, ratingObj });
          content.setState({ updatedPlayers: array })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        content.updateGame();
      })
  }

  updateGame() {
    const content = this;
    axios.patch(server.address + '/mafia-games/' + content.state._id, content.state)
      .then(res => {
        if (res.data.message === 'Game updated') {
          store.dispatch({
            type: 'GAME',
            payload: content.state
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  back(game) {
    this.updateGame(game.prevState);
    this.props.history.goBack();
  }

  newGame() {
    if (this.props.currentGame.type === 'public') {
      axios.delete(server.address + '/mafia-games/' + this.props.currentGame._id)
        .then(() => {
          store.dispatch({
            type: 'NEW_GAME'
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    } else {
      store.dispatch({
        type: 'NEW_GAME'
      });
    }
  }

  returnRating(role) {
    if (this.props.currentGame.rating === true) {
      if (this.props.currentGame.whoWon === 1) {
        if (role === 'Mafia') {
          return this.props.config.ratings.mafiaWin;
        } else if (role === 'Don') {
          return this.props.config.ratings.donWin;
        } else if (role === 'Citizen') {
          return this.props.config.ratings.citizenLose;
        } else if (role === 'Sheriff') {
          return this.props.config.ratings.sheriffLose;
        } else {
          return `err`;
        }
      } else if (this.props.currentGame.whoWon === 2) {
        if (role === 'Mafia') {
          return this.props.config.ratings.mafiaLose;
        } else if (role === 'Don') {
          return this.props.config.ratings.donLose;
        } else if (role === 'Citizen') {
          return this.props.config.ratings.citizenWin;
        } else if (role === 'Sheriff') {
          return this.props.config.ratings.sheriffWin;
        } else {
          return `err`;
        }
      } else {
        return 0;
      }
    } else {
      return 'err';
    }
  }

  returnWin(role) {
    if (this.props.currentGame.rating === true) {
      if (this.props.currentGame.whoWon === 1) {
        if (role === 'Mafia') {
          return { win: 1, lose: 0 };
        } else if (role === 'Don') {
          return { win: 1, lose: 0 };
        } else if (role === 'Citizen') {
          return { win: 0, lose: 1 };
        } else if (role === 'Sheriff') {
          return { win: 0, lose: 1 };
        } else {
          return { win: 0, lose: 1 };
        }
      } else if (this.props.currentGame.whoWon === 2) {
        if (role === 'Mafia') {
          return { win: 0, lose: 1 };
        } else if (role === 'Don') {
          return { win: 0, lose: 1 };
        } else if (role === 'Citizen') {
          return { win: 1, lose: 0 };
        } else if (role === 'Sheriff') {
          return { win: 1, lose: 0 };
        } else {
          return { win: 1, lose: 0 };
        }
      } else {
        return { win: 0, lose: 0 };
      }
    } else {
      return { win: 0, lose: 0 };
    }
  }

  render() {
    return this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/game' />
      :
      <div className={this.props.isGame ? '' : 'page-wrap'} id='end-page'>

        <div className='mt-3 mb-3'>
          <div className="card custom-thead">
            <div className='card-body p-1'>
              {this.props.currentGame.whoWon === 1
                ?
                <strong><span id='mafia-wins'>{this.props.config.texts.mafiaWins}</span></strong>
                : this.props.currentGame.whoWon === 2
                  ?
                  <strong><span id='city-wins'>{this.props.config.texts.cityWins}</span></strong>
                  :
                  <strong><span id='nobody-wins'>{this.props.config.texts.nobodyWins}</span></strong>
              }
            </div>
          </div>

          <table className='table table-sm text-sm'>
            <thead className='thead-light'>
              <tr>
                <th>{this.props.config.labels.placeLabel}</th>
                {breakpoint() === 'XS' ? null : <th>{this.props.config.labels.nicknameLabel}</th>}
                <th>{this.props.config.labels.gameRoleLabel}</th>
                {
                  this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                    null
                    :
                    <th>{this.props.config.labels.ratingLabel}</th>
                }
                {
                  this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                    null
                    :
                    <th>{this.props.config.labels.bonusLabel}</th>
                }
                {
                  this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                    null
                    :
                    <th>{this.props.config.labels.penaltyLabel}</th>
                }
                {
                  this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                    null
                    :
                    <th><GrUpdate className={breakpoint() === 'XS' ? '' : "mr-2"} />{breakpoint() === 'XS' ? null : this.props.config.buttons.update}</th>
                }
              </tr>
            </thead>
            <tbody>
              {this.props.currentGame.players.map(el =>
                <tr key={el._id}>

                  <td className='content-middle'>{el.no}</td>

                  {breakpoint() === 'XS' ? null : <td className='content-middle'>{trimString(el.nickName, howMuchTrim('end'))}</td>}

                  <td className={`content-middle ${el.role === 'Citizen' || el.role === 'Sheriff' ? 'text-red' : null}`}>
                    {el.role !== 'Citizen' ?
                      <strong>{this.returnRole(el.role)}</strong>
                      :
                      this.returnRole(el.role)
                    }
                  </td>

                  {
                    this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                      null
                      :
                      <td className='content-middle'>{this.returnRating(el.role)}</td>
                  }

                  {
                    this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                      null
                      :
                      <td className='content-middle'>
                        <input
                          value={el.additionalScore || '0'}
                          onChange={(e) => this.additionalScore(el.no, e.target.value)}
                          type="text"
                          maxLength="4"
                          disabled={this.props.currentGame.updatedPlayers.find(rtng => rtng.no === el.no) !== undefined}
                          className="form-control form-control-sm add-score-input">
                        </input>
                      </td>
                  }

                  {
                    this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                      null
                      :
                      <td className='content-middle'>
                        <input
                          value={el.penaltyScore || '0'}
                          onChange={(e) => this.penaltyScore(el.no, e.target.value)}
                          type="text"
                          maxLength="4"
                          disabled={this.props.currentGame.updatedPlayers.find(rtng => rtng.no === el.no) !== undefined}
                          className="form-control form-control-sm add-score-input">
                        </input>
                      </td>
                  }

                  {
                    this.props.currentGame.type === 'public' || this.props.currentGame.rating === false ?
                      null
                      :
                      <td className='content-middle'>
                        {this.props.currentGame.updatedPlayers.find(rtng => rtng.no === el.no) !== undefined ?
                          <IoCloudDone
                            alt='yes'
                            id={`updated_${el.no}`}
                            className='button-icon'
                          />
                          :
                          <TfiSave
                            onClick={() => this.updateUser(el)}
                            alt='Update'
                            title="Update User"
                            id={`update_${el.no}`}
                            className='button-icon hover-item'
                          />
                        }
                      </td>
                  }

                </tr>
              )}
            </tbody>
          </table>

          {this.props.currentGame.comments.length > 0 ?
            <div className='mb-3'>
              <b>{this.props.config.labels.comments}</b>
              <ul className='text-left'>
                {this.props.currentGame.comments.map((el, i) =>
                  <li key={Math.random()}>{el}</li>)}
              </ul>
            </div>
            :
            null
          }

          <div className="card mt-2">
            <div className='card-body d-flex justify-content-between p-1'>
              {this.state.prevState
                &&
                <button
                  id='back-btn'
                  className='btn btn-sm btn-warning nav-button m-1'
                  onClick={() => this.back(this.state)}>
                  {this.props.config.buttons.back}
                </button>
              }
              <button
                id='new-game-btn'
                disabled={this.props.currentGame.type === 'public' ? false : this.state.players.length > this.state.updatedPlayers.length}
                className='btn btn-sm btn-primary nav-button m-1'
                onClick={() => this.newGame()}>
                {this.props.config.buttons.newGame}
              </button>
            </div>
          </div>

        </div>

      </div >
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  users: state.users,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(End);