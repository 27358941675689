import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from "../../../redux/store";

import end from '../../../mp3/timers/10sec.mp3';
import endClock from '../../../mp3/timers/10sec-clock.mp3';

class ConfigAudio extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
  }

  updateAudio(value, audio) {
    const audios = this.state.audios;
    audios[audio] = value;
    this.setState({ audios: audios },
      () => {
        store.dispatch({
          type: 'CONFIG',
          payload: this.state
        })
      });
  }

  render() {
    return (
      <div className="text-center">

        {this.props.platform === 'desktop' ?
          <div className='config-section'>
            <div className='text-left text-reg config-section-title'>Music:</div>
            <hr></hr>
            <div className="form-row">
              <div className="form-group col-md-3 content-middle">
                <label htmlFor="localMusic">Replace Music by YouTube</label>
                <div><input
                  id='localMusic'
                  type="checkbox"
                  name="localMusic"
                  className='hover-item big-checkbox'
                  checked={!this.state.audios.localMusic}
                  onChange={() => this.updateAudio(!this.state.audios.localMusic, 'localMusic')} />
                </div>
              </div>
              <div className="form-group col-md-3 content-middle">
                <label htmlFor="showYT">Show YouTube window</label>
                <div><input
                  id='showYT'
                  type="checkbox"
                  name="showYT"
                  className='hover-item big-checkbox'
                  checked={this.state.audios.showYT}
                  onChange={() => this.updateAudio(!this.state.audios.showYT, 'showYT')} />
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="musicVolume">Music Volume</label>
                <div>
                  <input
                    type="range"
                    onChange={(e) => this.updateAudio(+e.target.value / 100, 'musicVolume')}
                    min="0"
                    max="100"
                    step="1"
                    className="music-vol-slider"
                    value={this.state.audios.musicVolume * 100}
                    id="musicVolume" />
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="YTid">YouTube video ID</label>
                <input value={this.state.audios.YTid}
                  onChange={(e) => this.updateAudio(e.target.value, 'YTid')}
                  type="text"
                  id="YTid"
                  maxLength="11"
                  aria-label="YT-id"
                  placeholder="*"
                  className="form-control form-control-sm" />
              </div>
            </div>
          </div>
          :
          null
        }

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Other Sounds:</div>
          <hr></hr>
          <div className="form-row">
            {this.props.platform === 'desktop' ?
              <div className="form-group col-md-3">
                <label htmlFor="nightTikTokVolume">Night Tik-Tok Volume</label>
                <div>
                  <input
                    type="range"
                    onChange={(e) => this.updateAudio(+e.target.value / 100, 'nightTikTokVolume')}
                    min="0"
                    max="100"
                    step="1"
                    className="music-vol-slider"
                    value={this.state.audios.nightTikTokVolume * 100}
                    id="nightTikTokVolume" />
                </div>
              </div>
              :
              null
            }
            {this.props.platform === 'desktop' ?
              <div className="form-group col-md-3">
                <label htmlFor="dayTikTokVolume">Day Tik-Tok Volume</label>
                <div>
                  <input
                    type="range"
                    onChange={(e) => this.updateAudio(+e.target.value / 100, 'dayTikTokVolume')}
                    min="0"
                    max="100"
                    step="1"
                    className="music-vol-slider"
                    value={this.state.audios.dayTikTokVolume * 100}
                    id="dayTikTokVolume" />
                </div>
              </div>
              :
              null
            }
            <div className="form-group col-md-3">
              <label htmlFor="hitsTikTok">Hits Tik-Tok</label>
              <div>
                <input
                  type="radio"
                  className="mr-1 config-player-radio"
                  onChange={() => this.updateAudio('hits', 'tikTokType')}
                  value={this.state.audios.tikTokType}
                  checked={this.state.audios.tikTokType === 'hits'}
                  id="hitsTikTok" />
                <audio id='timer-hits' controls className="config-player">
                  <source src={end} type="audio/mpeg" />
                  If you see this message your browser does not support the audio element.
                </audio>
              </div>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="clockTikTok">Clock Tik-Tok</label>
              <div>
                <input
                  type="radio"
                  className="mr-1 config-player-radio"
                  onChange={() => this.updateAudio('clock', 'tikTokType')}
                  value={this.state.audios.tikTokType}
                  checked={this.state.audios.tikTokType === 'clock'}
                  id="clockTikTok" />
                <audio id='timer-clock' controls className="config-player">
                  <source src={endClock} type="audio/mpeg" />
                  If you see this message your browser does not support the audio element.
                </audio>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
  platform: state.platform
});

export default connect(mapStateToProps)(ConfigAudio);