import React, { Component } from 'react';
import '../../css/custom.css';
import { connect } from 'react-redux';
import { withRouter, Link, Redirect } from "react-router-dom";
import store from "../../redux/store";
import axios from "axios";
import server from '../../server';
import trimString from '../../helpers/trim-string';
import howMuchTrim from '../../helpers/trim-amount';
import randomNum from '../../helpers/random-number';
import { TfiReload } from 'react-icons/tfi';

class Initial extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.currentGame.startDate !== undefined ? this.props.currentGame : {};
  }

  componentDidMount() {
    this.generateSeats();
    store.dispatch({
      type: 'URL',
      payload: this.props.location.pathname
    });
  }

  updateGame(game) {
    this.postData(game);
    store.dispatch({
      type: 'GAME',
      payload: game
    });
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
  }

  postData(game) {
    axios.patch(server.address + '/mafia-games/' + game._id, game)
      .catch(function (error) {
        console.log(error);
      })
  }

  generateSeats() {
    const game = this.state;
    const gamers = game.players;
    gamers.forEach((el, i) => el.no = i + 1);
    const newGame = { ...game, players: gamers }
    this.setState(newGame, () => {
      this.updateGame(this.state);
    });
  }

  ramdomizeSeats() {
    const game = this.state;
    const gamers = game.players;
    gamers.forEach(el => el.randomNo = randomNum());
    gamers.sort((a, b) => a.randomNo - b.randomNo);
    gamers.forEach((el, i) => el.no = i + 1);
    const newGame = { ...game, players: gamers }
    this.setState(newGame, () => {
      this.updateGame(this.state);
    });
  }

  randomNum() {
    return parseInt(Math.random() * 1000000)
  }

  render() {
    return this.props.currentUser.email === undefined && this.props.currentGame.startDate === undefined
      ?
      <Redirect to='/public-game' />
      :
      this.props.currentGame.startDate === undefined
        ?
        <Redirect to='/game' />
        :
        (
          <div className={this.props.isGame ? '' : 'page-wrap'} id='initial-page'>
            <div className='mt-3 mb-3'>

              {this.props.currentGame.type === 'public' ?
                <div className="card">
                  <div className='card-body p-1 text-sm'>
                    {this.props.config.labels.uniqueID}: {this.props.currentGame._id}
                  </div>
                </div>
                :
                null
              }

              <div className="card mt-2">
                <div className='card-body p-2'>
                  {this.props.config.texts.openGameTxt.split('\n').map((el, i) => <div key={i}>{el}</div>)}
                </div>
              </div>

              <div className="card custom-thead mt-2">
                <div className='card-body p-1'>
                  <strong>{this.props.config.texts.greetings}</strong>
                </div>
              </div>

              <table className='table table-sm mb-0'>
                <tbody>
                  {this.props.currentGame.players.map(el =>
                    <tr key={el._id}>
                      <td width="5%"><b>{el.no}</b></td>
                      <td width="30%">{el.sex === 'female' ? this.props.config.labels.madam : this.props.config.labels.mister}</td>
                      <td width="65%"><b>{trimString(el.nickName, howMuchTrim())}</b></td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="card mb-2">
                <div className='card-body p-2'>
                  {this.props.config.texts.beginNightTxt.split('\n').map((el, i) => <div key={i}>{el}</div>)}
                  <div className="stream-text">
                    {this.props.config.messages.streamNight}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className='card-body d-flex justify-content-between p-1'>
                  <button id='next-random'
                    className='btn btn-sm btn-warning m-1'
                    onClick={() => this.ramdomizeSeats()}>
                    <TfiReload className='mr-2' />
                    Random
                  </button>
                  <Link to={'/game/roles'}>
                    <button id='next-btn'
                      className='btn btn-sm btn-warning m-1'
                      onClick={() => this.updateGame({
                        ...this.state,
                        prevState: this.state,
                        phase: '/game/roles',
                      })}>{this.props.config.buttons.next}</button>
                  </Link>
                </div>
              </div>

            </div>
          </div >
        );
  }
}

const mapStateToProps = state => ({
  currentGame: state.currentGame,
  currentUser: state.currentUser,
  config: state.config,
  isGame: state.isGame
});

export default connect(mapStateToProps)(withRouter(Initial));