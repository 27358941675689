import React, { Component } from 'react';
import '../../../css/custom.css';
import { connect } from 'react-redux';
import store from "../../../redux/store";
import end from '../../../mp3/timers/10sec.mp3';
import endMobile from '../../../mp3/timers/10sec-low.mp3';
import endClock from '../../../mp3/timers/10sec-clock.mp3';
import endClockMobile from '../../../mp3/timers/10sec-clock-low.mp3';

import { CgAdd, CgRemove } from "react-icons/cg";

let timer;

class Timer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timer: this.props.countdownTimer,
      active: this.props.timerIsActive,
      volume: this.props.countdownVolume
    };
    this.timer = 0;
    this.lastSeconds = 12;
  }

  componentDidMount() {
    timer = document.getElementById('timer');
    timer.volume = this.state.volume === 'low' ? this.props.config.audios.dayTikTokVolume : this.props.config.audios.nightTikTokVolume;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countdownTimer !== this.props.countdownTimer) {
      this.setState({ timer: this.props.countdownTimer })
    }
    if (prevProps.timerIsActive !== this.props.timerIsActive) {
      this.setState({ active: this.props.timerIsActive }, () => this.startStopCountdown())
    }
    if (prevProps.countdownVolume !== this.state.volume) {
      this.setState({ volume: this.props.countdownVolume }, () => {
        timer.src = this.audioFile();
      })
    }
  }

  audioFile() {
    if (this.props.config.audios.tikTokType === 'clock') {
      if (this.props.platform === 'mobile') {
        if (this.props.volume === 'low') {
          return endClockMobile;
        } else {
          return endClock;
        }
      } else {
        return endClock;
      }
    } else {
      if (this.props.platform === 'mobile') {
        if (this.props.volume === 'low') {
          return endMobile;
        } else {
          return end;
        }
      } else {
        return end;
      }
    }
  }

  startStopCountdown() {
    if (this.state.active) {
      this.startTimer()
    } else {
      this.stopTimer()
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.setState({ timer: this.state.timer - 1 });

      if (this.state.timer === this.lastSeconds) {
        timer.play();
      }

      if (this.state.timer === 0) {
        this.stopTimer();
      }

    }, 1000);
  }

  stopTimer() {
    store.dispatch({
      type: 'DEACTIVATE_TIMER'
    })
    clearInterval(this.timer);
    this.setState(this.initialState);
    timer.load();
  }

  showClock() {
    let minutes = parseInt(this.state.timer / 60)
    let seconds = parseInt(this.state.timer % 60)
    minutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    seconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${seconds}`
  }

  render() {
    return (

      <div className='content-middle'>

        {
          this.props.displayAddButtons === false
            ?
            null
            :
            <CgAdd
              id='plus-btn'
              onClick={() => this.state.timer <= 590 ? this.setState({ timer: this.state.timer + 10 }) : null}
              alt='plus'
              className={`${this.state.timer > 590 ? 'disabled-icon' : 'hover-item'} ${this.props.size === 'big' ? 'game-header-icon' : 'button-icon'}`}
            />
        }

        <span
          id='time-left'
          className={`content-middle d-inline-block ${this.props.size === 'big' ? 'text-lg big-timer' : 'small-timer'}`}>
          {this.showClock()}
        </span>

        {
          this.props.displayAddButtons === false
            ?
            null
            :
            <CgRemove
              id='minus-btn'
              onClick={() => this.state.timer >= 10 ? this.setState({ timer: this.state.timer - 10 }) : null}
              alt='minus'
              className={`${this.state.timer < 10 ? 'disabled-icon' : 'hover-item'} ${this.props.size === 'big' ? 'game-header-icon' : 'button-icon'}`}
            />
        }

        <audio id='timer'>
          <source src={this.audioFile()} type="audio/mpeg" />
          If you see this message your browser does not support the audio element.
        </audio>

      </div>

    );
  }
}

const mapStateToProps = state => ({
  countdownTimer: state.countdownTimer,
  timerIsActive: state.timerIsActive,
  countdownVolume: state.countdownVolume,
  currentGame: state.currentGame,
  config: state.config,
  bestMoveDisplayed: state.bestMoveDisplayed,
  platform: state.platform
});

export default connect(mapStateToProps)(Timer);