import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from "../../../redux/store";

class ConfigRoles extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
  }

  updateRoles(value, role) {
    const roles = this.state.roles;
    const find = roles.find(el => el.role === role);
    find.translation = value;
    let restRoles = roles.filter(el => el.role !== role);
    restRoles.push(find);
    this.setState({ roles: restRoles },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  updateRolesQuantity(value, role) {
    const roles = this.state.roles;
    const find = roles.find(el => el.role === role);
    find.quantity = +value;
    let restRoles = roles.filter(el => el.role !== role);
    restRoles.push(find);
    this.setState({ roles: restRoles },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  updateRolesRating(value, role, scenario) {
    const roles = this.state.roles;
    const find = roles.find(el => el.role === role);
    if (scenario === 'win') {
      find.winRating = +value;
    } else if (scenario === 'lose') {
      find.loseRating = +value;
    }
    let restRoles = roles.filter(el => el.role !== role);
    restRoles.push(find);
    this.setState({ roles: restRoles },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  updateRatings(value, message) {
    const ratings = this.state.ratings;
    ratings[message] = value;
    this.setState({ ratings: ratings },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  render() {
    return (
      <div className="text-center">

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Roles:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="don">Don</label>
              <input value={this.state.roles.find(el => el.role === 'Don').translation}
                onChange={(e) => this.updateRoles(e.target.value, 'Don')}
                type="text"
                id="don"
                maxLength="40"
                aria-label="Don role"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="mafia">Mafia</label>
              <input value={this.state.roles.find(el => el.role === 'Mafia').translation}
                onChange={(e) => this.updateRoles(e.target.value, 'Mafia')}
                type="text"
                id="mafia"
                maxLength="40"
                aria-label="Mafia role"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="citizen">Citizen</label>
              <input value={this.state.roles.find(el => el.role === 'Citizen').translation}
                onChange={(e) => this.updateRoles(e.target.value, 'Citizen')}
                type="text"
                id="citizen"
                maxLength="40"
                aria-label="Citizen role"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="sheriff">Sheriff</label>
              <input value={this.state.roles.find(el => el.role === 'Sheriff').translation}
                onChange={(e) => this.updateRoles(e.target.value, 'Sheriff')}
                type="text"
                id="sheriff"
                maxLength="40"
                aria-label="Sheriff role"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="don-quantity">Don quantity</label>
              <input value={this.state.roles.find(el => el.role === 'Don').quantity}
                onChange={(e) => this.updateRolesQuantity(e.target.value, 'Don')}
                type="text"
                id="don-quantity"
                maxLength="2"
                aria-label="Don quantity"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="mafia-quantity">Mafia quantity</label>
              <input value={this.state.roles.find(el => el.role === 'Mafia').quantity}
                onChange={(e) => this.updateRolesQuantity(e.target.value, 'Mafia')}
                type="text"
                id="mafia-quantity"
                maxLength="2"
                aria-label="Mafia quantity"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="citizen-quantity">Citizen quantity</label>
              <input value={this.state.roles.find(el => el.role === 'Citizen').quantity}
                onChange={(e) => this.updateRolesQuantity(e.target.value, 'Citizen')}
                type="text"
                id="citizen-quantity"
                maxLength="2"
                aria-label="Citizen quantity"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="sheriff-quantity">Sheriff quantity</label>
              <input value={this.state.roles.find(el => el.role === 'Sheriff').quantity}
                onChange={(e) => this.updateRolesQuantity(e.target.value, 'Sheriff')}
                type="text"
                id="sheriff-quantity"
                maxLength="2"
                aria-label="Sheriff quantity"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Win Rates:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="don-wins">Don wins</label>
              <input value={this.state.roles.find(el => el.role === 'Don').winRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Don', 'win')}
                type="text"
                id="don-wins"
                maxLength="2"
                aria-label="Don wins"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="mafia-wins">Mafia wins</label>
              <input value={this.state.roles.find(el => el.role === 'Mafia').winRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Mafia', 'win')}
                type="text"
                id="mafia-wins"
                maxLength="2"
                aria-label="Mafia wins"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="citizen-wins">Citizen wins</label>
              <input value={this.state.roles.find(el => el.role === 'Citizen').winRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Citizen', 'win')}
                type="text"
                id="citizen-wins"
                maxLength="2"
                aria-label="Citizen wins"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="sheriff-wins">Sheriff wins</label>
              <input value={this.state.roles.find(el => el.role === 'Sheriff').winRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Sheriff', 'win')}
                type="text"
                id="sheriff-wins"
                maxLength="2"
                aria-label="Sheriff wins"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="don-loses">Don loses</label>
              <input value={this.state.roles.find(el => el.role === 'Don').loseRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Don', 'lose')}
                type="text"
                id="don-loses"
                maxLength="2"
                aria-label="Don loses"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="mafia-loses">Mafia loses</label>
              <input value={this.state.roles.find(el => el.role === 'Mafia').loseRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Mafia', 'lose')}
                type="text"
                id="mafia-loses"
                maxLength="2"
                aria-label="Mafia loses"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="citizen-loses">Citizen loses</label>
              <input value={this.state.roles.find(el => el.role === 'Citizen').loseRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Citizen', 'lose')}
                type="text"
                id="citizen-loses"
                maxLength="2"
                aria-label="Citizen loses"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="sheriff-loses">Sheriff loses</label>
              <input value={this.state.roles.find(el => el.role === 'Sheriff').loseRating}
                onChange={(e) => this.updateRolesRating(e.target.value, 'Sheriff', 'lose')}
                type="text"
                id="sheriff-loses"
                maxLength="2"
                aria-label="Sheriff loses"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Points:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="bestMove3">Best move for 3</label>
              <input value={this.state.ratings.bestMove3}
                onChange={(e) => this.updateRatings(e.target.value, 'bestMove3')}
                type="text"
                id="bestMove3"
                maxLength="40"
                aria-label="Best move for 3"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="bestMove2">Best move for 2</label>
              <input value={this.state.ratings.bestMove2}
                onChange={(e) => this.updateRatings(e.target.value, 'bestMove2')}
                type="text"
                id="bestMove2"
                maxLength="40"
                aria-label="Best move for 2"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="bestMove1">Best move for 1</label>
              <input value={this.state.ratings.bestMove1}
                onChange={(e) => this.updateRatings(e.target.value, 'bestMove1')}
                type="text"
                id="bestMove1"
                maxLength="40"
                aria-label="Best move for 1"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="autoBonus">Auto Bonus</label>
              <input value={this.state.ratings.autoBonus}
                onChange={(e) => this.updateRatings(e.target.value, 'autoBonus')}
                type="text"
                id="autoBonus"
                maxLength="40"
                aria-label="Auto Bonus"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="disqualification">Disqualification</label>
              <input value={this.state.ratings.disqualification}
                onChange={(e) => this.updateRatings(e.target.value, 'disqualification')}
                type="text"
                id="disqualification"
                maxLength="40"
                aria-label="Disqualification"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="ppk">Opposite team win</label>
              <input value={this.state.ratings.ppk}
                onChange={(e) => this.updateRatings(e.target.value, 'ppk')}
                type="text"
                id="ppk"
                maxLength="40"
                aria-label="PPK"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
});

export default connect(mapStateToProps)(ConfigRoles);