import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Link } from "react-router-dom";
import axios from 'axios';
import server from '../../server';
import trimString from '../../helpers/trim-string';
import howMuchTrim from '../../helpers/trim-amount';
import Spinner from '../additional/Spinner';
import { updateTounament } from '../../actions';

//SVG
import rating from '../../images/ratings/rating.svg';
import games from '../../images/ratings/games.svg';
import number from '../../images/ratings/number.svg';
import nickname from '../../images/ratings/nick.svg';
import win from '../../images/ratings/win.svg';
import percent from '../../images/ratings/percent.svg';
import plus from '../../images/plus.svg';
import remove from '../../images/remove-user.svg';
import delIcon from '../../images/trash.svg';

class Standings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      players: this.props.currentTournament.players.sort((a, b) => b.mainScore - a.mainScore),
      progress: false
    };
  }

  componentDidMount() {
  }

  removePlayer(nickName) {
    const removed = this.state.players.filter(el => el.nickName !== nickName);
    this.setState({ players: removed.sort((a, b) => b.mainScore - a.mainScore), progress: true }, () => this.updateTournament(removed));
  }

  updateTournament(payload) {
    const content = this;
    axios.patch(`${server.address}/mafia-tournaments/${content.props.currentTournament._id}`, { players: payload })
      .then((res) => {
        content.props.updateTounament({ ...content.props.currentTournament, players: payload })
        content.setState({ progress: false })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  render() {
    if (this.state.progress) {
      return (<Spinner />)
    } else {
      return (
        <div id='standings'>

          <table className='table table-sm table-borderless'>
            <thead className='thead-light'>
              <tr className='players-table-row'>
                <th width='5%' id='number'><img src={number} title='Place' alt='number' className='checkmark' /></th>
                <th width='50%' id='nickname'><img src={nickname} title='Nickname' alt='nick' className='checkmark' /></th>
                <th width='9%' id='rating'><img src={rating} title='Rating' alt='rating' className='checkmark' /></th>
                <th width='9%' id='add-rating'><img src={plus} title='Additional Rating' alt='add-rating' className='checkmark' /></th>
                <th width='9%' id='games'><img src={games} title='Number of games' alt='games' className='checkmark' /></th>
                <th width='9%' id='wins'><img src={win} title='Victories' alt='win' className='checkmark' /></th>
                <th width='9%' id='percent'><img src={percent} title='Percent of vistories' alt='percent' className='checkmark' /></th>
                {
                  this.props.currentUser.admin === true || this.props.currentUser.moderator === true
                    ?
                    <th width='5%' id='delete'><img src={remove} title='Remove Player' alt='remove' className='checkmark' /></th>
                    :
                    null
                }
              </tr>
            </thead>
            {this.state.players.length > 0 ?
              <tbody>
                {this.state.players.map((el, i) =>
                  <tr
                    key={el.email}
                    id={`player_${i + 1}`}
                    className='players-row players-table-row'>
                    <td id={`no_${i + 1}`}>{i + 1}</td>
                    <td id={`nickname_${i + 1}`}>
                      {trimString(el.nickName, howMuchTrim())}
                    </td>
                    <td id={`rating_${i + 1}`}>{el.mainScore}</td>
                    <td id={`add-rating_${i + 1}`}>{el.additionalScore}</td>
                    <td id={`games_${i + 1}`}>{el.wins + el.loses}</td>
                    <td id={`wins_${i + 1}`}>{el.wins}</td>
                    <td id={`percent_${i + 1}`}>{el.wins === 0 ? 0 : parseInt(el.wins / (el.wins + el.loses) * 100)}</td>
                    {
                      this.props.currentUser.admin === true || this.props.currentUser.moderator === true
                        ?
                        <td id={`delete_${i + 1}`}>
                          <img
                            src={delIcon}
                            onClick={() => this.removePlayer(el.nickName)}
                            title='Deletee Player'
                            alt='delete'
                            height={16}
                            className='hover-item' />
                        </td>
                        :
                        null
                    }
                  </tr>
                )}
              </tbody>
              :
              <tbody id='nothing'>
                <tr>
                  <td colSpan={7}>
                    <div className='empty-list'>
                      {this.props.config.messages.noSearchRes}
                    </div>
                  </td>
                </tr>
              </tbody>
            }
          </table>

        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  config: state.config,
  currentUser: state.currentUser,
  currentTournament: state.currentTournament
});

const mapDispatchToProps = dispatch => ({
  updateTounament: args => dispatch(updateTounament(args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Standings);