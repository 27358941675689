import breakpoint from "./breakpoints";

export default function foulsRender(page) {
    let obj = {};
    if (breakpoint() === 'XL') {
        obj.twoRows = false;
    } else if (breakpoint() === 'L') {
        obj.twoRows = false;
    } else if (breakpoint() === 'M') {
        obj.twoRows = true;
    } else if (breakpoint() === 'S') {
        obj.twoRows = true;
    } else if (breakpoint() === 'XS') {
        obj.twoRows = false;
        obj.threeRows = true;
    }
    return obj;
}