import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from "../../../redux/store";

class ConfigMessages extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.config;
  }

  componentDidMount() {
  }

  updateMessages(value, message) {
    const messages = this.state.messages;
    messages[message] = value;
    this.setState({ messages: messages },
      () => store.dispatch({
        type: 'CONFIG',
        payload: this.state
      }));
  }

  render() {
    return (
      <div className="text-center">

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>General errors and success messages:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="configUpdated">Config updated</label>
              <input value={this.state.messages.configUpdated}
                onChange={(e) => this.updateMessages(e.target.value, 'configUpdated')}
                type="text"
                id="configUpdated"
                maxLength="40"
                aria-label="Config updated"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="playerRemoved">Player removed</label>
              <input value={this.state.messages.playerRemoved}
                onChange={(e) => this.updateMessages(e.target.value, 'playerRemoved')}
                type="text"
                id="playerRemoved"
                maxLength="40"
                aria-label="Player removed"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="infoSaved">Information saved</label>
              <input value={this.state.messages.infoSaved}
                onChange={(e) => this.updateMessages(e.target.value, 'infoSaved')}
                type="text"
                id="infoSaved"
                maxLength="40"
                aria-label="Information saved"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="noSearchRes">No search result</label>
              <input value={this.state.messages.noSearchRes}
                onChange={(e) => this.updateMessages(e.target.value, 'noSearchRes')}
                type="text"
                id="noSearchRes"
                maxLength="40"
                aria-label="No search result"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="noGame">No such game</label>
              <input value={this.state.messages.noGame}
                onChange={(e) => this.updateMessages(e.target.value, 'noGame')}
                type="text"
                id="noGame"
                maxLength="40"
                aria-label="No such game"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Game messages:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="yesSheriff">Yes, Sheriff</label>
              <input value={this.state.messages.yesSheriff}
                onChange={(e) => this.updateMessages(e.target.value, 'yesSheriff')}
                type="text"
                id="yesSheriff"
                maxLength="40"
                aria-label="Yes, Sheriff"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="noSheriff">No, not Sheriff</label>
              <input value={this.state.messages.noSheriff}
                onChange={(e) => this.updateMessages(e.target.value, 'noSheriff')}
                type="text"
                id="noSheriff"
                maxLength="40"
                aria-label="Don-Check-Placeholder"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="yesMafia">Yes, Mafia</label>
              <input value={this.state.messages.yesMafia}
                onChange={(e) => this.updateMessages(e.target.value, 'yesMafia')}
                type="text"
                id="yesMafia"
                maxLength="40"
                aria-label="Yes, Mafia"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="noMafia">No, not Mafia</label>
              <input value={this.state.messages.noMafia}
                onChange={(e) => this.updateMessages(e.target.value, 'noMafia')}
                type="text"
                id="noMafia"
                maxLength="40"
                aria-label="No, not Mafia"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="playerKilled">Killed Player</label>
              <input value={this.state.messages.playerKilled}
                onChange={(e) => this.updateMessages(e.target.value, 'playerKilled')}
                type="text"
                id="playerKilled"
                maxLength="40"
                aria-label="Killed Player"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="streamNight">Night in stream</label>
              <input value={this.state.messages.streamNight}
                onChange={(e) => this.updateMessages(e.target.value, 'streamNight')}
                type="text"
                id="streamNight"
                maxLength="40"
                aria-label="Night in stream"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="streamDay">Day in stream</label>
              <input value={this.state.messages.streamDay}
                onChange={(e) => this.updateMessages(e.target.value, 'streamDay')}
                type="text"
                id="streamDay"
                maxLength="40"
                aria-label="Day in stream"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Login and Forgot Password messages:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="emptyEmailPass">Empty email and password</label>
              <input value={this.state.messages.emptyEmailPass}
                onChange={(e) => this.updateMessages(e.target.value, 'emptyEmailPass')}
                type="text"
                id="emptyEmailPass"
                maxLength="40"
                aria-label="Empty email and password"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="doesNotExist">Email does not exist</label>
              <input value={this.state.messages.doesNotExist}
                onChange={(e) => this.updateMessages(e.target.value, 'doesNotExist')}
                type="text"
                id="doesNotExist"
                maxLength="40"
                aria-label="Email does not exist"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="incorrectPass">Incorrect password</label>
              <input value={this.state.messages.incorrectPass}
                onChange={(e) => this.updateMessages(e.target.value, 'incorrectPass')}
                type="text"
                id="incorrectPass"
                maxLength="40"
                aria-label="Incorrect password"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="specifyEmail">Specify email</label>
              <input value={this.state.messages.specifyEmail}
                onChange={(e) => this.updateMessages(e.target.value, 'specifyEmail')}
                type="text"
                id="specifyEmail"
                maxLength="40"
                aria-label="Specify email"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>

          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="reminderSent">Reminder sent</label>
              <input value={this.state.messages.reminderSent}
                onChange={(e) => this.updateMessages(e.target.value, 'reminderSent')}
                type="text"
                id="reminderSent"
                maxLength="40"
                aria-label="Reminder sent"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="serverError">Server Error</label>
              <input value={this.state.messages.serverError}
                onChange={(e) => this.updateMessages(e.target.value, 'serverError')}
                type="text"
                id="serverError"
                maxLength="40"
                aria-label="serverError"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

        <div className='config-section'>
          <div className='text-left text-reg config-section-title'>Registration messages:</div>
          <hr></hr>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="fillAllFields">Fill All Fields</label>
              <input value={this.state.messages.fillAllFields}
                onChange={(e) => this.updateMessages(e.target.value, 'fillAllFields')}
                type="text"
                id="fillAllFields"
                maxLength="40"
                aria-label="Fill All Fields"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="emailTaken">Email taken</label>
              <input value={this.state.messages.emailTaken}
                onChange={(e) => this.updateMessages(e.target.value, 'emailTaken')}
                type="text"
                id="emailTaken"
                maxLength="40"
                aria-label="Email taken"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="nickTaken">Nickname Taken</label>
              <input value={this.state.messages.nickTaken}
                onChange={(e) => this.updateMessages(e.target.value, 'nickTaken')}
                type="text"
                id="nickTaken"
                maxLength="40"
                aria-label="Nickname Taken"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="selSex">Select Gender</label>
              <input value={this.state.messages.selSex}
                onChange={(e) => this.updateMessages(e.target.value, 'selSex')}
                type="text"
                id="selSex"
                maxLength="40"
                aria-label="Select Gender"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="userReg">User registered</label>
              <input value={this.state.messages.userReg}
                onChange={(e) => this.updateMessages(e.target.value, 'userReg')}
                type="text"
                id="userReg"
                maxLength="40"
                aria-label="User registered"
                placeholder="*"
                className="form-control form-control-sm" />
            </div>
          </div>
        </div>

      </div>

    );
  }
}

const mapStateToProps = state => ({
  config: state.config,
});

export default connect(mapStateToProps)(ConfigMessages);