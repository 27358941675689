import React, { Component } from 'react';
import '../css/custom.css';
import { connect } from 'react-redux';

class NA extends Component {

  render() {
    return (
      <div className='text-lg page-wrap center' id='page-missing-error'>
        <div id='page-missing-error-number'>404</div>
        <div id='page-missing-error-text'>page does not exist</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps)(NA);