import breakpoint from "./breakpoints";

export default function votingRender(page) {
    let obj = {};
    if (breakpoint() === 'XL') {
        obj.name = false;
        obj.buttons = false;
    } else if (breakpoint() === 'L') {
        obj.name = true;
        obj.buttons = false;
    } else if (breakpoint() === 'M') {
        obj.name = false;
        obj.buttons = true;
    } else if (breakpoint() === 'S') {
        obj.name = true;
        obj.buttons = false;
    } else if (breakpoint() === 'XS') {
        obj.xs = true;
    }
    return obj;
}