export function updateGame(args) {
    return { type: 'GAME', payload: args }
}

export function newGame() {
    return { type: 'NEW_GAME' }
}

export function updateTounament(args) {
    return { type: 'TOURNAMENT', payload: args }
}

export function inProgress() {
    return { type: 'PROGRESS' }
}

export function noProgress() {
    return { type: 'NO_PROGRESS' }
}

export function saveUrl(args) {
    return { type: 'URL', payload: args }
}

export function updateConfig(args) {
    return { type: 'CONFIG', payload: args }
}

export function login(args) {
    return {
        type: 'LOGIN',
        payload: {
            user: args
        }
    }
}

export function inProgressItem(args) {
    return { type: 'PROGRESS_ITEM', payload: args }
}

export function noProgressItem() {
    return { type: 'NO_PROGRESS_ITEM' }
}

export function noLoading() {
    return { type: 'NO_LOADING' }
}

export function noError() {
    return { type: 'NO_ERROR' }
}

export function error(args) {
    return {
        type: 'ERROR',
        payload: {
            errorText: args
        }
    }
}

export function loadUsers(args) {
    return {
        type: 'LOAD_USERS',
        payload: {
            users: args
        }
    }
}

export function inPreloading() {
    return { type: 'PRE_LOADING' }
}

export function setPlatform(args) {
    return { type: 'PLATFORM', payload: args }
}